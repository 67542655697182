import { useEffect, useRef, useState } from "react";
import Paginator from "../../_model/helpers/Paginator";
import withAdminPermissions from "../../components/auth/hocs/withAdminPermissions";
import PageSection from "../../components/common/PageSection";
import { AppLoader } from "../../components/common/loaders/AppLoader";
import PaginationControls from "../../components/common/pagination/PaginationControls";
import SearchFieldSlideDown from "../../components/common/search/SearchFieldSlideDown";
import ErrorLogListItem from "../../components/error-logs/ErrorLogListItem";
import AppPage from "../../components/layout/AppPage";
import useQueryWrapper from "../../hooks/use-query-wrapper";
import { useRepos } from "../../hooks/use-repos";
import __ from "../../utils/utils";

const ErrorLogsRoute = () => {
  const { errorLogRepo } = useRepos();
  const perPage = 8;
  const [page, setPage] = useState(0);
  const res = useQueryWrapper({
    queryKey: ["errorsLogs", page],
    queryFn: () => errorLogRepo.getMultiple({ perPage, page }),
  });

  const previousTotalCount = useRef<number | null>(null);

  useEffect(() => {
    if (res.data) {
      previousTotalCount.current = res.data.totalCount;
    }
  }, [res.data]);

  const paginator = new Paginator({
    pageIndex: page,
    totalCountOfElements:
      res.data?.totalCount ?? previousTotalCount.current ?? 0,
    data: res.data?.errorLogs ?? [],
    elementsPerPage: perPage,
  });

  return (
    <AppPage
      className={__.classNames(
        "mx-auto flex max-w-screen-xl flex-col gap-4 p-4"
      )}
      headerProps={{ heading: "Errorlogg" }}
    >
      <PageSection as="section">
        <aside>
          <SearchFieldSlideDown
            id="errorLogSearch"
            fetch={async (searchTerm: string) => {
              return errorLogRepo.search({ query: searchTerm, limit: 5 });
            }}
            placeholder="Sök efter errorloggar"
            Render={(props) => {
              if (!props.data.length) {
                return <p className="p-2">Inga errorloggar hittades</p>;
              }
              return (
                <ul className="mx-auto flex w-full max-w-screen-xl flex-col gap-2">
                  {props.data.map((log) => {
                    return <ErrorLogListItem key={log.id} errorLog={log} />;
                  })}
                </ul>
              );
            }}
          />
        </aside>
        {paginator.needsPagination && (
          <header className="flex justify-end p-4">
            <PaginationControls
              className="ml-auto"
              canGoBack={paginator.canGoBack}
              canGoForward={paginator.canGoForward}
              pageIndex={paginator.pageIndex}
              maxPageIndex={paginator.limits.maxPageIndex}
              onGoBack={() => setPage(page - 1)}
              onGoForward={() => setPage(page + 1)}
            />
          </header>
        )}
        <main className="p-2">
          {res.isLoading && <AppLoader />}
          {res.isError && (
            <p className="p-2 text-red-600">
              Det gick inte att hämta loggarna just nu
            </p>
          )}
          {res.data && (
            <ul className="mx-auto flex w-full max-w-screen-xl flex-col gap-2">
              {res.data.errorLogs.map((log) => {
                return <ErrorLogListItem key={log.id} errorLog={log} />;
              })}
            </ul>
          )}
        </main>
      </PageSection>
    </AppPage>
  );
};

export default withAdminPermissions(ErrorLogsRoute);
