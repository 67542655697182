/* import chalk from "chalk"; */

export enum MLogLevel {
  fatal = "Fatal",
  error = "Error",
  warn = "Warn",
  info = "Info",
  debug = "Debug",
  trace = "Trace",
}

export class MLogger {
  /*  private static shouldLog: boolean = Platform.OS === "ios"; */

  static fatal(context: string, log: any) {
    this.log(context, log, MLogLevel.fatal);
  }
  static error(context: string, log: any) {
    this.log(context, log, MLogLevel.error);
  }
  static warn(context: string, log: any) {
    this.log(context, log, MLogLevel.warn);
  }
  static info(context: string, log: any) {
    this.log(context, log, MLogLevel.info);
  }
  static debug(context: string, log: any) {
    this.log(context, log, MLogLevel.debug);
  }
  static trace(context: string, log?: any) {
    this.log(context, log, MLogLevel.trace);
  }

  private static log(context: string, log: any, level: MLogLevel) {
    /*   let _log = MFunction.match(level, {
      [MLogLevel.debug]: console.debug,
      [MLogLevel.error]: console.error,
      [MLogLevel.fatal]: console.error,
      [MLogLevel.info]: console.info,
      [MLogLevel.trace]: console.trace,
      [MLogLevel.warn]: console.warn,
    }); */
    //if (!this.shouldLog) return;

    const _log = console.log;
    if (typeof log === "object") {
      _log(`${context} - [${level}] -`, log);
      _log("\n");
    } else {
      _log(`${context} - [${level}] - ${log}`);
    }
  }
}
