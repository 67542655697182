import { WorkOrderConfirmation } from "@eljouren/domain/build";
import { useContext, useRef } from "react";
import AppPages from "../../../components/common/pages/AppPages";
import { FullImageType } from "../../../components/work-order/files/WorkOrderFileUpload";
import FormBreadcrumb from "../../../components/forms/breadcrumb/FormBreadcrumb";
import { useBundledState } from "../../../hooks/hooks";
import { useRepos } from "../../../hooks/use-repos";
import __ from "../../../utils/utils";
import CustomerWorkOrderContext from "../CustomerWorkOrderContext";
import CustomerWorkOrderBaseFieldsFormStep from "./CustomerWorkOrderBaseFieldsFormStep";
import CustomerWorkOrderImagesFormStep from "./CustomerWorkOrderImagesFormStep";
import CustomerWorkOrderOverviewFormStep from "./CustomerWorkOrderOverviewFormStep";
import CustomerWorkOrderPaymentAndHousingFormStep from "./CustomerWorkOrderPaymentAndHousingFormStep";

export type WorkOrderStepFormValues = [
  WorkOrderConfirmation.BaseFieldsType | undefined,
  WorkOrderConfirmation.PaymentInfoType | undefined,
  FullImageType[] | undefined,
  Pick<WorkOrderConfirmation.Type, "gdprConfirmed"> | undefined
];

enum Step {
  confirmBaseFields,
  paymentAndHousing,
  images,
  overviewAndAgreement,
}

interface Props {
  className?: string;
}

const CustomerWorkOrderStepBasedForm = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const { workOrderRepo } = useRepos();
  const stepBundle = useBundledState(Step.confirmBaseFields);
  const valuesRef = useRef<WorkOrderStepFormValues>([
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  function confirmBaseFieldsSubmit(
    baseFields: WorkOrderConfirmation.BaseFieldsType
  ) {
    valuesRef.current[0] = baseFields;
    stepBundle.set(1);
  }

  function paymentAndHousingSubmit(
    fields: WorkOrderConfirmation.PaymentInfoType
  ) {
    valuesRef.current[1] = fields;
    stepBundle.set(2);
  }

  function imageSubmit(images: FullImageType[]) {
    valuesRef.current[2] = images;
    stepBundle.set(3);
  }

  function overviewAndAgreementSubmit(
    values: Pick<WorkOrderConfirmation.Type, "gdprConfirmed">
  ) {
    valuesRef.current[3] = values;
    confirm();
  }

  async function confirm() {
    try {
      await ctx.workOrderRes.mutate(() => {
        const [base, paymentAndHousing, images, gdpr] = valuesRef.current;
        if (!base || !paymentAndHousing || !images || !gdpr) {
          throw new Error("Invalid values");
        }

        return workOrderRepo.confirm({
          workOrderId: ctx.workOrder.orderId,
          values: {
            ...base,
            ...paymentAndHousing,
            ...gdpr,
          },
          files: images,
        });
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att bekräfta uppdraget just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  function onGoBack() {
    stepBundle.set(stepBundle.value - 1);
  }

  function getOverviewProps() {
    const base = valuesRef.current[0];
    const paymentAndHousing = valuesRef.current[1];
    const images = valuesRef.current[2];

    if (!base || !images || !paymentAndHousing) {
      throw new Error("");
    }

    const values: Omit<WorkOrderConfirmation.Type, "gdprConfirmed"> = {
      ...base,
      ...paymentAndHousing,
    };

    return { values, images };
  }

  function getDefaultInvoiceMail(): string | undefined {
    // If the contact mail and the invoice mail additionally were the same,
    // set the (potentially) edited contact mail from step1 as the default value in step 2
    if (
      ctx.workOrder.contact.email === ctx.workOrder.invoiceInformation.email
    ) {
      const step1Email = valuesRef.current[0]?.contact.email;
      return step1Email;
    }

    return undefined;
  }

  return (
    <section
      className={__.classNames(
        "mx-auto grid h-full w-full max-w-2xl grid-rows-[auto,minmax(0,1fr)] flex-col",
        props.className
      )}
    >
      <aside className="z-50 flex flex-col">
        <FormBreadcrumb
          steps={["Order", "Fakturering", "Filer", "Översikt"]}
          atIndex={stepBundle.value}
          onActiveClick={stepBundle.set}
        />
      </aside>

      <AppPages
        pageIndex={stepBundle.value}
        onlyMain
        mainGridRow={2}
        className="h-full overflow-auto py-4"
      >
        {stepBundle.value === Step.confirmBaseFields && (
          <CustomerWorkOrderBaseFieldsFormStep
            defaultValues={valuesRef.current[0]}
            onSubmit={confirmBaseFieldsSubmit}
          />
        )}
        {stepBundle.value === Step.paymentAndHousing && (
          <CustomerWorkOrderPaymentAndHousingFormStep
            defaultValues={valuesRef.current[1]}
            onSubmit={paymentAndHousingSubmit}
            onGoBack={onGoBack}
            defaultInvoiceMail={getDefaultInvoiceMail()}
          />
        )}
        {stepBundle.value === Step.images && (
          <CustomerWorkOrderImagesFormStep
            onSubmit={imageSubmit}
            onGoBack={onGoBack}
            defaultValues={valuesRef.current[2]}
          />
        )}
        {stepBundle.value === Step.overviewAndAgreement && (
          <CustomerWorkOrderOverviewFormStep
            {...getOverviewProps()}
            onSubmit={overviewAndAgreementSubmit}
            onGoBack={onGoBack}
          />
        )}
      </AppPages>
    </section>
  );
};

export default CustomerWorkOrderStepBasedForm;
