import { HandymanWorkingHours } from "@eljouren/domain/build";
import { isWeekend } from "date-fns";
import { useContext } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import AppTextButton from "../../../../components/common/buttons/AppTextButton";
import { AppLoader } from "../../../../components/common/loaders/AppLoader";
import PageSection from "../../../../components/common/PageSection";
import HandymanContext from "../../../../components/handyman-context/HandymanContext";
import { TUseImmutableReturn } from "../../../../hooks/hooks";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import __ from "../../../../utils/utils";
import DatePeriod from "../../../../_model/helpers/AvailabilityPeriod";
import WorkerDateHelper from "../../../../_model/helpers/WorkerDateHelper";

interface Props {
  period: DatePeriod;
  nextPage?(): void;
  className?: string;
  selectedDates: TUseImmutableReturn<Record<string, true>>;
  noEdit: boolean;
}

const HandymanWorkingHoursSelectDatesSection = (props: Props) => {
  const ctx = useContext(HandymanContext);
  const isLg = useMediaQuery(TailwindBreakpoint.lg);

  function activeDates() {
    //return props.period.getPeriodAtIndexWithoutPastDates(ctx.page);
    return props.period.getPeriodAtIndex(ctx.page);
  }

  function selectWeekdays() {
    select((date) => !isWeekend(date));
  }

  function selectWeekends() {
    select(isWeekend);
  }

  function select(predicate: (date: Date) => boolean) {
    const entries = activeDates()
      .filter(predicate)
      .map((date) => [date.getTime(), true]);
    const dict = Object.fromEntries(entries);
    props.selectedDates.override(dict);
  }

  function onDateClick(date: Date) {
    const time = date.getTime();
    props.selectedDates.modify((current) => {
      if (time in current) {
        delete current[time];
      } else {
        current[time] = true;
      }
    });
  }

  const availabilityString = (
    date: Date,
    availability: HandymanWorkingHours.DictIndexedByStartOfDayType
  ): string => {
    const item = availability[date.getTime()];
    const string = WorkerDateHelper.fromTo(item, {
      whenNotSpecified: "Ej rapporterat",
      whenOffDuty: "Otillgänglig",
      format: "$start - $end",
    });
    return string;
  };

  const headerText = (isLarge: boolean): string => {
    const weeks = props.period.getWeeksInPeriod(activeDates());
    return weeks.map((week) => (isLarge ? "Vecka " : "V") + week).join(", ");
  };

  return (
    <PageSection
      as="section"
      className={__.classNames("flex h-full flex-col gap-2")}
    >
      <header className="flex flex-col gap-4">
        <span className="flex items-center gap-4">
          <AppTextButton
            disabled={ctx.page === 0}
            onClick={ctx.decrementPage}
            aria-label="Gå till föregående period"
          >
            <FiArrowLeft size={25} />
          </AppTextButton>
          <h3 className="text-lg">{headerText(isLg)}</h3>
          <AppTextButton
            onClick={ctx.incrementPage}
            aria-label="Gå till nästa period"
          >
            <FiArrowRight size={25} />
          </AppTextButton>
          {ctx.isLoading && <AppLoader className="ml-auto mr-2 h-6 w-6" />}
        </span>
        {ctx.workingHoursRes.isError && (
          <span className="text-red-600">
            Det gick inte att hämta arbetstider just nu
          </span>
        )}
      </header>
      <main
        className={__.classNames(
          "flex grow flex-col gap-2",
          ctx.workingHoursRes.isError && "pointer-events-none opacity-80"
        )}
      >
        {!props.noEdit && (
          <span className="flex gap-3 border-b border-b-black px-2 pb-2">
            <AppTextButton
              className="col-start-2 text-sm lg:text-lg"
              onClick={selectWeekdays}
            >
              Vardagar
            </AppTextButton>
            <AppTextButton
              className="col-start-2 text-sm lg:text-lg"
              onClick={selectWeekends}
            >
              Helger
            </AppTextButton>
            <AppTextButton
              className="col-start-3 text-sm lg:text-lg"
              onClick={() => {
                props.selectedDates.override({});
              }}
            >
              Rensa
            </AppTextButton>
            {!!props.nextPage && (
              <AppTextButton
                className="ml-auto flex items-center gap-1"
                disabled={!props.selectedDates.values.length}
                onClick={props.nextPage}
              >
                Rapportera
                <FiArrowRight size={25} />
              </AppTextButton>
            )}
          </span>
        )}

        <ul className="flex grow flex-col" role={"listbox"}>
          {activeDates().map((date) => {
            const time = date.getTime();
            const dateStr = new WorkerDateHelper(
              date
            ).weekdayDayOfMonthAndMonth();

            const merge = ctx.merger.get(date);
            const selected = time in props.selectedDates.dict;

            return (
              <li
                className={__.classNames(
                  "grid cursor-pointer grid-cols-[minmax(0,1fr),auto] items-center border-b p-1 py-2 font-semibold transition-colors",
                  selected && " bg-main-bg-light/20"
                )}
                key={time}
                onClick={() => {
                  onDateClick(date);
                }}
                aria-selected={selected}
                role="option"
              >
                <span className="text-base">{dateStr}</span>
                <span className="text-sm">
                  {ctx.workingHoursRes.data &&
                    availabilityString(date, ctx.workingHoursRes.data)}
                </span>
                {!!merge.workOrders.length && (
                  <span className="text-sm">Arbetsorder inplanerad</span>
                )}
              </li>
            );
          })}
        </ul>
      </main>
    </PageSection>
  );
};

export default HandymanWorkingHoursSelectDatesSection;
