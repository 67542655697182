import { motion } from "framer-motion";
import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import withCustomerGuid from "../../components/auth/hocs/withCustomerGuid";
import AnimatedCheckmark from "../../components/common/AnimatedCheckMark";
import { AppButton } from "../../components/common/buttons/AppButton";
import LoadingContent from "../../components/common/loaders/LoadingContent";
import PageSection from "../../components/common/PageSection";
import PageHeader from "../../components/layout/PageHeader";
import SomethingWentWrong from "../../components/on-error/SomethingWentWrong";
import WorkOrderFileContextProvider from "../../components/work-order/files/WorkOrderFileContextProvider";
import useMutatableQuery from "../../hooks/use-mutatable-query";
import { useRepos } from "../../hooks/use-repos";
import CustomerWorkOrderContext from "./CustomerWorkOrderContext";
import CustomerWorkOrderOverviewSection from "./CustomerWorkOrderOverviewSection";
import CustomerWorkOrderStepBasedForm from "./step-based-form/CustomerWorkOrderStepBasedForm";

const CustomerWorkOrderRoute = () => {
  const { workOrderRepo } = useRepos();

  const workOrderRes = useMutatableQuery({
    queryKey: ["customerOrderInJwt"],
    queryFn: () => {
      return workOrderRepo.getByCustomerGuid();
    },
  });

  if (workOrderRes.query.isError) {
    return (
      <SomethingWentWrong
        error={workOrderRes.query.error}
        description="Det gick inte att hämta denna order, vilket förmodligen betyder att det inte finns."
      />
    );
  }

  if (workOrderRes.query.error) {
    return (
      <SomethingWentWrong
        error={null}
        description="Det gick inte att hämta denna order just nu"
      />
    );
  }
  if (!workOrderRes.query.data && !workOrderRes.query.isLoading) {
    return (
      <SomethingWentWrong
        error={null}
        description="Det gick inte att hämta denna order just nu"
      />
    );
  }

  return (
    <LoadingContent
      renderContentSeparately
      loading={
        workOrderRes.isLoading || workOrderRes.query.isManuallyRefetching
      }
    >
      <ErrorBoundary
        fallbackRender={(props) => <SomethingWentWrong error={props.error} />}
      >
        {workOrderRes.query.data && (
          <CustomerWorkOrderContext.Provider
            value={{
              workOrder: workOrderRes.query.data,
              workOrderRes: workOrderRes,
            }}
          >
            <section className="mx-auto grid h-full w-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)] overflow-hidden pb-4">
              <PageHeader
                heading={"Uppdrag: " + workOrderRes.query.data.serialNumber}
                noBackButton
                loading={workOrderRes.query.isFetching}
              />
              <MainContent />
            </section>
          </CustomerWorkOrderContext.Provider>
        )}
      </ErrorBoundary>
    </LoadingContent>
  );
};

const MainContent = (props: {}) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const { workOrderRepo } = useRepos();
  function onReset() {
    ctx.workOrderRes.mutate(() =>
      workOrderRepo.reset(ctx.workOrderRes.query.data!)
    );
  }

  if (!ctx.workOrder.confirmedByCustomer) {
    return (
      <PageSection as="main">
        <CustomerWorkOrderStepBasedForm />
      </PageSection>
    );
  }

  return (
    <WorkOrderFileContextProvider orderId={ctx.workOrder.orderId}>
      <PageSection
        as="main"
        className="grid h-full grid-rows-[auto,minmax(0,1fr)] gap-8 overflow-auto rounded"
      >
        <section className="flex flex-col gap-2 rounded border p-8">
          <header className="flex flex-col items-center gap-8">
            <AnimatedCheckmark />
            <motion.h2
              className="text-xl"
              initial={{ y: -10, opacity: 0 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              Hurra!
            </motion.h2>
          </header>
          <motion.main
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.3 }}
          >
            <p className="mx-auto max-w-lg p-2 text-center text-sm">
              Just nu finns det inget mer du behöver göra - vi kontaktar dig om
              vi behöver kompletterande information!
            </p>
          </motion.main>

          {(process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_CUSTOM_ENVIRONMENT === "development") && (
            <fieldset className="border-2 p-4">
              <legend>För testningssyfte</legend>
              <AppButton onClick={onReset}>
                Återställ uppdraget till obekräftat
              </AppButton>
            </fieldset>
          )}
        </section>
        <CustomerWorkOrderOverviewSection
          uploadImageLabel="Ladda upp"
          workOrder={ctx.workOrder}
          images={"useImageContext"}
          allowImageHandling={!ctx.workOrder.isFinished}
        />
      </PageSection>
    </WorkOrderFileContextProvider>
  );
};

export default withCustomerGuid(CustomerWorkOrderRoute);
