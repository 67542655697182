"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileErrorReason = void 0;
var zod_1 = require("zod");
var FileErrorReason;
(function (FileErrorReason) {
    var Enum;
    (function (Enum) {
        Enum["unknown"] = "unknown";
        Enum["fileNotFound"] = "fileNotFound";
        Enum["metaFailedToUpload"] = "metaFailedToUpload";
        Enum["unauthorized"] = "unauthorized";
    })(Enum = FileErrorReason.Enum || (FileErrorReason.Enum = {}));
    FileErrorReason.Schema = zod_1.z.nativeEnum(Enum);
})(FileErrorReason || (FileErrorReason = {}));
exports.FileErrorReason = FileErrorReason;
