import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import React from "react";
import { useBrand } from "../../../hooks/brand-hooks";
import __ from "../../../utils/utils";
import EljourenLogo from "../brand/EljourenLogo";
import IpisLogo from "../brand/IpisLogo";
import RorjourenLogo from "../brand/RorjourenLogo";
import "./AppLoaderStyling.css";
interface Props {
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  renderContentSeparately?: boolean;
}

const LoadingContent: React.FC<Props> = (props) => {
  const reducedMotion = useReducedMotion();
  const brand = useBrand();

  if (!props.loading && props.renderContentSeparately) {
    return <>{props.children}</>;
  }

  return (
    <>
      <motion.section
        className={__.classNames(
          "relative h-full w-full",
          props.loading && "app-loader-section",
          props.className
        )}
        initial={{
          opacity: 0.5,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0.5,
        }}
      >
        {!props.renderContentSeparately && props.children}
        <AnimatePresence exitBeforeEnter initial>
          {props.loading && (
            <motion.span
              data-testid="loading-content"
              className={__.classNames(
                "absolute left-1/2 z-50 flex h-[clamp(125px,60vmin,250px)] w-[clamp(125px,60vmin,250px)] items-center justify-center",
                props.loading && "app-loader-wrapper"
              )}
              initial={{
                opacity: 0,
                x: "-50%",
                y: "10%",
              }}
              animate={{
                opacity: 1,
                x: "-50%",
                y: "10%",
                scale: [1.1, 1],
              }}
              exit={{
                opacity: 0,
                x: "-50%",
                y: "10%",
                transition: { duration: 5 },
              }}
            >
              <motion.div
                className={__.classNames(
                  "app-loader flex items-center justify-center"
                )}
                animate={
                  reducedMotion
                    ? { opacity: [0.7, 1, 0.7] }
                    : { rotate: 360, opacity: 1 }
                }
                transition={{
                  repeat: Infinity,
                  repeatDelay: 0.5,
                }}
              />
              {brand === "eljouren" && (
                <EljourenLogo className="absolute w-9/12 translate-y-[-6%] translate-x-[4%]" />
              )}
              {brand === "rorjouren" && (
                <RorjourenLogo className="absolute w-1/2" />
              )}
              {brand === "ipis" && <IpisLogo className="absolute w-1/2" />}
            </motion.span>
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
};

export default LoadingContent;
