import { useState } from "react";
import {
  QueryKey,
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import useQueryWrapper, { UseQueryWrapperResult } from "./use-query-wrapper";

type MutateOptions<T> = {
  optimisticUpdate?: T;
};

export type UseMutatableQueryResult<T> = {
  isLoading: boolean;
  query: UseQueryWrapperResult<T, unknown>;
  isInMutationProcess: boolean;
  mutation: Omit<
    UseMutationResult<unknown, unknown, unknown, unknown>,
    "mutate" | "mutateAsync"
  >;
  mutate: (
    callback: () => Promise<any>,
    options?: MutateOptions<T>
  ) => Promise<void>;
};

function useMutatableQuery<T>(
  options: UseQueryOptions<T> & { queryKey: QueryKey }
): UseMutatableQueryResult<T> {
  const queryClient = useQueryClient();
  const query = useQueryWrapper<T>(options);
  const [isInMutationProcess, setIsInMutationProcess] = useState(false);
  const mutation = useMutation(
    async (args: {
      callback: () => Promise<any>;
      mutateOptions?: MutateOptions<T>;
    }) => {
      return args.callback();
    },
    {
      onMutate: (args) => {
        setIsInMutationProcess(true);
        if (args.mutateOptions?.optimisticUpdate) {
          queryClient.setQueryData(
            options.queryKey,
            args.mutateOptions.optimisticUpdate
          );
        }
      },

      onSettled: async () => {
        await query.refetch();
        setIsInMutationProcess(false);
      },
    }
  );

  async function mutate(
    callback: () => Promise<any>,
    mutateOptions?: MutateOptions<T>
  ) {
    return mutation.mutateAsync({ callback, mutateOptions });
  }

  const { mutate: ignoredMutate, ...rest } = mutation;

  return {
    query,
    mutation: rest,
    isInMutationProcess,
    isLoading:
      mutation.isLoading || query.isLoading || query.isManuallyRefetching,
    mutate,
  };
}

export default useMutatableQuery;
