import { useEffect } from "react";

export function useOnEscapeClick(callback: () => void) {
  useOnClick("Escape", callback);
}

function useOnClick(key: string, callback: () => void) {
  const onKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === key) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });
}

let disabledEnterClickListeners = false;
export function useDisableEnterClickListeners() {
  useEffect(() => {
    disabledEnterClickListeners = true;

    return () => {
      disabledEnterClickListeners = false;
    };
  });
}

export function useOnEnterClick(args: {
  ref: React.MutableRefObject<HTMLElement | null | undefined>;
  callback?(): void;
}) {
  useEffect(() => {
    const { ref, callback } = args;
    const _callback = (e: KeyboardEvent) => {
      console.log({ disabledEnterClickListeners });
      if (disabledEnterClickListeners) {
        return;
      }
      onEnterClick(e);
    };
    if (ref.current && callback) {
      const { current } = ref;
      current.addEventListener("keypress", (e) => _callback(e));
    }

    return () => {
      ref.current?.removeEventListener("keypress", (e) => _callback(e));
    };
  });

  const onEnterClick = (event: KeyboardEvent) => {
    console.log("onEnterClick");
    if (event.code === "Enter") {
      // Not sure what the default is in this case
      event.preventDefault();
      args.callback && args.callback();
    }
  };
}
