import MoreThanOneContactErrorLog from "@eljouren/domain/build/error-logs/error-log-types/MoreThanOneContactErrorLog";
import __ from "../../utils/utils";
import LabelAndText from "../common/LabelAndText";

interface Props {
  className?: string;
  log: MoreThanOneContactErrorLog.Type;
}

const MoreThanOneContactErrorLogMain = (props: Props) => {
  const log = props.log;
  return (
    <main className={__.classNames("flex flex-col gap-2", props.className)}>
      <LabelAndText
        id={"email" + log.id}
        label="E-post som användes vid inloggsförsök"
        text={log.additionalInformation.email}
      />
      <p className="flex flex-col gap-2">
        <span className="text-sm">Användare med samma e-postaddress</span>
        <ul>
          {log.additionalInformation.contacts.map((contact) => (
            <li
              key={contact.id + log.id}
              className="grid grid-cols-[auto,minmax(0,1fr)] gap-2 bg-bg-base-layer p-2"
            >
              <span className="font-semibold">{contact.id}</span>
              <span>{`${contact.name}`}</span>
            </li>
          ))}
        </ul>
      </p>
    </main>
  );
};

export default MoreThanOneContactErrorLogMain;
