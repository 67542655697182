import { useRef } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import __ from "../../../utils/utils";
import { UUID } from "../../../utils/UUID";

interface Props<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  errorMessage?: string;
  errors?: FieldErrors<T>;
  className?: string;
  helperText?: string;
  register: UseFormRegister<T>;
  options?: RegisterOptions;
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
  data?: Record<string, string>;
}
export function AppFormTextField<T extends FieldValues>(props: Props<T>) {
  const id = useRef(UUID.generate().value).current;

  const options = props.options || {};
  if (!options.setValueAs && props.htmlAttributes?.type === "number") {
    options.setValueAs = (v) => (v === "" ? undefined : parseFloat(v));
  }

  const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError();

  let srOnlyLabel: string | undefined;
  if (!props.label && props.htmlAttributes?.placeholder) {
    srOnlyLabel = props.htmlAttributes.placeholder;
  }

  const data = __.objectKeyMap(props.data ?? {}, (key) => {
    return `data-${key}`;
  });

  return (
    <p className={__.classNames("flex w-full flex-col gap-1", props.className)}>
      {props.label && (
        <label htmlFor={id} className="text-lg">
          {props.label}
        </label>
      )}
      {srOnlyLabel && (
        <label htmlFor={id} className="sr-only">
          {srOnlyLabel}
        </label>
      )}
      <input
        className={__.classNames(
          "disabled:opacity-1 opacity-1 flex w-full rounded border fill-black p-2 text-black placeholder:text-sm placeholder:italic placeholder:text-black/60",
          props.htmlAttributes?.readOnly
            ? "border-form-border-disabled"
            : "border-form-border"
        )}
        id={id}
        {...props.register(props.name, options)}
        {...props.htmlAttributes}
        {...data}
      ></input>
      {props.helperText && (
        <span className="text-sm italic">{props.helperText}</span>
      )}
      {error && <span className="text-sm text-red-600">{error}</span>}
    </p>
  );
}
