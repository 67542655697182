import { IpisFile } from "@eljouren/file-schemas/build";
import { createContext, useContext, useEffect } from "react";
import useMutatableQuery, {
  UseMutatableQueryResult,
} from "../../../hooks/use-mutatable-query";
import useQueue from "../../../hooks/use-queue";
import { useRepos } from "../../../hooks/use-repos";
import { GlobalContext } from "../../../top-level-contexts";
import { ProcessTaskArgs } from "../../../utils/process/ProcessTask";
import { FullImageType } from "./WorkOrderFileUpload";
export const WorkOrderFileContext = createContext<{
  fileRes: UseMutatableQueryResult<IpisFile.WithMetaType[]>;
  uploadFileMutation: (files: FullImageType[]) => void;
  deleteFileMutation: (file: IpisFile.ObjectType) => void;
  downloadFile: (file: IpisFile.WithMetaType) => Promise<void>;
}>({} as never);

interface Props {
  children?: React.ReactNode;
  orderId: string;
}

const WorkOrderFileContextProvider = (props: Props) => {
  const { signInState } = useContext(GlobalContext);
  const { workOrderRepo } = useRepos();
  const uploadQueue = useQueue({
    id: "file-upload-queue",
    name: "Filuppladdning",
  });
  const deleteQueue = useQueue({
    id: "file-delete-queue",
    name: "Ta bort filer",
  });
  const fileRes = useMutatableQuery({
    queryKey: ["workOrderFiles", props.orderId, signInState.signedInAs],
    queryFn: () => workOrderRepo.getFiles({ workOrderId: props.orderId }),
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (uploadQueue.isInProgress) {
      unsubscribe = uploadQueue.process.onFinished(() => {
        fileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  useEffect(() => {
    let unsubscribe: () => void;
    if (deleteQueue.isInProgress) {
      unsubscribe = deleteQueue.process.onFinished(() => {
        fileRes.query.refetch();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  const uploadFileMutation = async (files: FullImageType[]) => {
    const tasks: ProcessTaskArgs<any>[] = files.map((file) => {
      return {
        name: file.meta?.customName || file.guid,
        func: async () => {
          //await new Promise((res) => setTimeout(res, 5000));
          await workOrderRepo.uploadFiles({
            workOrderId: props.orderId,
            files: [file],
          });
        },
      };
    });

    uploadQueue.addTasks(...tasks);
  };

  const deleteFileMutation = async (obj: IpisFile.WithMetaType) => {
    const tasks: ProcessTaskArgs<any> = {
      name: obj.meta?.customName || obj.guid,
      func: async () => {
        const res = await workOrderRepo.deleteFile(obj);
        return res;
      },
      onError: (er) => {
        return {
          errorMessage: "Det gick inte att ta bort filen",
        };
      },
    };
    deleteQueue.addTasks(tasks);
  };

  const downloadFile = async (obj: IpisFile.WithMetaType) => {
    try {
      const file = await fetch(obj.src);
      const blob = await file.blob();
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      const name = obj.meta?.customName || obj.guid;
      a.download = name + obj.ext;
      a.click();

      document.body.removeChild(a);
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ladda ner filen just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  };

  return (
    <WorkOrderFileContext.Provider
      value={{
        fileRes,
        uploadFileMutation,
        deleteFileMutation,
        downloadFile,
      }}
    >
      {props.children}
    </WorkOrderFileContext.Provider>
  );
};

export default WorkOrderFileContextProvider;
