import { Handyman } from "@eljouren/domain";
import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import TabPanel from "../../../components/common/tabs/TabPanel";
import HandymanContextProvider from "../../../components/handyman-context/HandymanContextProvider";
import AppPage from "../../../components/layout/AppPage";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import __ from "../../../utils/utils";
import HandymanCalendarRoute from "./calendar/HandymanCalendarRoute";
import HandymanDashboard from "./dashboard/HandymanDashboard";
import HandymanWorkingHoursTab from "./workings-hours/HandymanWorkingHoursTab";

export const HandymanLandingPageContext = React.createContext<{
  handyman: Handyman.Type;
  signedInWorker: Handyman.Type;
  isViewingOwnProfile: boolean;
}>({} as never);

interface Props {
  className?: string;
}

export enum HandymanLandingPageTab {
  dashboard = "Översikt",
  workingHours = "Arbetstider",
  calendar = "Kalender",
}

const HandymanLandingPage = (props: Props) => {
  const { isViewingOwnProfile, handyman } = useContext(
    HandymanLandingPageContext
  );

  const [params] = useSearchParams();

  function getTab() {
    const page = params.get("sida");
    switch (page) {
      case "arbetstider":
        return HandymanLandingPageTab.workingHours;
      case "kalender":
        return HandymanLandingPageTab.calendar;
      default:
        return HandymanLandingPageTab.dashboard;
    }
  }

  const tab = getTab();

  return (
    <AppPage
      className={__.classNames(
        "mx-auto grid h-full w-full md:drop-shadow-sm",
        props.className
      )}
      headerProps={{
        heading: tab,
        subheading: !isViewingOwnProfile
          ? `För ${handyman.firstName} ${handyman.lastName}`
          : undefined,
      }}
      tabProps={{
        tabs: Object.values(HandymanLandingPageTab),
        selectedTab: tab,
        mainClassName: "p-2 sm:p-4",
        searchParams: (tab) => {
          let page: string;
          switch (tab) {
            case HandymanLandingPageTab.workingHours:
              page = "arbetstider";
              break;
            case HandymanLandingPageTab.calendar:
              page = "kalender";
              break;
            case HandymanLandingPageTab.dashboard:
            default:
              page = "oversikt";
          }

          // "sida" is Swedish for page
          return {
            sida: page,
          };
        },
      }}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        <TabPanel key={tab}>
          {tab === HandymanLandingPageTab.dashboard && <HandymanDashboard />}
          {tab === HandymanLandingPageTab.workingHours && (
            <HandymanContextProvider
              handymanId={handyman.id}
              pageIndexSearchParamKey="whIndex"
            >
              <HandymanWorkingHoursTab />
            </HandymanContextProvider>
          )}
          {tab === HandymanLandingPageTab.calendar && (
            <ErrorBoundary
              fallbackRender={(props) => (
                <SomethingWentWrong
                  error={props.error}
                  description="Något gick fel när vi skulle visa kalendern. Vänligen försök igen senare!"
                />
              )}
            >
              <HandymanContextProvider
                handymanId={handyman.id}
                pageIndexSearchParamKey="cIndex"
              >
                <HandymanCalendarRoute />
              </HandymanContextProvider>
            </ErrorBoundary>
          )}
        </TabPanel>
      </AnimatePresence>
    </AppPage>
  );
};

export default HandymanLandingPage;
