"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLogLevel = void 0;
var zod_1 = require("zod");
var ErrorLogLevel;
(function (ErrorLogLevel) {
    var Enum;
    (function (Enum) {
        Enum["ERROR"] = "ERROR";
        Enum["WARN"] = "WARN";
    })(Enum = ErrorLogLevel.Enum || (ErrorLogLevel.Enum = {}));
    ErrorLogLevel.Schema = zod_1.z.nativeEnum(Enum);
})(ErrorLogLevel || (ErrorLogLevel = {}));
exports.ErrorLogLevel = ErrorLogLevel;
