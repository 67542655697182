import { Handyman } from "@eljouren/domain/build";
import { UseFormReturn } from "react-hook-form";
import __ from "../../../utils/utils";

interface Props {
  className?: string;
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
  id: string;
  label?: string;
  editable: boolean;
  form?: UseFormReturn<Handyman.Type>;
  path?: keyof Handyman.Type;
}

const ProfileFormSingleInput = (props: Props) => {
  const register =
    props.form && props.path ? props.form.register(props.path) : {};

  return (
    <p className={__.classNames("flex flex-col", props.className)}>
      {props.label && (
        <label className="col-span-2" htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <input
        className={__.classNames(
          "rounded border bg-transparent p-1 font-bold",
          props.editable && "border-bg-main-dark",
          !props.editable && "border-transparent"
        )}
        id={props.id}
        readOnly={!props.editable}
        disabled={!props.editable}
        {...props.htmlAttributes}
        {...register}
      />
    </p>
  );
};

export default ProfileFormSingleInput;
