import { useEffect, useRef } from "react";
import {
  QueryKey,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

export type UseQueryWrapperResult<Data, Error = unknown> = UseQueryResult<
  Data,
  Error
> & {
  isManuallyRefetching: boolean;
};

function useQueryWrapper<Data, Error = unknown>(
  options: UseQueryOptions<Data, Error> & { queryKey: QueryKey }
): UseQueryWrapperResult<Data, Error> {
  const isManuallyRefetchingRef = useRef(false);
  const query = useQuery(options);

  useEffect(() => {
    isManuallyRefetchingRef.current = false;
  }, [query.isFetching]);

  function refetch<TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) {
    isManuallyRefetchingRef.current = true;
    return query.refetch(options);
  }

  /* 
    Not sure why I have to check query.isFetching in

    isManuallyRefetching: query.isFetching && isManuallyRefetchingRef.current

    Checking the ref should be enough, as the ref will be set to true before refetching, and then immediately
    after rerender set to false in the useEffect since query.isFetching will change.

    On the next rerender - probably when the query has finished fetching - the ref should already have been changed to false
    and reflect the correct value. This doesn't work, however.
    */
  return {
    ...query,
    refetch,
    isManuallyRefetching: query.isFetching && isManuallyRefetchingRef.current,
  };
}

export default useQueryWrapper;
