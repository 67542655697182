import { useContext } from "react";
import PageSection from "../../../../components/common/PageSection";
import SearchFieldSlideDown from "../../../../components/common/search/SearchFieldSlideDown";
import HandymanWorkOrderListItem from "../../../../components/work-order/handyman/HandymanWorkOrderListItem";
import HandymanWorkOrderListSection from "../../../../components/work-order/handyman/HandymanWorkOrderListSection";
import { useRepos } from "../../../../hooks/use-repos";
import __ from "../../../../utils/utils";
import { HandymanLandingPageContext } from "../HandymanLandingPage";

interface Props {
  className?: string;
}

const HandymanDashboard = (props: Props) => {
  const ctx = useContext(HandymanLandingPageContext);
  const { workOrderRepo } = useRepos();
  return (
    <PageSection
      as="main"
      className={__.classNames("flex h-full flex-col gap-4", props.className)}
    >
      <section className={__.classNames("relative flex text-lg")}>
        {/* 
          ToDo: Needs accessible label
        */}
        <SearchFieldSlideDown
          className="w-full"
          placeholder="Sök på arbetsordrar"
          fetch={(query: string) =>
            workOrderRepo.searchForOrders({
              query,
              handymanId: ctx.handyman.id,
            })
          }
          id="orders"
          Render={(props) => {
            if (!props.data.length) {
              return <p>Inga arbetsordrar hittades</p>;
            }

            return (
              <ul className="flex flex-col gap-2 p-2">
                {props.data.map((order) => (
                  <HandymanWorkOrderListItem
                    className="rounded border-2 border-gray-300 bg-white"
                    key={order.orderId}
                    order={order}
                  />
                ))}
              </ul>
            );
          }}
        />
      </section>
      <HandymanWorkOrderListSection onlyForToday />
    </PageSection>
  );
};

export default HandymanDashboard;
