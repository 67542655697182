"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLogSignInInfo = void 0;
var zod_1 = require("zod");
var ErrorLogSignInInfo;
(function (ErrorLogSignInInfo) {
    ErrorLogSignInInfo.CustomerSchema = zod_1.z.object({
        signedInAs: zod_1.z.literal("customer"),
        customerId: zod_1.z.string(),
        relatedWorkOrderId: zod_1.z.string(),
    });
    ErrorLogSignInInfo.StaffSchema = zod_1.z.object({
        signedInAs: zod_1.z.literal("staff"),
        userId: zod_1.z.string(),
    });
    ErrorLogSignInInfo.HandymanSchema = zod_1.z.object({
        signedInAs: zod_1.z.literal("worker"),
        /*
            Contact ID, maybe
        */
        userId: zod_1.z.string(),
    });
    ErrorLogSignInInfo.NotSignedInSchema = zod_1.z.object({
        signedInAs: zod_1.z.literal("none"),
    });
    ErrorLogSignInInfo.Schema = zod_1.z.discriminatedUnion("signedInAs", [
        ErrorLogSignInInfo.CustomerSchema,
        ErrorLogSignInInfo.StaffSchema,
        ErrorLogSignInInfo.HandymanSchema,
        ErrorLogSignInInfo.NotSignedInSchema,
    ]);
})(ErrorLogSignInInfo || (ErrorLogSignInInfo = {}));
exports.ErrorLogSignInInfo = ErrorLogSignInInfo;
