"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderCategory = void 0;
var zod_1 = require("zod");
var WorkOrderCategory;
(function (WorkOrderCategory) {
    WorkOrderCategory.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
    });
})(WorkOrderCategory || (WorkOrderCategory = {}));
exports.WorkOrderCategory = WorkOrderCategory;
