import React from "react";
import __ from "../../utils/utils";

export type IconText = {
  label: string;
  href?: string;
  //linkType?: "tel" | "mail" | "default"
};

interface Props {
  id: string;
  className?: string;
  ariaLabel: string;
  text: IconText | IconText[];
  Icon: (props: { style: React.HTMLAttributes<any>["style"] }) => JSX.Element;
}

const IconAndText = (props: Props) => {
  return (
    <div
      className={__.classNames(
        "grid w-full grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2",
        props.className
      )}
      aria-label={props.ariaLabel}
    >
      <props.Icon
        style={{
          gridRowStart: 1,
          gridRowEnd: __.asArray(props.text).length + 1,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      />
      {__.asArray(props.text).map((el, i) => {
        const className = "grow rounded p-1 col-start-2";
        if (el.href && el.label.length) {
          return (
            <a
              href={el.href}
              key={el.label + "index:" + i}
              className={className + " hover:underline"}
            >
              {el.label.length === 0 ? "-" : el.label}
            </a>
          );
        }

        return (
          <span key={el.label + "index:" + i} className={className}>
            {el.label.length === 0 ? "-" : el.label}
          </span>
        );
      })}
    </div>
  );
};

export default IconAndText;
