import __ from "./utils";

type Truthy<T> = T extends null
  ? never
  : T extends undefined
  ? never
  : T extends false
  ? never
  : T;

function assert<T>(truthyValue: T, message?: string): Truthy<T> {
  if (__.isNull(truthyValue) || !truthyValue) {
    throw new AssertionError(message);
  }

  return truthyValue as Truthy<T>;
}

class AssertionError extends Error {
  constructor(message?: string) {
    const msg = message || "Assertion failed.";
    super(msg);
  }
}

export default assert;
