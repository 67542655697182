import { Brand } from "@eljouren/domain";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../top-level-contexts";

export function useBrand(): Brand.Type {
  const { signInState } = useContext(GlobalContext);

  if (!signInState.isSignedIn) {
    //console.log("use brand returning ipis because not signed in");
    return "ipis";
  }

  if (signInState.signedInAs === "worker") {
    const { profession } = signInState.handyman;
    const plumber =
      profession === "plumber" || profession === "plumber-spolbil";
    if (plumber) {
      return "rorjouren";
    }
    return "eljouren";
  } else {
    //console.log("returning sign in state brand", signInState);
    return signInState.brand;
  }
}

const useBrandLogic = () => {
  const brand = useBrand();

  useEffect(() => {
    let linkHref: string;
    let title: string;
    switch (brand) {
      case "eljouren":
        title = "Svenska Eljouren – Sveriges modernaste elektriker";
        linkHref = "/favicon-eljouren.svg";
        break;
      case "rorjouren":
        title = "Rörjour 24/7 – Effektiva rörmokare & rörjour 24/7";
        linkHref = "/favicon-rorjouren.svg";
        break;
      case "ipis":
        title = "IPIS – Din kompletta installationspartner";
        linkHref = "/favicon-ipis.svg";
        break;
    }

    document.title = title;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      //@ts-ignore
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    //@ts-ignore
    link.href = linkHref;
  }, [brand]);
};

export default useBrandLogic;
