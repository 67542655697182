"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyGetFilesResponse = void 0;
var FileServerGetFilesResponse_1 = require("../../file-server-routes/output/FileServerGetFilesResponse");
var zod_1 = require("zod");
var FileMeta_1 = require("../../file/FileMeta");
var OperationResults_1 = require("../../shared-schemas/OperationResults");
var ProxyGetFilesResponse;
(function (ProxyGetFilesResponse) {
    ProxyGetFilesResponse.AsSrcSingleSchema = zod_1.z.string();
    ProxyGetFilesResponse.AsSrcSchema = zod_1.z.object({
        files: ProxyGetFilesResponse.AsSrcSingleSchema.array(),
        operations: OperationResults_1.OperationResults.GetSchema,
    });
    ProxyGetFilesResponse.AsObjectSingleSchema = FileServerGetFilesResponse_1.FileServerGetFilesResponse.SingleObjectSchema;
    ProxyGetFilesResponse.AsObjectSchema = zod_1.z.object({
        files: ProxyGetFilesResponse.AsObjectSingleSchema.array(),
        operations: OperationResults_1.OperationResults.GetSchema,
    });
    ProxyGetFilesResponse.WithMetaSingleSchema = FileServerGetFilesResponse_1.FileServerGetFilesResponse.SingleObjectSchema.extend({
        meta: FileMeta_1.FileMeta.Schema.optional(),
    });
    ProxyGetFilesResponse.WithMetaSchema = zod_1.z.object({
        files: ProxyGetFilesResponse.WithMetaSingleSchema.array(),
        operations: OperationResults_1.OperationResults.GetSchema,
    });
    ProxyGetFilesResponse.Schema = zod_1.z.object({
        files: zod_1.z.union([
            ProxyGetFilesResponse.AsSrcSingleSchema.array(),
            ProxyGetFilesResponse.AsObjectSingleSchema.array(),
            ProxyGetFilesResponse.WithMetaSingleSchema.array(),
        ]),
        operations: OperationResults_1.OperationResults.GetSchema,
    });
})(ProxyGetFilesResponse || (ProxyGetFilesResponse = {}));
exports.ProxyGetFilesResponse = ProxyGetFilesResponse;
