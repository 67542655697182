"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandymanWorkingHours = void 0;
var zod_1 = require("zod");
var HandymanWorkingHours;
(function (HandymanWorkingHours) {
    HandymanWorkingHours.ReportedSchema = zod_1.z.object({
        status: zod_1.z.literal("reported"),
        //startOfDay: z.date(),
        start: zod_1.z.date(),
        end: zod_1.z.date(),
    });
    HandymanWorkingHours.OffWorkSchema = zod_1.z.object({
        status: zod_1.z.literal("offWork"),
        start: zod_1.z.date(),
        end: zod_1.z.date(),
        //startOfDay: z.date(),
    });
    HandymanWorkingHours.Schema = zod_1.z.discriminatedUnion("status", [
        HandymanWorkingHours.ReportedSchema,
        HandymanWorkingHours.OffWorkSchema,
    ]);
    HandymanWorkingHours.DictIndexedByStartOfDaySchema = zod_1.z.record(zod_1.z.string(), HandymanWorkingHours.Schema);
})(HandymanWorkingHours || (HandymanWorkingHours = {}));
exports.HandymanWorkingHours = HandymanWorkingHours;
