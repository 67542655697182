import { eachDayOfInterval, eachWeekOfInterval, isSameDay } from "date-fns";
import { sv } from "date-fns/locale";
import React from "react";
import DayOfWeek from "../../../components/calendar/DayOfWeek";
import DatePeriod from "../AvailabilityPeriod";
import DateHelper from "../DateHelper";
import CalendarEvent from "./CalendarEvent";
import CalendarEvents from "./CalendarEvents";
import { TCalendarEvent } from "./types";

//extends DateHelper
export default class CalendarHelper {
  protected readonly locale: Locale;
  public readonly events: CalendarEvents;
  public readonly gui: CalendarGuiHelper = new CalendarGuiHelper(
    () => this.calendarDays
  );
  private readonly start: Date;
  private readonly end: Date;

  constructor(
    index: number,
    events: TCalendarEvent[] | undefined,
    private readonly options: {
      locale?: Locale;
      weeksInPeriod?: number;
    } = {}
  ) {
    this.locale = sv;
    //super(date, locale);

    const period = new DatePeriod({
      weeksInPeriod: options.weeksInPeriod || 2,
    });
    const dates = period.getPeriodAtIndex(index);
    this.start = dates[0];
    this.end = dates[dates.length - 1];
    this.events = new CalendarEvents(events);
  }

  get numberOfGridViewRows() {
    return this.options.weeksInPeriod || 2;
  }

  get daysOfWeek(): DayOfWeek[] {
    return [
      new DayOfWeek("måndag", "mån"),
      new DayOfWeek("tisdag", "tis"),
      new DayOfWeek("onsdag", "ons"),
      new DayOfWeek("torsdag", "tors"),
      new DayOfWeek("fredag", "fre"),
      new DayOfWeek("lördag", "lör"),
      new DayOfWeek("söndag", "sön"),
    ];
  }

  get calendarDays(): DateHelper[] {
    const days = eachDayOfInterval({ start: this.start, end: this.end });
    return days.map((date) => new DateHelper(date, this.locale));
  }

  get weekNumbers(): DateHelper[] {
    const weekDates = eachWeekOfInterval(
      { start: this.start, end: this.end },
      { locale: this.locale }
    );
    return weekDates.map((date) => new DateHelper(date, this.locale));
  }
  label(isLg: boolean) {
    const weekNumbers = this.weekNumbers;
    const base = isLg ? "Vecka " : "V";
    return weekNumbers.map((date) => `${base}${date.week}`).join(", ");
  }
}

class CalendarGuiHelper {
  readonly weekdayLabelRowHeightInRem = 2.5;
  /*
  Having this in percent makes it hard to keep it responsive on small screens :'(
  */
  readonly weeknumberColumnWidthInPercent = 8;

  constructor(private readonly getCalendarDays: () => DateHelper[]) {}

  get gridStyle(): React.CSSProperties {
    const weekCount = new Set(this.getCalendarDays().map((day) => day.week))
      .size;
    const hideWeekNumbers = false;
    // Needs to take into account if we want to hide weeknumbers
    return {
      display: "grid",
      gridTemplateRows: `${this.weekdayLabelRowHeightInRem}rem repeat(${weekCount}, minmax(0, 1fr))`,
      gridTemplateColumns: hideWeekNumbers
        ? `repeat(7, minmax(0, 1fr))`
        : `${this.weeknumberColumnWidthInPercent}% repeat(7, minmax(0, 1fr))`,
    };
  }

  /*
  I can't write the correct type without breaking the type-system
  */
  gridItemEventStyle(event: CalendarEvent): any {
    const style: React.CSSProperties = {
      minHeight: "3rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      borderRadius: "0.125rem",
      border: "1px solid lightgray",
      //fontSize: "0.5rem",
      padding: 1,
    };
    /* if (event.hasPassed) {
      style.opacity = 0.5;
    } */
    return style;
  }

  gridItemHeadingStyle(sameMonth: boolean): any {
    const style: React.CSSProperties = {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      padding: "0px",
      width: 250,
      fontWeight: "bold",
    };
    if (!sameMonth) {
      style.opacity = 0.5;
    }
    return style;
  }

  getGridRow(date: Date, index?: number): number {
    if (index === undefined) {
      index = this.getCalendarDays().findIndex((helper) =>
        isSameDay(helper.date, date)
      );
    }
    return Math.floor(index / 7) + 2;
  }

  getGridColumn(date: Date, index?: number): number {
    if (index === undefined) {
      index = this.getCalendarDays().findIndex((helper) =>
        isSameDay(helper.date, date)
      );
    }
    return (index % 7) + 2;
  }
}
