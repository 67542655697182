"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderEditableFields = void 0;
var zod_1 = require("zod");
var WorkOrder_1 = require("./WorkOrder");
/*
    The fields of a work order that are editable by a handyman
    (with the right permisisons)
*/
var WorkOrderEditableFields;
(function (WorkOrderEditableFields) {
    WorkOrderEditableFields.Schema = WorkOrder_1.WorkOrder.HandymanSchema.pick({
        internalActionNote: true,
        actionDescription: true,
    })
        .merge(zod_1.z.object({
        contact: WorkOrder_1.WorkOrder.HandymanSchema.shape.contact.pick({ phone2: true }),
    }))
        .partial();
})(WorkOrderEditableFields || (WorkOrderEditableFields = {}));
exports.WorkOrderEditableFields = WorkOrderEditableFields;
