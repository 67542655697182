import UnknownErrorLog from "@eljouren/domain/build/error-logs/error-log-types/UnknownErrorLog";
import __ from "../../utils/utils";
import LabelAndText from "../common/LabelAndText";

interface Props {
  className?: string;
  log: UnknownErrorLog.Type;
}

const UnknownErrorLogMain = (props: Props) => {
  const log = props.log;
  return (
    <main className={__.classNames("flex flex-col gap-2", props.className)}>
      <LabelAndText
        id={"context" + log.id}
        label="Kontext"
        text={log.additionalInformation.context ?? "Ingen kontext angedd"}
      />
      <LabelAndText
        id={"errorMessage" + log.id}
        label="Meddelande"
        text={
          log.additionalInformation.errorMessage ?? "Inget meddelande angett"
        }
      />
      <LabelAndText
        id={"stackTrace" + log.id}
        label="Stacktrace"
        text={log.additionalInformation.stackTrace ?? "Ingen stacktrace angedd"}
      />
    </main>
  );
};

export default UnknownErrorLogMain;
