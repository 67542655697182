import React from "react";
import { TabContextProps } from "../common/tabs/TabContext";
import { PageHeaderProps } from "./PageHeader";

const LayoutControlsContext = React.createContext<{
  setTabProps: (props: TabContextProps<string> | false) => void;
  setPageHeaderProps: (props: PageHeaderProps | false) => void;
}>({} as never);

export default LayoutControlsContext;
