import { useState } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import __ from "../../../utils/utils";
import { UUID } from "../../../utils/UUID";

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  errorMessage?: string;
  fontNormal?: string;
  errors?: FieldErrors<T>;
  className?: string;
  register: UseFormRegister<T>;
  options?: RegisterOptions;
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
} & (
  | {
      type: "checkbox";
      value?: undefined;
    }
  | { type: "radio"; value: string }
);

export function AppFormCheckbox<T extends FieldValues>(props: Props<T>) {
  const [id] = useState(UUID.generate().value);

  /*   const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError(); */

  return (
    <p className={__.classNames("flex items-center gap-2", props.className)}>
      <input
        className={__.classNames(
          "cursor-pointer p-2 disabled:opacity-50 disabled:brightness-75"
        )}
        id={id}
        {...props.register(props.name, props.options)}
        {...props.htmlAttributes}
        type={props.type}
        value={props.value}
      ></input>
      {props.label && (
        <label htmlFor={id} className={__.classNames("cursor-pointer text-lg")}>
          {props.label}
        </label>
      )}

      {/* {error && <span>{error}</span>} */}
    </p>
  );
}
