import { Checklist, ChecklistAnswers } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import { AppFormTextArea } from "../../../components/common/text-areas/AppFormTextArea";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import { useRepos } from "../../../hooks/use-repos";
import { __tsx } from "../../../utils/tsxUtils";
import __ from "../../../utils/utils";
import HandymanWorkOrderRouteContext from "./HandymanWorkOrderRouteContext";

const noOption = "no-option";

const FormSchema = z.record(
  z.string(),
  z.object({
    answer: z.string(),
    comment: z.string().optional().nullable(),
  })
);

export type TFormValues = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  onGoBack(): void;
  //onSubmit();
}

function getDefaultValues(data: Checklist.Type | undefined) {
  if (!data) {
    return undefined;
  }

  const filtered = data.filter((item) => !!item.answer || !!item.comment);
  const entries = filtered.map((item) => [
    item.id,
    { answer: item.answer ?? noOption, comment: item.comment },
  ]);
  const values = Object.fromEntries(entries);
  return values;
}

const HandymanWorkOrderChecklist = (props: Props) => {
  const { workOrderRepo } = useRepos();
  const { checklistRes, order } = useContext(HandymanWorkOrderRouteContext);
  const readOnly = !order.allowedToHandleOrder || order.isFinished;

  const form = useForm<TFormValues>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(checklistRes.query.data),
  });

  useEffect(() => {
    const values = getDefaultValues(checklistRes.query.data);
    form.reset(values);
  }, [checklistRes.query.data, form]);

  async function onSubmit(values: TFormValues) {
    if (!checklistRes.query.data) {
      return;
    }
    const answers = Object.entries(values)
      .filter(([id, value]) => {
        if (!value) {
          return false;
        }
        return value.answer !== noOption || !!value.comment;
      })
      .map(([id, value]): ChecklistAnswers.SingleAnswerType => {
        return {
          checklistItemId: id,
          answer: value.answer === noOption ? "" : value.answer,
          comment: value.comment ?? null,
        };
      });
    try {
      await checklistRes.mutate(() =>
        workOrderRepo.reportChecklistAnswers({
          workOrderId: order.orderId,
          answers,
        })
      );

      //props.onSubmit();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att rapportera in svar just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  function onError(errors: FieldErrors<TFormValues>) {
    console.log({ errors });
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={__.classNames(
        "fixed left-0 top-0 z-50 flex h-full w-full flex-col bg-bg-base-layer",
        props.className
      )}
    >
      <form
        id="checklistForm"
        onSubmit={form.handleSubmit(onSubmit, onError)}
        className="mx-auto flex w-full max-w-screen-lg flex-col overflow-auto bg-white"
      >
        <header className="flex w-full justify-between gap-2 bg-main-bg-dark px-2 py-4 text-white lg:border-b">
          <h2 className="text-base md:text-lg" id="checklistHeading">
            {order.checklist?.name ?? "Checklista för uppdraget"}
          </h2>
          {checklistRes.isLoading && (
            /* ToDo: Fix barely visible */
            <AppLoader className="ml-auto mr-2 h-6 w-6" />
          )}
        </header>
        <main className="flex flex-col overflow-auto">
          {__tsx.renderOne(checklistRes, [
            (args) => args.query.isLoading && <></>,
            (args) =>
              args.query.isError && (
                <p>Det gick inte att hämta uppdragets checklista just nu</p>
              ),
            (args) =>
              !!args.query.data && (
                <>
                  {args.query.data.map((el) => (
                    <ChecklistItem
                      key={el.id}
                      item={el}
                      form={form}
                      readOnly={readOnly}
                    />
                  ))}
                </>
              ),
          ])}
        </main>
        <footer className="flex flex-col items-end gap-2 border-t p-4">
          {!readOnly && (
            <AppButton
              disabled={!form.formState.isDirty || checklistRes.isLoading}
              loading={checklistRes.isLoading}
              className="px-12"
            >
              Spara
            </AppButton>
          )}
          <AppButton buttonMode="outline" onClick={props.onGoBack}>
            Gå tillbaka
          </AppButton>
        </footer>
      </form>
    </motion.section>
  );
};

interface ChecklistItemProps {
  item: Checklist.ListItemType;
  form: UseFormReturn<any>;
  readOnly: boolean;
}

const ChecklistItem = (props: ChecklistItemProps) => {
  const { item } = props;
  return (
    <span className="px-2 py-4 odd:bg-main-bg-light/10">
      <MainAnswerElement {...props} />
      {item.allowComment && (
        <AppFormTextField
          register={props.form.register}
          name={item.id + ".comment"}
          htmlAttributes={{
            placeholder: "Kommentar",
            readOnly: props.readOnly,
          }}
          data={{
            "checklist-item-type": "comment",
          }}
        />
      )}
    </span>
  );
};

const MainAnswerElement = (props: ChecklistItemProps) => {
  const { item } = props;
  if (item.type === "yes/no") {
    return (
      <ChecklistItemSelect
        {...props}
        options={[
          { label: "Ja", value: "Ja" },
          { label: "Nej", value: "Nej" },
        ]}
        type="yes/no"
      />
    );
  } else if (item.type === "text") {
    return (
      <AppFormTextArea
        register={props.form.register}
        name={item.id + ".answer"}
        label={item.name + (item.required ? " *" : "")}
        data={{
          "checklist-item-type": "text",
        }}
        htmlAttributes={{
          readOnly: props.readOnly,
        }}
      />
    );
  } else if (item.type === "multipleChoice") {
    return (
      <ChecklistItemSelect
        {...props}
        options={item.options?.map((opt) => ({ value: opt, label: opt })) ?? []}
        type="multipleChoice"
      />
    );
  }

  return <></>;
};

const ChecklistItemSelect = (
  props: ChecklistItemProps & {
    options: { value: string; label: string }[];
    type: "yes/no" | "multipleChoice";
  }
) => {
  return (
    <p className="grid grid-cols-[minmax(0,1fr),auto] gap-2 pb-2">
      <label
        htmlFor={`checkboxFor${props.item.id}`}
        className="rounded text-base"
      >
        {props.item.name + (props.item.required ? " *" : "")}
      </label>
      <select
        className="my-auto w-24 cursor-pointer rounded border bg-white p-2"
        id={`checkboxFor${props.item.id}`}
        {...props.form.register(props.item.id + ".answer", { required: true })}
        defaultValue={noOption}
        disabled={props.readOnly}
        data-checklist-item-type={props.type}
      >
        <option disabled value={noOption}>
          Välj
        </option>
        {props.options.map((opt, i) => {
          const key = opt.value + props.item.id + i;
          return (
            <option key={key} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </select>
    </p>
  );
};

export default HandymanWorkOrderChecklist;
