import { Handyman } from "@eljouren/domain/build";
import { FaArrowRight } from "react-icons/fa";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useRepos } from "../../../hooks/use-repos";
import AppRoutes from "../../../routes";
import DateHelper from "../../../_model/helpers/DateHelper";
import SalesTeamWorkingHoursComponent from "./SalesTeamWorkingHoursComponent";
import MyLink from "../../../components/routing/MyLink";

interface Props {
  className?: string;
  handyman: Handyman.Type;
}

const SalesTeamPartnerListItem = (props: Props) => {
  const { handymanRepo, workOrderRepo } = useRepos();

  const workingHoursRes = useQuery(
    ["workingHoursForTheNextTwoWeeks", props.handyman.id],
    async () => {
      const start = new DateHelper().startOfDay;
      const end = new DateHelper().add("weeks", 2).add("days", 1).startOfDay;
      const res = await handymanRepo.fetchWorkingHours({
        handymanId: props.handyman.id,
        interval: {
          start: start.date,
          end: end.date,
        },
      });

      return res;
    }
  );
  const workOrderRes = useQuery(
    ["workOrdersForTheNextTwoWeeks", props.handyman.id],
    async () => {
      const start = new DateHelper().startOfDay;
      const end = new DateHelper().add("weeks", 2).endOfDay;
      const res = await workOrderRepo.getForHandymanBetween({
        handymanId: props.handyman.id,
        interval: {
          start: start.date,
          end: end.date,
        },
      });

      return res;
    }
  );

  const location = useLocation();
  const params = new URLSearchParams({
    sida: "kalender",
    goBackTo: location.pathname + location.search,
  });

  return (
    <li
      className="flex w-full cursor-pointer items-center justify-between rounded border bg-bg-base-layer transition-all hover:bg-main-bg-light hover:text-white"
      key={props.handyman.id}
    >
      <MyLink
        to={AppRoutes.salesTeam.singlePartner(props.handyman.id) + `?${params}`}
        className="grid w-full grid-cols-[minmax(0,1fr),auto] items-center justify-between gap-x-2 gap-y-1 p-2"
      >
        <span className="text-xl font-semibold">
          {props.handyman.firstName} {props.handyman.lastName}
        </span>
        <span className="text-lg">{props.handyman.companyName}</span>
        <SalesTeamWorkingHoursComponent
          workingHoursRes={workingHoursRes}
          workOrderRes={workOrderRes}
          {...props}
        />
        <FaArrowRight
          size={25}
          className="col-start-2 row-span-3 row-start-1 "
        />
      </MyLink>
    </li>
  );
};

export default SalesTeamPartnerListItem;
