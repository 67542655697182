import { motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import __ from "../../utils/utils";
import { TabContextProps } from "../common/tabs/TabContext";
import LayoutControlsContext from "./LayoutControlsContext";
import { PageHeaderProps } from "./PageHeader";

interface Props {
  className?: string;
  children?: React.ReactNode;
  headerProps?: PageHeaderProps;
  tabProps?: TabContextProps<string>;
}

const variants = {
  closed: {
    //clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
    opacity: 0,
  },
  open: {
    //clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
    opacity: 1,
  },

  exit: {
    // Animating to same as open apparently doesn't work
    //clipPath: "polygon(0 1%, 100% 1%, 100% 1%, 0 1%)",
    opacity: 0,
  },
};

const AppPage = (props: Props) => {
  const controls = useContext(LayoutControlsContext);

  useEffect(() => {
    controls.setPageHeaderProps(props.headerProps ?? false);
  }, [props.headerProps, controls]);

  useEffect(() => {
    controls.setTabProps(props.tabProps ?? false);
  }, [props.tabProps, controls]);

  const spanFull = !props.headerProps && !props.tabProps;
  const span2 = !spanFull && (!props.headerProps || !props.tabProps);
  const span1 = !spanFull && !span2;

  return (
    <motion.main
      className={__.classNames(
        props.className,
        "col-start-1 flex h-full w-full",
        spanFull && "row-span-3 row-start-1",
        span2 && "row-span-2 row-start-2",
        span1 && "row-start-2 lg:row-start-3"
      )}
      variants={variants}
      initial="closed"
      animate="open"
      exit="exit"
      transition={{ duration: 0.3 }}
    >
      {props.children}
    </motion.main>
  );
};

export default AppPage;
