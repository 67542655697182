import { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/common/brand/Logo";
import LoadingContent from "../../components/common/loaders/LoadingContent";
import PageHeader from "../../components/layout/PageHeader";
import AppRoutes from "../../routes";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";
import AppPage from "../../components/layout/AppPage";
import MyLink from "../../components/routing/MyLink";

interface Props {
  className?: string;
}

const NotFoundRoute = (props: Props) => {
  const { signInState } = useContext(GlobalContext);

  const signedInAsWorker =
    signInState.isSignedIn && signInState.signedInAs === "worker";

  return (
    <LoadingContent loading={signInState.isLoading} renderContentSeparately>
      <AppPage
        headerProps={{
          heading: "Sidan hittades inte",
        }}
        className="flex flex-grow flex-col items-center gap-2 py-10"
      >
        <h2 className="h-20 w-44">
          <Logo center withColor />
        </h2>
        <p className="text-lg font-bold">Oj, nu verkar du ha kommit fel!</p>
        {signedInAsWorker && (
          <MyLink to={AppRoutes.partner.root()}>Gå till framsidan</MyLink>
        )}
        {!signedInAsWorker && (
          <p className="max-w-xs text-center">
            Du kommer åt dina ärenden genom att klicka på länken vi skickar ut
            till dig via mail eller sms
          </p>
        )}
      </AppPage>
    </LoadingContent>
  );
};

export default NotFoundRoute;
