"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationResults = void 0;
var zod_1 = require("zod");
var OperationResults;
(function (OperationResults) {
    var BaseSchema = zod_1.z.object({
        fileOperationSuccess: zod_1.z.boolean(),
        metaOperationSuccess: zod_1.z.boolean(),
    });
    var Enum;
    (function (Enum) {
        Enum["UPLOAD"] = "UPLOAD";
        Enum["DELETE"] = "DELETE";
        Enum["GET"] = "GET";
    })(Enum = OperationResults.Enum || (OperationResults.Enum = {}));
    OperationResults.UploadSchema = BaseSchema.extend({
        operationType: zod_1.z.literal(Enum.UPLOAD),
    });
    OperationResults.DeleteSchema = BaseSchema.extend({
        operationType: zod_1.z.literal(Enum.DELETE),
    });
    OperationResults.GetSchema = BaseSchema.extend({
        operationType: zod_1.z.literal(Enum.GET),
    });
    OperationResults.Schema = zod_1.z.discriminatedUnion("operationType", [
        OperationResults.UploadSchema,
        OperationResults.DeleteSchema,
        OperationResults.GetSchema,
    ]);
})(OperationResults || (OperationResults = {}));
exports.OperationResults = OperationResults;
