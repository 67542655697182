import { useEffect, useRef } from "react";
import {
  useOnEnterClick,
  useOnEscapeClick,
} from "../../hooks/keyboard-event-hooks";
import { AppButton } from "../common/buttons/AppButton";
import { motion } from "framer-motion";
import { AlertState, ConfirmDialogAnswerArgs, ConfirmState } from "./Modal";
import __ from "../../utils/utils";
import ErrorUtil from "../../utils/errors/ErrorUtil";

type AlertDialogProps =
  | (AlertState & { close: () => void })
  | (ConfirmState & { answer: (args: ConfirmDialogAnswerArgs) => void });

const AlertDialog = (props: AlertDialogProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref.current?.focus();
  });
  useOnEnterClick({
    ref: ref,
    callback: () => {
      if (props.type === "alert") {
        props.close();
      }
    },
  });
  useOnEscapeClick(() => {
    if (props.type === "alert") {
      props.close();
    }
  });

  const publicErrorProps =
    props.type === "alert" && ErrorUtil.getErrorProps(props.args.error);

  return (
    <motion.div
      className="fixed z-50 flex h-full w-full flex-col items-center justify-center bg-bg-base-layer/50 p-8"
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      role="alertdialog"
      aria-labelledby="alertTitle"
      aria-describedby="alertPrompt"
    >
      <div
        className={__.classNames(
          "pointer-events-auto flex w-full max-w-lg flex-col gap-2 rounded border bg-white/90 p-4 shadow-lg"
          //props.typeOfAlert === "notification" && "border-green-400",
          //props.typeOfAlert === "error" && "border-red-400"
        )}
        role="document"
        tabIndex={0}
        ref={ref}
      >
        <h2 id="alertTitle" className="text-lg">
          {props.args.title}
        </h2>
        {!!publicErrorProps && (
          <h3 className="text-base font-semibold">
            {publicErrorProps.publicIdShorthand}
          </h3>
        )}
        <p id="alertPrompt" className="text-base text-gray-700">
          {props.args.prompt}
        </p>
        {props.type === "alert" && (
          <AppButton
            onClick={props.close}
            className="ml-auto mt-2 min-w-[100px]"
          >
            {props.args.buttonLabel ?? "OK"}
          </AppButton>
        )}
        {props.type === "confirm" && (
          <span className="ml-auto flex gap-2">
            <AppButton
              buttonMode="outline"
              onClick={() => {
                props.answer({ answer: false });
              }}
            >
              {props.args.noLabel ?? "Nej"}
            </AppButton>
            <AppButton
              onClick={() => {
                props.answer({ answer: true });
              }}
            >
              {props.args.yesLabel ?? "Ja"}
            </AppButton>
          </span>
        )}
      </div>
    </motion.div>
  );
};

export default AlertDialog;
