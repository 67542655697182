import { useState } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import __ from "../../../utils/utils";
import { UUID } from "../../../utils/UUID";

interface Props<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  errorMessage?: string;
  errors?: FieldErrors<T>;
  className?: string;
  boldLabel?: boolean;
  register: UseFormRegister<T>;
  options?: RegisterOptions;
  htmlAttributes?: Omit<JSX.IntrinsicElements["textarea"], "ref">;
  data?: Record<string, string>;
}

export function AppFormTextArea<T extends FieldValues>(props: Props<T>) {
  const [id] = useState(UUID.generate().value);

  const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError();
  let srOnlyLabel: string | undefined;
  if (!props.label && props.htmlAttributes?.placeholder) {
    srOnlyLabel = props.htmlAttributes.placeholder;
  }

  const data = __.objectKeyMap(props.data ?? {}, (key) => {
    return `data-${key}`;
  });

  return (
    <p className={__.classNames("flex w-full flex-col gap-2", props.className)}>
      {props.label && (
        <label
          htmlFor={id}
          className={__.classNames(props.boldLabel && "font-bold", "text-lg")}
        >
          {props.label}
        </label>
      )}
      {srOnlyLabel && (
        <label htmlFor={id} className="sr-only">
          {srOnlyLabel}
        </label>
      )}
      <textarea
        className={__.classNames(
          "w-full resize-none rounded-sm border p-2 placeholder:text-sm placeholder:italic placeholder:text-black/60",
          props.htmlAttributes?.readOnly
            ? "border-form-border-disabled"
            : "border-form-border"
        )}
        id={id}
        {...props.register(props.name, props.options)}
        {...props.htmlAttributes}
        {...data}
      ></textarea>
      {error && <span>{error}</span>}
    </p>
  );
}
