import { Route, Routes } from "react-router-dom";
import App from "./App";
import AppRoutes from "./routes";
import ErrorLogsRoute from "./routes/admin/ErrorLogsRoute";
import CustomerWorkOrderRoute from "./routes/customer/CustomerWorkOrderRoute";
import NotFoundRoute from "./routes/not-found/NotFoundRoute";
import ResetPasswordRoute from "./routes/reset/ResetPasswordRoute";
import SalesTeamOrderRoute from "./routes/sales/order/SalesTeamOrderRoute";
import SalesTeamPartnerListAssociationRoute from "./routes/sales/partner-list/SalesTeamPartnerListAssociationRoute";
import SalesTeamPartnerListRoute from "./routes/sales/partner-list/SalesTeamPartnerListRoute";
import SalesTeamPartnerListSingleRoute from "./routes/sales/partner-list/SalesTeamPartnerListSingleRoute";
import SignUpRoute from "./routes/sign-up/SignUpRoute";
import StaffRoot from "./routes/staff/StaffRoot";
import StaffTestRoute from "./routes/staff/StaffTestRoute";
import WorkerCompanyRoute from "./routes/worker/company/WorkerCompanyRoute";
import HandymanRoute from "./routes/worker/landing/HandymanRoute";
import HandymanWorkOrderRoute from "./routes/worker/order/HandymanWorkOrderRoute";
import HandymanProfileRoute from "./routes/worker/profile/HandymanProfileRoute";

interface Props {
  className?: string;
}

const AppRoutesComponent = (props: Props) => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <App>
            <NotFoundRoute />
          </App>
        }
      />

      <Route path={AppRoutes.root()} element={<App />}>
        <Route path={AppRoutes.staff.test()} element={<StaffTestRoute />} />
        <Route path={AppRoutes.staff.root()} element={<StaffRoot />} />
        <Route path={AppRoutes.signUp.root()} element={<SignUpRoute />} />
        <Route
          path={AppRoutes.resetPassword.root()}
          element={<ResetPasswordRoute />}
        />
        <Route
          path={AppRoutes.customer.root()}
          element={<CustomerWorkOrderRoute />}
        />
        <Route path={AppRoutes.partner.root()} element={<HandymanRoute />} />

        <Route
          path={AppRoutes.partner.profile()}
          element={<HandymanProfileRoute />}
        />
        <Route
          path={AppRoutes.partner.customerOrder()}
          element={<HandymanWorkOrderRoute />}
        />
        <Route
          path={AppRoutes.partner.company()}
          element={<WorkerCompanyRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.customerOrder()}
          element={<SalesTeamOrderRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.partnerList()}
          element={<SalesTeamPartnerListRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.partnerListWithAssociation()}
          element={<SalesTeamPartnerListAssociationRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.singlePartner()}
          element={<SalesTeamPartnerListSingleRoute />}
        />
        <Route
          path={AppRoutes.admin.errorLogs()}
          element={<ErrorLogsRoute />}
        />

        <Route index element={<NotFoundRoute />} />
      </Route>
    </Routes>
  );
};

export default AppRoutesComponent;
