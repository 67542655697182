import { ErrorLog, ErrorLogType } from "@eljouren/domain/build";
import { useState } from "react";
import { FiArrowUp } from "react-icons/fi";
import DateHelper from "../../_model/helpers/DateHelper";
import { __tsx } from "../../utils/tsxUtils";
import __ from "../../utils/utils";
import MoreThanOneContactErrorLogMain from "./MoreThanOneContactErrorLogMain";
import UnknownErrorLogMain from "./UnknownErrorLogMain";

interface Props {
  errorLog: ErrorLog.Type;
}

const ErrorLogListItem = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const log = props.errorLog;

  const heading = (log: ErrorLog.Type) => {
    switch (log.type) {
      case ErrorLogType.Enum.moreThanOneContactLinkedToEmail:
        return "Fler än en användare (contact) vid inlogg";
      case ErrorLogType.Enum.unknownError:
        return `Okänt fel - ${log.additionalInformation.errorMessage ?? ""}`;
      default:
        return `Okänt fel`;
    }
  };

  return (
    <li className="flex flex-col gap-3 rounded border p-4">
      <header
        className="grid w-full cursor-pointer grid-cols-[minmax(0,1fr),auto] gap-1"
        onClick={() => setExpanded(!expanded)}
      >
        <h2 className="overflow- w-full truncate text-lg">{heading(log)}</h2>
        <h3 className="text-sm font-semibold">{log.publicId}</h3>
        <span className="text-sm italic">
          {new DateHelper(log.date).dateTimeInputFormat.replace("T", " ")}
        </span>
        <FiArrowUp
          size={25}
          className={__.classNames(
            "col-start-2 row-span-3 row-start-1 my-auto",
            expanded && "rotate-180"
          )}
        />
      </header>
      {expanded &&
        __tsx.renderOne(log, [
          (log) =>
            log.type === ErrorLogType.Enum.moreThanOneContactLinkedToEmail && (
              <MoreThanOneContactErrorLogMain log={log} />
            ),
          (log) =>
            log.type === ErrorLogType.Enum.unknownError && (
              <UnknownErrorLogMain log={log} />
            ),
          (log) => <p>Ingen mer information finns</p>,
        ])}
    </li>
  );

  return <li>Okänt fel </li>;
};

export default ErrorLogListItem;
