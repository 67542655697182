import { useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import PageSection from "../../../components/common/PageSection";
import { useRepos } from "../../../hooks/use-repos";
import ICompanyRepo from "../../../_model/repos/interfaces/ICompanyRepo";
import HandymanCompanyEmployeeListItem from "./HandymanCompanyEmployeeListItem";
import WorkerCompanyRouteContext from "./WorkerCompanyRouteContext";

interface Props {
  className?: string;
}

const HandymanCompanyEmployeeTab = (props: Props) => {
  const { handyman: worker } = useContext(WorkerCompanyRouteContext);
  const client = useQueryClient();
  const { companyRepo } = useRepos();

  const employeeRes = useQuery(["employees", worker.companyId, worker.id], () =>
    companyRepo.fetchEmployees()
  );

  const setPricePermissionMutation = useMutation(
    async (
      ...args: Parameters<ICompanyRepo["setEmployeePricePermissions"]>
    ) => {
      return companyRepo.setEmployeePricePermissions(...args);
    },
    {
      onSettled: () => {
        client.invalidateQueries(["employees"]);
      },
    }
  );

  return (
    <LoadingContent loading={employeeRes.isLoading} renderContentSeparately>
      <PageSection as="section" className="flex h-full flex-col gap-2">
        <main>
          {employeeRes.data && !!employeeRes.data.length && (
            <ul className="flex flex-col gap-4">
              {employeeRes.data.map((employee) => (
                <HandymanCompanyEmployeeListItem
                  key={employee.id}
                  employee={employee}
                  setPriceMermissionMutation={setPricePermissionMutation}
                />
              ))}
            </ul>
          )}
          {employeeRes.data && !employeeRes.data.length && (
            <p>Vi hittar inga anställda för detta företag</p>
          )}
          {!employeeRes.isLoading && employeeRes.isError && (
            <p>
              Det gick inte att hämta företagets anställda just nu. Vänligen
              försök igen senare.
            </p>
          )}
        </main>
      </PageSection>
    </LoadingContent>
  );
};

export default HandymanCompanyEmployeeTab;
