"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRepoGetFileLinksResponse = void 0;
var zod_1 = require("zod");
var FileErrorReason_1 = require("./FileErrorReason");
var FileRepoGetFileLinksResponse;
(function (FileRepoGetFileLinksResponse) {
    var Success = zod_1.z.object({
        success: zod_1.z.literal(true),
        files: zod_1.z.string().array(),
    });
    var Failure = zod_1.z.object({
        success: zod_1.z.literal(false),
        reason: FileErrorReason_1.FileErrorReason.Schema,
        fileServiceJson: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
        error: zod_1.z.unknown().optional(),
    });
    FileRepoGetFileLinksResponse.Schema = zod_1.z.discriminatedUnion("success", [Success, Failure]);
})(FileRepoGetFileLinksResponse || (FileRepoGetFileLinksResponse = {}));
exports.FileRepoGetFileLinksResponse = FileRepoGetFileLinksResponse;
