import { Link, LinkProps } from "react-router-dom";

export type MyLinkProps = Omit<LinkProps, "to"> & {
  to: string;
  state?: object;
};

const MyLink: React.FC<MyLinkProps> = ({ to, state, ...rest }) => {
  const linkProps: LinkProps = {
    ...rest,
    to: {
      pathname: to,
    },
    state: {
      ...state,
      previousLocation: window.location.href,
    },
  };

  return <Link {...linkProps} />;
};

export default MyLink;
