"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyPermissionLevel = void 0;
/*
    ToDo: better name
*/
var CompanyPermissionLevel;
(function (CompanyPermissionLevel) {
    var Enum;
    (function (Enum) {
        Enum["inCharge"] = "inCharge";
        Enum["employed"] = "employed";
    })(Enum = CompanyPermissionLevel.Enum || (CompanyPermissionLevel.Enum = {}));
})(CompanyPermissionLevel || (CompanyPermissionLevel = {}));
exports.CompanyPermissionLevel = CompanyPermissionLevel;
