import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import withWorkerCredentials from "../../../components/auth/hocs/withWorkerCredentials";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import ProfileFormSection from "../../../components/forms/profile-form/ProfileFormSection";
import AppPage from "../../../components/layout/AppPage";
import useMutatableQuery from "../../../hooks/use-mutatable-query";
import { useRepos } from "../../../hooks/use-repos";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";

const HandymanProfileRoute = (props: TWorkerSignInData) => {
  const navigate = useNavigate();

  const { handymanRepo } = useRepos();
  const queryClient = useQueryClient();
  const handymanRes = useMutatableQuery({
    queryKey: ["handyman", props.handyman.id],
    queryFn: () => handymanRepo.fetchHandyman(props.handyman.id),
    initialData: () => {
      return queryClient.getQueryData("signInStateProfile");
    },
  });

  useEffect(() => {
    if (
      handymanRes.query.isError ||
      (!handymanRes.query.isLoading && !handymanRes.query.data)
    ) {
      navigate("/partner");
    }
  }, [
    handymanRes.query.isLoading,
    handymanRes.query.isError,
    handymanRes.query.data,
    navigate,
  ]);

  return (
    <LoadingContent
      loading={handymanRes.query.isLoading && !handymanRes.query.data}
      renderContentSeparately
    >
      <AppPage
        className="mx-auto grid h-full w-full grid-rows-[auto,minmax(0,1fr)]"
        headerProps={{
          heading: "Profil",
        }}
      >
        <main className="h-full p-2 lg:p-4">
          {handymanRes.query.data && (
            <ProfileFormSection
              user={handymanRes.query.data}
              loading={handymanRes.query.isLoading}
              updateProfileInfo={async (handyman) => {
                handymanRes.mutate(
                  () => handymanRepo.updateProfileInfo(handyman),
                  {
                    optimisticUpdate: handyman,
                  }
                );
              }}
            />
          )}
        </main>
      </AppPage>
    </LoadingContent>
  );
};

export default withWorkerCredentials(HandymanProfileRoute);
