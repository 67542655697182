"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProvinceWithCountiesSchema = exports.WorkingHoursSchema = exports.OffWorkWorkingHoursSchema = exports.ReportedWorkingHoursSchema = void 0;
var zod_1 = require("zod");
/*
WORKING HOURS
*/
exports.ReportedWorkingHoursSchema = zod_1.z.object({
    status: zod_1.z.literal("reported"),
    startOfDay: zod_1.z.date(),
    start: zod_1.z.date(),
    end: zod_1.z.date(),
});
exports.OffWorkWorkingHoursSchema = zod_1.z.object({
    status: zod_1.z.literal("offWork"),
    startOfDay: zod_1.z.date(),
});
exports.WorkingHoursSchema = zod_1.z.discriminatedUnion("status", [
    exports.ReportedWorkingHoursSchema,
    exports.OffWorkWorkingHoursSchema,
]);
/*
  Lmao this name
*/
exports.ProvinceWithCountiesSchema = zod_1.z.object({
    name: zod_1.z.string(),
    counties: zod_1.z.string().array(),
});
