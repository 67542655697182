import React from "react";
import ProcessQueue from "../../utils/process/ProcessQueue";
import Process from "../../utils/process/Process";

const QueueContext = React.createContext<{
  queue: ProcessQueue | null;
  previousQueue: ProcessQueue | null;
  addToQueue: (process: Process) => void;
  cleanUp: () => void;
}>({} as never);

export default QueueContext;
