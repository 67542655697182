import { useContext } from "react";
import { Path } from "react-hook-form";
import { SiGooglemaps } from "react-icons/si";
import { GlobalContext } from "../../../top-level-contexts";
import __ from "../../../utils/utils";
import { WorkOrder } from "@eljouren/domain";
import { AppFormTextField } from "../../common/text-fields/AppFormTextField";
import ControlledDatePicker from "../../common/time/ControlledDatePicker";
import DatePicker from "../../common/time/DatePicker";
import { CustomerOrderDefaultFormFieldProps } from "./CustomerOrderDefaultFormFields";

const CustomerOrderTimeAndPlaceFieldset = (
  props: CustomerOrderDefaultFormFieldProps
) => {
  const allowEditing: Partial<Record<Path<WorkOrder.Type>, boolean>> =
    props.allowEditing || {};

  const {
    signInState: { signedInAs },
  } = useContext(GlobalContext);

  const signedInAsWorker = signedInAs === "worker";

  function addressString(order: WorkOrder.Type): string | false {
    const { city, street, postalCode } = order.location;
    if (!city || !street || !postalCode) {
      return false;
    }
    return `${street}, ${postalCode} ${city}`;
  }

  function googleMapsUrl(order: WorkOrder.Type): string {
    const address = addressString(order);
    if (!address) {
      return "";
    }
    const base = "https://www.google.com/maps/search/?";
    const params = new URLSearchParams({
      api: "1",
      query: address,
    });
    return `${base}${params}`;
  }

  const GoogleMapsLink = (props: { order: WorkOrder.Type }) => {
    const address = addressString(props.order);
    if (!address) {
      return <></>;
    }
    const href = googleMapsUrl(props.order);

    return (
      <a
        className="flex items-center gap-2 py-2 text-base hover:underline"
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <SiGooglemaps size={20} />
        <span>{address}</span>
      </a>
    );
  };

  return (
    <fieldset
      className={__.classNames("flex flex-col gap-2 pb-6", props.className)}
    >
      <header>
        <h3 className="text-xl">Tid och plats</h3>
        {signedInAsWorker && <GoogleMapsLink {...props} />}
      </header>
      <main className="grid grid-cols-4 gap-2 md:gap-4">
        <AppFormTextField
          className="col-span-2"
          register={props.register}
          name="location.city"
          label="Stad"
          htmlAttributes={{
            readOnly: !allowEditing["location.city"],
            disabled: !allowEditing["location.city"],
          }}
        />
        <AppFormTextField
          className="col-span-2"
          register={props.register}
          name="location.street"
          label="Gata"
          htmlAttributes={{
            readOnly: !allowEditing["location.street"],
            disabled: !allowEditing["location.street"],
          }}
        />
        <AppFormTextField
          className="col-span-2"
          register={props.register}
          name="location.postalCode"
          label="Postnummer"
          htmlAttributes={{
            readOnly: !allowEditing["location.postalCode"],
            disabled: !allowEditing["location.postalCode"],
          }}
        />
        <AppFormTextField
          className="col-span-1"
          register={props.register}
          name="location.floor"
          label="Våning"
          htmlAttributes={{
            readOnly: !allowEditing["location.floor"],
            disabled: !allowEditing["location.floor"],
          }}
        />
        <AppFormTextField
          className="col-span-1"
          register={props.register}
          name="location.doorCode"
          label="Portkod"
          htmlAttributes={{
            readOnly: !allowEditing["location.doorCode"],
            disabled: !allowEditing["location.doorCode"],
          }}
        />

        <p className="col-span-4 flex flex-col">
          <label className="text-lg" htmlFor="startDate">
            Datum och tid
          </label>
          {"control" in props && (
            <ControlledDatePicker
              control={props.control}
              name={"startDate"}
              id="startDate"
              htmlAttributes={{
                readOnly: !allowEditing["location.startDate"],
                disabled: !allowEditing["location.startDate"],
              }}
              type="datetime-local"
            />
          )}
          {"startDate" in props && (
            <DatePicker
              htmlAttributes={{
                readOnly: true,
                disabled: true,
              }}
              id={"startDate"}
              date={props.startDate}
              type="datetime-local"
            />
          )}
        </p>
      </main>
    </fieldset>
  );
};

export default CustomerOrderTimeAndPlaceFieldset;
