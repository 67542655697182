export namespace __tsx {
  export function renderOne<T>(
    args: T,
    renderFunctions: ((
      props: T
    ) => React.ReactElement | null | undefined | false)[]
  ): React.ReactNode {
    for (const renderFunction of renderFunctions) {
      const element = renderFunction(args);
      if (element) {
        return element;
      }
    }

    return null;
  }
}
