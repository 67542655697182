import WorkOrderFinishedReport from "@eljouren/domain/build/work-order/WorkOrderFinishedReport";
import { AnimatePresence, motion } from "framer-motion";
import { UseFormReturn } from "react-hook-form";
import { AppButton } from "../../../components/common/buttons/AppButton";
import AppTextButton from "../../../components/common/buttons/AppTextButton";
import { AppFormTextArea } from "../../../components/common/text-areas/AppFormTextArea";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import ControlledDatePicker from "../../../components/common/time/ControlledDatePicker";
import __ from "../../../utils/utils";

interface Props {
  className?: string;
  form: UseFormReturn<WorkOrderFinishedReport.Type>;
  onSubmit(values: WorkOrderFinishedReport.Type): any;
  onClose(): any;
}

const HandymanWorkOrderMarkAsFinishedModal = (props: Props) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={__.classNames(
        props.className,
        "fixed left-0 top-0 z-50 flex h-full w-full flex-col bg-white/90 p-4 md:pt-32"
      )}
    >
      <form
        onSubmit={props.form.handleSubmit(props.onSubmit)}
        className="mx-auto flex h-full w-full max-w-screen-lg flex-col gap-2 overflow-auto p-4"
      >
        <header className="flex w-full justify-between gap-2 py-2">
          <h2 className="text-xl">Klarmarkera arbetet</h2>
        </header>
        <main className="flex grow flex-col gap-2 overflow-auto p-2">
          <fieldset className="flex flex-col gap-2">
            <h3 className="text-lg">
              Behövs det bokas ett återkommande arbete?
            </h3>
            <p className="flex gap-2">
              <input
                className="cursor-pointer p-1"
                type="radio"
                id="rebookingFalse"
                value={"no"}
                {...props.form.register("rebooking")}
              />
              <label
                htmlFor="rebookingFalse"
                className="max-w-full cursor-pointer p-1"
              >
                Nej
              </label>
            </p>
            <p className="flex gap-2">
              <input
                className="cursor-pointer p-1"
                type="radio"
                id="rebookingTrue"
                value={"yes"}
                {...props.form.register("rebooking")}
              />
              <label
                htmlFor="rebookingTrue"
                className="max-w-full cursor-pointer p-1"
              >
                Ja
              </label>
            </p>
          </fieldset>

          <AnimatePresence>
            {props.form.getValues("rebooking") === "yes" && (
              <motion.fieldset
                className="flex w-full flex-col gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <AppFormTextArea
                  register={props.form.register}
                  boldLabel
                  name="description"
                  label="Vad ska utföras på arbetet?"
                  htmlAttributes={{
                    maxLength: 255,
                    minLength: 1,
                  }}
                />
                <AppFormTextField
                  register={props.form.register}
                  name="timeEstimateInHours"
                  label="Uppskattad tidsåtgång i timmar"
                  htmlAttributes={{
                    step: 0.5,
                    min: 0.5,
                    type: "number",
                  }}
                />
                <p className="flex items-center gap-2">
                  <input
                    id="selfBooking"
                    type="checkbox"
                    className="h-4 w-4 cursor-pointer"
                    {...props.form.register("newDateBooked")}
                  ></input>
                  <label htmlFor="selfBooking" className="cursor-pointer">
                    Jag har bokat in ett nytt datum
                  </label>
                </p>

                <p
                  className={__.classNames(
                    "flex flex-col gap-2",
                    !props.form.getValues("newDateBooked") &&
                      "pointer-events-none opacity-50"
                  )}
                >
                  <label htmlFor="date">Datum och tid</label>
                  <ControlledDatePicker
                    id="date"
                    control={props.form.control}
                    name="date"
                    type="datetime-local"
                  />
                </p>
              </motion.fieldset>
            )}
          </AnimatePresence>
        </main>
        <footer className="flex flex-col items-end gap-2">
          <AppButton disabled={!props.form.formState.isValid}>
            Bekräfta klarmarkering
          </AppButton>
          <AppTextButton type="button" onClick={props.onClose}>
            Gå tillbaka
          </AppTextButton>
        </footer>
      </form>
    </motion.section>
  );
};

export default HandymanWorkOrderMarkAsFinishedModal;
