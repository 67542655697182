import { HandymanWorkingHours, WorkOrder } from "@eljouren/domain/build";
import __ from "../../utils/utils";
import DateHelper from "./DateHelper";

export type TOrdersAndHourMerge = {
  workOrders: WorkOrder.HandymanWithPermissionsType[];
  workingHours: HandymanWorkingHours.Type | undefined;
};

export type TScheduleGap = {
  start: Date;
  end: Date;
};

export default class WorkOrdersAndWorkingHoursMerger {
  private dict: {
    [startOfDayTimestamp: string]: TOrdersAndHourMerge;
  };

  constructor(
    private props: {
      workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType;
      workOrders: WorkOrder.HandymanWithPermissionsType[];
    }
  ) {
    this.dict = __.objectMap(props.workingHours, (val) => ({
      workingHours: val,
      workOrders: [],
    }));

    props.workOrders.forEach((order) => {
      const helper = new DateHelper(order.startDate);
      const timestamp = helper.startOfDay.date.getTime();
      if (timestamp in this.dict) {
        this.dict[timestamp].workOrders.push(order);
      } else {
        this.dict[timestamp] = {
          workingHours: undefined,
          workOrders: [order],
        };
      }
    });
  }

  get(timestamp: Date | string) {
    const key =
      typeof timestamp === "string"
        ? timestamp
        : new DateHelper(timestamp).startOfDay.date.getTime();

    //console.log({ timestamp, key, value: this.dict[key] });

    if (key in this.dict) {
      return this.dict[key];
    } else {
      return { workingHours: undefined, workOrders: [] };
    }
  }

  getScheduleGaps(
    timestamp: Date | string,
    options: {
      minMinutesToConsiderAGap?: number;
    } = {}
  ): false | TScheduleGap[] {
    const merge = this.get(timestamp);

    if (!merge.workingHours || merge.workingHours?.status === "offWork") {
      return false;
    }

    const _wh: HandymanWorkingHours.ReportedType = merge.workingHours;

    /*
    Atleast 2 hours to be considered a gap unless something else is passed in
    */
    const minMinutesToConsiderAGap = options.minMinutesToConsiderAGap ?? 120;
    //const endOfWorkLeewayInMinutes = 15;

    const orders = merge.workOrders;
    const sortedOrders = orders.sort(
      (a, b) => Number(a.startDate) - Number(b.startDate)
    );
    /*
    If there are no work orders planned, return the full span of the working hours
    */
    if (!sortedOrders.length) {
      return [{ start: _wh.start, end: _wh.end }];
    }

    let start = _wh.start;
    let end = _wh.end;
    orders.forEach((order) => {
      const startHelper = new DateHelper(order.startDate);
      const endHelper = new DateHelper(order.endDate);
      if (startHelper.isBefore(start)) {
        start = startHelper.date;
      }
      if (endHelper.isAfter(end)) {
        end = endHelper.date;
      }
    });

    const gaps: TScheduleGap[] = [];
    let lastEndDateHelper = new DateHelper(start);

    sortedOrders.every((order) => {
      const orderStartsAfterEndOfWorkingHours = new DateHelper(
        order.startDate
      ).isAfter(end);
      if (orderStartsAfterEndOfWorkingHours) {
        return false;
      }

      const diff = new DateHelper(order.startDate).differenceIn(
        "minutes",
        lastEndDateHelper.date
      );

      /* const minutesAfterEndOfWork = new DateHelper(order.endDate).differenceIn(
        "minutes",
        end
      ); */

      /* console.log({
        lastDate: lastEndDateHelper.date,
        diff,
        orderStartDate: order.startDate,
        orderEndDate: order.endDate,
        minutesAfterEndOfWork,
        endOfWork: end,
      }); */

      if (
        diff >= minMinutesToConsiderAGap
        //minutesAfterEndOfWork <= endOfWorkLeewayInMinutes
      ) {
        const gap = {
          start: lastEndDateHelper.date,
          end: order.startDate,
        };
        gaps.push(gap);
      }

      lastEndDateHelper = new DateHelper(order.endDate);

      return true;
    });

    const diffToEndOfWork = new DateHelper(end).differenceIn(
      "minutes",
      lastEndDateHelper.date
    );

    console.log({
      diffToEndOfWork,
      lastEndDate: lastEndDateHelper.date,
      whEnd: end,
    });

    if (diffToEndOfWork >= minMinutesToConsiderAGap) {
      gaps.push({
        start: lastEndDateHelper.date,
        end: end,
      });
    }

    if (!gaps.length) {
      return false;
    }
    return gaps;
  }
}
