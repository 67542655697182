// ProcessTask.ts

// Define types for callback functions and their return values
type TaskFunc<T> = () => Promise<T>;
type ErrorCallback = (error: unknown) => void | { errorMessage: string };
type ResultCallback<T> = (result: T) => void | MixedResultWithErrorMessage<T>;
type MixedResult<T> =
  | { success: false; error: unknown }
  | { success: true; result: T };
type MixedResultWithErrorMessage<T> = MixedResult<T> & {
  errorMessage?: string;
};
type MixedCallback<T> = (
  result: MixedResult<T>
) => void | MixedResultWithErrorMessage<T>;

// Define the argument type for the ProcessTask constructor
export type ProcessTaskArgs<T> = {
  name: string;
  func: TaskFunc<T>;
  onFinished?: MixedCallback<T>;
  onSuccess?: ResultCallback<T>;
  onError?: ErrorCallback;
};

// The ProcessTask class represents a single async task within a process
export default class ProcessTask<T = any> {
  name: string;
  func: TaskFunc<T>;
  private onFinishedCallbacks: MixedCallback<T>[];
  private onSuccessCallbacks: ResultCallback<T>[];
  private onErrorCallbacks: ErrorCallback[];
  private _result: MixedResult<T> | null;
  private _errorMessages: string[];

  constructor(args: ProcessTaskArgs<T>) {
    this.name = args.name;
    this.func = args.func;
    this.onFinishedCallbacks = args.onFinished ? [args.onFinished] : [];
    this.onSuccessCallbacks = args.onSuccess ? [args.onSuccess] : [];
    this.onErrorCallbacks = args.onError ? [args.onError] : [];
    this._result = null;
    this._errorMessages = [];
  }

  // Run the task and handle the result or error
  async run() {
    try {
      const result = await this.func();
      this._result = { success: true, result };
      this.handleOnSuccess(result);
      this.handleOnFinished(this._result);
    } catch (error) {
      this._result = { success: false, error };
      this.handleOnError(error);
      this.handleOnFinished(this._result);
    }
  }

  // Get the result of the task
  getResult(): MixedResult<T> | null {
    return this._result;
  }

  // Register a callback to be called when the task is finished
  onFinished(callback: MixedCallback<T>) {
    this.onFinishedCallbacks.push(callback);
  }

  // Register a callback to be called when the task is successful
  onSuccess(callback: ResultCallback<T>) {
    this.onSuccessCallbacks.push(callback);
  }

  // Register a callback to be called when the task encounters an error
  onError(callback: ErrorCallback) {
    this.onErrorCallbacks.push(callback);
  }

  // Handle calling the onFinished callbacks and capturing custom error messages
  private handleOnFinished(resultOrError: MixedResult<T>) {
    this.onFinishedCallbacks.forEach((callback) => {
      const response = callback(resultOrError);
      if (typeof response === "object" && response.success !== undefined) {
        this._result = response;
        if (response.errorMessage) {
          this._errorMessages.push(response.errorMessage);
        }
      }
    });
  }

  // Handle calling the onSuccess callbacks and capturing custom error messages
  private handleOnSuccess(result: T) {
    this.onSuccessCallbacks.forEach((callback) => {
      const response = callback(result);
      if (typeof response === "object" && response.success !== undefined) {
        this._result = response;
        if (response.errorMessage) {
          this._errorMessages.push(response.errorMessage);
        }
      }
    });
  }

  // Handle calling the onError callbacks and capturing custom error messages
  private handleOnError(error: unknown) {
    this.onErrorCallbacks.forEach((callback) => {
      const response = callback(error);
      if (typeof response === "object" && response.errorMessage) {
        this._errorMessages.push(response.errorMessage);
      }
    });
  }

  getErrorMessage(index: number = 0): string | null {
    return this._errorMessages[index] ?? null;
  }
  // Get the error messages captured from callback functions
  getErrorMessages(): string[] {
    return [...this._errorMessages];
  }
}
