"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ErrorLogAccessLevel_1 = require("../ErrorLogAccessLevel");
var ErrorLogLevel_1 = require("../ErrorLogLevel");
var zod_1 = require("zod");
var ErrorLogBase;
(function (ErrorLogBase) {
    ErrorLogBase.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        publicId: zod_1.z.string(),
        date: zod_1.z.date(),
        level: ErrorLogLevel_1.ErrorLogLevel.Schema,
        accessLevel: ErrorLogAccessLevel_1.ErrorLogAccessLevel.Schema,
    });
})(ErrorLogBase || (ErrorLogBase = {}));
exports.default = ErrorLogBase;
