"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderExtraHour = void 0;
var zod_1 = require("zod");
/*
 The billable hour you can add to a work order when it takes longer
 than expected
*/
var WorkOrderExtraHour;
(function (WorkOrderExtraHour) {
    WorkOrderExtraHour.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
        unitPrice: zod_1.z.number(),
    });
})(WorkOrderExtraHour || (WorkOrderExtraHour = {}));
exports.WorkOrderExtraHour = WorkOrderExtraHour;
