"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileServerDeleteFileResponse = void 0;
var CollectionType_1 = require("../../shared-schemas/CollectionType");
var UploaderSource_1 = require("../../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var FileServerDeleteFileResponse;
(function (FileServerDeleteFileResponse) {
    FileServerDeleteFileResponse.Schema = zod_1.z.object({
        recordId: zod_1.z.string(),
        uploadedBy: UploaderSource_1.UploaderSource.Schema,
        collectionType: CollectionType_1.CollectionType.Schema,
        guid: zod_1.z.string(),
        name: zod_1.z.string(),
    });
})(FileServerDeleteFileResponse || (FileServerDeleteFileResponse = {}));
exports.FileServerDeleteFileResponse = FileServerDeleteFileResponse;
