import { isValid, parse } from "date-fns";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import __ from "../../../utils/utils";
import DateHelper from "../../../_model/helpers/DateHelper";

interface Props<T extends FieldValues> {
  className?: string;
  id?: string;
  name: Path<T>;
  control: Control<T>;
  type: "time" | "datetime-local" | "date";
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
}

function ControlledDatePicker<T extends FieldValues>(props: Props<T>) {
  const attributes = props.htmlAttributes || {};

  function getFormat() {
    switch (props.type) {
      case "date":
        return "yyyy-MM-dd";
      case "datetime-local":
        return "yyyy-MM-dd'T'HH:mm";
      case "time":
        return "k:m";
    }
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {
        let value: string;

        if (field.value && isValid(field.value)) {
          const helper = new DateHelper(field.value);
          switch (props.type) {
            case "date":
              value = helper.dateInputFormat;
              break;
            case "datetime-local":
              value = helper.dateTimeInputFormat;
              break;
            case "time":
              value = helper.timeInputFormat;
              break;
          }
        } else {
          value = "";
        }

        return (
          <input
            id={props.id}
            ref={field.ref}
            className={__.classNames(
              "border-2 border-solid border-gray-300  p-1",
              props.htmlAttributes?.readOnly
                ? "border-gray-200"
                : "border-blue-300",
              props.className
            )}
            type={props.type}
            value={value}
            name={props.name}
            onBlur={field.onBlur}
            onChange={(e) => {
              const value = e.target.value;
              const date = parse(value, getFormat(), new Date());
              field.onChange(date);
            }}
            {...attributes}
          />
        );
      }}
    />
  );
}

export default ControlledDatePicker;
