"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRepoGetFileResponse = void 0;
var zod_1 = require("zod");
var FileErrorReason_1 = require("./FileErrorReason");
var FileRepoGetFileResponse;
(function (FileRepoGetFileResponse) {
    var Success = zod_1.z.object({
        success: zod_1.z.literal(true),
        arrayBuffer: zod_1.z.instanceof(ArrayBuffer),
        httpHeaders: zod_1.z.object({
            "Content-Type": zod_1.z.string(),
            "Content-Disposition": zod_1.z.string(),
            "Content-Length": zod_1.z.string(),
        }),
    });
    var Failure = zod_1.z.object({
        success: zod_1.z.literal(false),
        reason: FileErrorReason_1.FileErrorReason.Schema,
        fileServiceJson: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
        error: zod_1.z.unknown().optional(),
    });
    FileRepoGetFileResponse.Schema = zod_1.z.discriminatedUnion("success", [Success, Failure]);
})(FileRepoGetFileResponse || (FileRepoGetFileResponse = {}));
exports.FileRepoGetFileResponse = FileRepoGetFileResponse;
