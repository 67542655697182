import { isPast } from "date-fns";
import { TCalendarEvent } from "./types";

export default class CalendarEvent {
  constructor(readonly props: TCalendarEvent) {}

  get hasPassed() {
    return isPast(this.props.end);
  }
}
