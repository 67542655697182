import { Handyman } from "@eljouren/domain";
import React, { useRef } from "react";
import { FiArrowRight, FiUser } from "react-icons/fi";
import { UseMutationResult } from "react-query";
import { Link } from "react-router-dom";
import { useBundledState } from "../../../hooks/hooks";
import AppRoutes from "../../../routes";
import __ from "../../../utils/utils";
import MyLink from "../../../components/routing/MyLink";

interface Props {
  className?: string;
  employee: Handyman.Type;
  setPriceMermissionMutation: UseMutationResult<
    void,
    unknown,
    {
      employeeId: string;
      permittedToViewPrices: boolean;
    },
    unknown
  >;
}

const HandymanCompanyEmployeeListItem = (props: Props) => {
  const inputId = `showPricesCheckboxFor${props.employee.id}`;
  const timeoutRef = useRef<undefined | NodeJS.Timeout>();

  const showPricesBundle = useBundledState(
    props.employee.permittedToViewPrices
  );

  function onShowPricesChange(e: React.ChangeEvent<HTMLInputElement>) {
    const showPrices = e.target.checked;
    showPricesBundle.set(showPrices);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (showPrices !== props.employee.permittedToViewPrices) {
      timeoutRef.current = setTimeout(async () => {
        if (props.setPriceMermissionMutation.isLoading) {
          console.log("is loading");
          showPricesBundle.set(props.employee.permittedToViewPrices);
          return false;
        }
        try {
          await props.setPriceMermissionMutation.mutateAsync({
            employeeId: props.employee.id,
            permittedToViewPrices: showPrices,
          });
        } catch (er) {
          window.modal.alert({
            title: "Det gick inte att spara ändringarna just nu",
            prompt: "Vänligen försök igen senare",
            typeOfAlert: "error",
            error: er,
          });
          showPricesBundle.set(props.employee.permittedToViewPrices);
        }
      }, 750);
    }
  }

  return (
    <li
      className={__.classNames(
        "flex flex-col border",
        props.setPriceMermissionMutation.isLoading &&
          "pointer-events-none opacity-80",
        props.className
      )}
    >
      <MyLink
        className="grid grid-cols-[auto,minmax(0,1fr),auto] items-center gap-x-4 py-2 px-2 transition-all hover:bg-blue-100"
        to={AppRoutes.partner.root({ userId: props.employee.id })}
        state={{ worker: props.employee }}
      >
        <FiUser size={25} className="row-span-2" />
        <span className="col-start-2 row-start-1 text-lg">
          {props.employee.firstName} {props.employee.lastName}
        </span>
        <span className="col-start-2 row-start-2">
          {props.employee.companyRole === "handyman"
            ? "Hantverkare"
            : "Arbetsledare"}
        </span>
        <FiArrowRight
          className="col-start-3 row-span-2 my-auto ml-auto"
          size={25}
        />
      </MyLink>
      {props.employee.companyRole === "handyman" && (
        <p className="ml-auto flex w-full items-center justify-end gap-2 bg-gray-300/40 p-2">
          <label htmlFor={inputId} className="cursor-pointer">
            Visa ersättning
          </label>
          <input
            onChange={onShowPricesChange}
            className="h-4 w-4 cursor-pointer"
            type="checkbox"
            id={inputId}
            checked={showPricesBundle.value}
          />
        </p>
      )}
    </li>
  );
};

export default HandymanCompanyEmployeeListItem;
