import { UpdateType } from "./UpdateType";
import { UUID } from "../../utils/UUID";
import __ from "../../utils/utils";
import isEqual from "lodash.isequal";
import { MLogger } from "../../logging/MLogger";

export type ObservableSettings<T> = {
  comparisonType?: ObservableComparisonType<T>;
};

export type ObservableComparisonType<T> =
  | "lodashIsEqual"
  | "alwaysNotify"
  | ((newValue: T, prevValue: T | undefined) => boolean);

export type PrivateObservable<T> = Pick<
  Observable<T>,
  "subscribe" | "subscribeWithInitial" | "value"
>;

export type ObsCallback<T> = (
  newValue: T,
  oldValue?: T,
  updateType?: UpdateType
) => void;

export interface IObsSubscriber<T> {
  callback: ObsCallback<T>;
  updateType?: UpdateType;
}

export class Observable<T> {
  private subscribers: {
    [id: string]: IObsSubscriber<T>;
  } = {};

  constructor(
    protected _value: T,
    private readonly settings: ObservableSettings<T> = {},
    private logMessageOnUpdate?: string
  ) {}

  get privateObs(): PrivateObservable<T> {
    const instance = this;
    return {
      subscribe: (...params: Parameters<Observable<T>["subscribe"]>) =>
        this.subscribe(...params),
      subscribeWithInitial: (
        ...params: Parameters<Observable<T>["subscribeWithInitial"]>
      ) => this.subscribeWithInitial(...params),
      get value() {
        return instance._value;
      },
    };
  }

  protected shouldSubscribersBeNotified(
    oldValue: T | undefined,
    newValue: T
  ): boolean {
    const { comparisonType } = this.settings;

    if (comparisonType === undefined) {
      return !isEqual(oldValue, newValue);
    } else if (comparisonType instanceof Function) {
      return comparisonType(newValue, oldValue);
    } else {
      switch (comparisonType) {
        case "lodashIsEqual":
          return !isEqual(oldValue, newValue);
        case "alwaysNotify":
        default:
          return true;
      }
    }
  }

  /*
        Subscribe to updates.

        If an updateType is provided, the subscriber will only be notified
        if the updateType of the change matches the updateType provided.

        returns an unsubscribe method
    */
  subscribe(
    callback: ObsCallback<T>,
    updateType: UpdateType | undefined = undefined
  ): () => void {
    const subscriber = { callback, updateType };
    const id = UUID.generate().value;
    this.subscribers[id] = subscriber;

    return () => {
      delete this.subscribers[id];
    };
  }

  /*
        Same as subscribe(), but also initially calls the callback-function
        once, as a way to make it easier to initiate variables. 
    */
  subscribeWithInitial(
    callback: ObsCallback<T>,
    updateType: UpdateType | undefined = undefined
  ) {
    callback(this.value);
    return this.subscribe(callback, updateType);
  }

  set(newValue: T) {
    this.updateWithType(newValue, UpdateType.SET);
  }

  sync(newValue: T) {
    this.updateWithType(newValue, UpdateType.SYNC);
  }

  updateWithType(newValue: T, type: UpdateType) {
    const oldValue = this._value;
    this._value = newValue;
    if (this.shouldSubscribersBeNotified(oldValue, newValue)) {
      this.notify(newValue, oldValue, type);
    }
  }

  bindTo<T2>(
    args:
      | Observable<T>
      | {
          obs: Observable<T2>;
          convert(value: T2): T;
        }
  ) {
    if ("subscribeWithInitial" in args) {
      return args.subscribeWithInitial((value: T) => {
        this.set(value);
      });
    } else {
      return args.obs.subscribeWithInitial((value: T2) => {
        this.set(args.convert(value));
      });
    }
  }

  /*
        Notifies each subscriber that either provided no updateType when subscribing,
        or provided the matching updateType for the change.
    */
  protected notify(newValue: T, oldValue: T | undefined, type: UpdateType) {
    if (this.logMessageOnUpdate) {
      MLogger.trace("Observable.notify()", {
        message: this.logMessageOnUpdate,
        newValue,
        oldValue,
        type,
      });
    }
    Object.values(this.subscribers).forEach(({ callback, updateType }) => {
      if (__.isNull(updateType) || updateType === type) {
        callback(newValue, oldValue, type);
      }
    });
  }

  get value() {
    return this._value;
  }
}
