import { Handyman } from "@eljouren/domain/build";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useBundledState } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import ProfileFormSingleInput from "./ProfileFormSingleInput";

type Props = {
  label: string;
  id: string;
  canEdit: boolean;
  //isEditing: boolean;
  children?: React.ReactNode;
  value: string;
  className?: string;
} & (
  | {
      paths: (
        | keyof Handyman.Type
        | { path: keyof Handyman.Type; label?: string }
      )[];
      form: UseFormReturn<Handyman.Type>;
      onEdit?: undefined;
      onSave?(): void;
    }
  | { onEdit(): void; paths?: undefined; onSave?: undefined }
);

const ProfileFormMergedInput = (props: Props) => {
  const editBundle = useBundledState(false);

  function getStaticInputLabel(): string | undefined {
    if (props.paths?.length === 1) {
      return props.label;
    }

    return undefined;
  }

  return (
    <div
      className={__.classNames(
        props.className,
        "grid grid-cols-[minmax(0,1fr),auto] gap-3 border-b pb-2 text-sm last:border-b-0"
      )}
    >
      <span className="flex flex-col">
        {!editBundle.value && (
          <ProfileFormSingleInput
            id={props.id}
            label={props.label}
            editable={false}
            htmlAttributes={{
              value: props.value,
            }}
          />
        )}

        {editBundle.value &&
          props.paths &&
          props.paths.map((el) => {
            let label: string | undefined;
            let path: keyof Handyman.Type;
            if (typeof el === "string") {
              path = el;
            } else {
              path = el.path;
              label = el.label;
            }

            if (!label) {
              label = getStaticInputLabel();
            }

            const id = "profileFormInputMap" + path;

            return (
              <ProfileFormSingleInput
                label={label}
                id={id}
                editable
                form={props.form}
                path={path}
              />
            );
          })}
      </span>

      {props.canEdit && !editBundle.value && (
        <button
          className="mt-auto mb-1 font-semibold"
          onClick={(e) => {
            e.preventDefault();
            if (props.onEdit) {
              props.onEdit();
            } else {
              editBundle.set(true);
            }
          }}
        >
          Ändra
        </button>
      )}
      {editBundle.value && (
        <button
          className="mt-auto mb-1 font-semibold"
          onClick={(e) => {
            e.preventDefault();
            editBundle.set(false);

            if (props.onSave) {
              props.onSave();
            }
          }}
        >
          Spara
        </button>
      )}
    </div>
  );
};

export default ProfileFormMergedInput;
