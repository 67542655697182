import { Handyman, SupplierInvoice } from "@eljouren/domain/build";
import ICompanyRepo from "../../../_model/repos/interfaces/ICompanyRepo";

/* Functionality will be added as we go along, when needed */
export default class WorkingCompanyRepoMock implements ICompanyRepo {
  async fetchSupplierInvoices(args: {
    from: Date;
    to: Date;
  }): Promise<SupplierInvoice.Type[]> {
    return [];
  }
  async fetchEmployees(): Promise<Handyman.Type[]> {
    return [];
  }
  setEmployeePricePermissions(args: {
    employeeId: string;
    permittedToViewPrices: boolean;
  }): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
