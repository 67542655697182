import { IpisFile } from "@eljouren/file-schemas/build";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import React, { useEffect, useRef } from "react";
import {
  FiArrowLeft,
  FiArrowRight,
  FiExternalLink,
  FiTrash,
  FiX,
} from "react-icons/fi";
import {
  useBundledState,
  useOnOutsideClick,
  usePagination,
} from "../../hooks/hooks";
import { useOnEscapeClick } from "../../hooks/keyboard-event-hooks";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import { AppLoader } from "../common/loaders/AppLoader";
import IpisFileIcon from "./IpisFileIcon";
import IpisUploadedByIcon from "./IpisUploadedByIcon";

type Image = IpisFile.WithMetaType & {
  alt: string;
};

interface Props<T extends Image> {
  className?: string;
  files?: T[];
  isError?: boolean;
  isLoading?: boolean;
  onDelete?: (image: T) => Promise<boolean>;
  disableDelete?: boolean;
}

/* 
  The GridItem component was always used inside the <ul/> component previously, which is why it gets passed props like selected: boolean and whatnot.

  Currently, when an image is selected, it is rendered outside of the <ul/> as a section, and it probably would be better and clearer if the selected image
  was a totally different component.
*/
const SelectableFileGrid = <T extends Image>(props: Props<T>) => {
  const images = props.files || [];
  const imagePage = usePagination(8, images, 0);
  const selectedImage = useBundledState<T | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);
  const previousScrollPosition = useRef<[number, number] | null>(null);

  async function onDelete(image: T | null) {
    if (!props.disableDelete && image && props.onDelete) {
      try {
        selectedImage.set(null);
        await props.onDelete(image);
      } catch (er) {
        //...
      }
    }
  }

  // Including imagePage in dependency array will cause infinite loop
  useEffect(() => {
    imagePage.updateCount(props.files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files, props.isLoading]);

  useEffect(() => {
    if (props.files) {
      props.files.slice(0, 6).forEach((images) => {
        new Image().src = images.src;
      });
    }
  }, [props.files]);

  if (props.isLoading) {
    return (
      <section className="flex flex-col gap-3 p-1">
        <AppLoader />
      </section>
    );
  }

  const showPagination =
    !!images?.length && imagePage.needsPagination && !selectedImage.value;

  const showHeader = showPagination;

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.section
        key="list"
        className={__.classNames(
          "grid h-full",
          showHeader && "grid-rows-[auto,minmax(0,1fr)]",
          !showHeader && "grid-rows-1"
        )}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{ opacity: 0 }}
      >
        {showHeader && (
          <header className="flex py-2">
            {showPagination && (
              <span className="ml-auto flex items-center gap-2">
                <button
                  onClick={() => imagePage.decrement()}
                  disabled={!imagePage.canGoLower()}
                >
                  <FiArrowLeft size={30} />
                </button>
                <span>
                  {imagePage.pageNumber} / {imagePage.maxPage}
                </span>
                <button
                  onClick={() => imagePage.increment()}
                  disabled={!imagePage.canGoHigher()}
                >
                  <FiArrowRight size={30} />
                </button>
              </span>
            )}
          </header>
        )}

        <main className="relative h-full overflow-y-auto">
          {selectedImage.value && (
            <GridItem
              showAll={() => selectedImage.set(null)}
              key={"selected"}
              as="section"
              image={selectedImage.value}
              onDelete={
                props.disableDelete || !props.onDelete
                  ? undefined
                  : () => {
                      onDelete(selectedImage.value);
                    }
              }
              onClick={() => {
                return;
              }}
              //selected={selected}
              //hasSelected={!!selectedImage.value}
              selected={true}
              hasSelected={true}
            />
          )}
          {!!images?.length && !selectedImage.value && (
            <motion.ul
              ref={listRef}
              className={__.classNames(
                "group relative grid h-full w-full grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-2 overflow-x-hidden lg:grid-cols-[repeat(auto-fill,minmax(225px,1fr))]",
                "auto-rows-[minmax(150px,1fr)]"
                //"grid-rows-[repeat(auto-fill,minmax(150px,1fr))]"
                //selectedImage.value && "overflow-hidden",
                //!selectedImage.value && "overflow-y-auto"
              )}
            >
              {imagePage.current.map((image) => {
                return (
                  <GridItem
                    key={image.src}
                    image={image}
                    onDelete={
                      props.disableDelete || !props.onDelete
                        ? undefined
                        : () => {
                            onDelete(image);
                          }
                    }
                    onClick={() => {
                      if (selectedImage.value) {
                        return;
                      }
                      if (listRef.current) {
                        previousScrollPosition.current = [
                          listRef.current.scrollLeft,
                          listRef.current.scrollTop,
                        ];
                      } else {
                        previousScrollPosition.current = null;
                      }
                      listRef.current?.scrollTo(0, 0);
                      selectedImage.set(image);
                    }}
                    //selected={selected}
                    //hasSelected={!!selectedImage.value}
                    selected={false}
                    hasSelected={false}
                  />
                );
              })}
            </motion.ul>
          )}
          {!images?.length && !props.isError && (
            <p className="p-1">Det finns inga filer att visa</p>
          )}
          {!images?.length && props.isError && (
            <p className="p-1">Något gick fel när filerna skulle hämtas</p>
          )}
        </main>
      </motion.section>
    </AnimatePresence>
  );
};

interface GridItemProps {
  image: Image;
  onClick(): void;
  onDelete?(): void;
  selected: boolean;
  hasSelected: boolean;
  as?: "li" | "section";
  showAll?(): void;
}

const GridItem = (props: GridItemProps) => {
  const isImage = props.image.meta?.mimeType?.startsWith("image");
  return (
    <GridItemWrapper
      key={props.image.src}
      className={__.classNames(
        "grid h-full w-full max-w-3xl grid-cols-1 grid-rows-[minmax(0,1fr)] overflow-hidden rounded-lg",
        props.selected && "absolute left-1/2 z-10 mx-auto -translate-x-1/2",
        !props.selected && "max-h-96 cursor-pointer",
        !isImage && "border"
      )}
      {...props}
    >
      <motion.header
        className={__.classNames(
          "z-20 col-start-1 row-start-1 mb-auto flex w-full flex-col gap-x-4 p-2 text-white lg:grid lg:grid-cols-[minmax(0,1fr),auto,auto]",
          "bg-main-bg-light/30"
        )}
      >
        <motion.span
          className={__.classNames(
            "my-auto mb-auto block w-full max-w-full items-center truncate  text-base font-bold [text-shadow:_0_2px_0_black] lg:text-lg"
          )}
        >
          {`${props.image.meta?.customName || props.image.guid}${
            isImage ? "" : props.image.ext
          }`}
        </motion.span>
        {props.selected && props.image.meta?.description && (
          <motion.p className="row-start-2 p-2 text-sm text-white">
            {props.image.meta?.description}
          </motion.p>
        )}

        {props.selected && props.image.meta?.createdDate && (
          <span className="col-span-2 col-start-2 row-start-2 mr-auto text-sm italic text-white lg:ml-auto lg:mr-0 lg:p-1">
            {new DateHelper(props.image.meta.createdDate).dateInputFormat}
          </span>
        )}
      </motion.header>
      <motion.main
        className={__.classNames(
          "col-start-1 row-start-1 grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-center",
          props.selected && !isImage && "bg-main-bg-light/80",
          !props.selected && !isImage && "bg-main-bg-light/20"
        )}
      >
        {isImage && (
          <motion.img
            className={__.classNames(
              "col-start-1 row-start-1 h-full w-full object-cover"
            )}
            src={props.image.src}
            alt={props.image.alt}
          ></motion.img>
        )}
        {!isImage && (
          <span
            className={__.classNames(
              "col-start-1 row-start-1 flex aspect-square max-h-full w-full flex-col items-center justify-center gap-4",
              props.selected && "text-white"
            )}
          >
            <IpisFileIcon size={100} mimeType={props.image.meta?.mimeType} />
          </span>
        )}
        <span className="col-start-1 row-start-1 mt-auto mr-2 flex w-full flex-row-reverse gap-2 p-2">
          {props.showAll && (
            <button
              className="flex h-14 w-14 items-center justify-center rounded-full bg-brand-light-blue/70"
              onClick={props.showAll}
            >
              <span className="sr-only">Visa alla filer</span>
              <FiX size={30} className="flex items-center text-white" />
            </button>
          )}
          {props.selected && (
            <a
              className="flex h-14 w-14 items-center justify-center rounded-full bg-brand-light-blue/70"
              target={"_blank"}
              rel="noreferrer"
              href={props.image.src}
            >
              <span className="sr-only">Öppna fil</span>
              <FiExternalLink className="text-white" size={30} />
            </a>
          )}
          {props.onDelete && props.selected && (
            <button
              className="flex h-14 w-14 items-center justify-center rounded-full bg-brand-light-blue/70"
              onClick={props.onDelete}
            >
              <span className="sr-only">Ta bort fil</span>
              <FiTrash size={30} className="flex items-center text-white" />
            </button>
          )}

          <span className="mr-auto flex h-14 w-14 items-center justify-center rounded-full bg-main-bg-light/50 text-white">
            <IpisUploadedByIcon
              size={30}
              uploaderSource={props.image.uploadedBy}
            />
          </span>
        </span>
      </motion.main>
    </GridItemWrapper>
  );
};

const GridItemWrapper = (
  props: GridItemProps & {
    children?: React.ReactNode;
    motion?: MotionProps;
    className?: string;
  }
) => {
  const ref = useRef<any>(null);

  useOnOutsideClick(ref, () => {
    if (props.showAll) {
      props.showAll();
    }
  });

  useOnEscapeClick(() => {
    if (props.showAll) {
      props.showAll();
    }
  });

  if (props.as === "section") {
    return (
      <motion.section
        ref={ref}
        id={props.selected ? "selected-image" : undefined}
        {...props.motion}
        className={__.classNames(props.className)}
      >
        {props.children}
      </motion.section>
    );
  }
  return (
    <motion.li
      ref={ref}
      id={props.selected ? "selected-image" : undefined}
      {...props.motion}
      layout
      className={__.classNames(props.className)}
      onClick={props.onClick}
      animate={{
        scale: props.selected ? 1 : 0.97,
        opacity: undefined,
      }}
      whileHover={{
        scale: 1,
        opacity: 1,
      }}
    >
      {props.children}
    </motion.li>
  );
};

export default SelectableFileGrid;
