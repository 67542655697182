import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import __ from "../../../utils/utils";

interface Props extends HTMLMotionProps<"button"> {
  className?: string;
  selected?: boolean;
}

export const ForwardedAppTextButton = React.forwardRef((props, ref) =>
  AppTextButton(props, ref)
);

const AppTextButton: React.FC<Props> = (
  props,
  ref?: React.ForwardedRef<HTMLButtonElement>
) => {
  const { className, children, selected, onClick, ...rest } = props;

  function localOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    props.onClick && props.onClick(e);
  }

  return (
    <AnimatePresence initial={false}>
      <motion.button
        {...rest}
        ref={ref}
        className={__.classNames(
          "font-semibold disabled:pointer-events-none disabled:opacity-50",
          className
        )}
        onClick={localOnClick}
        whileHover={{
          //scale: 1.05,
          color: "var(--focus-main)",
        }}
        /* whileFocus={{
          scale: 1.05,
        }} */
        whileTap={{ scale: 0.95 }}
        animate={{
          scale: 1,
          color: selected ? "var(--focus-main)" : "var(--text-primary)",
        }}
      >
        {props.children}
      </motion.button>
    </AnimatePresence>
  );
};

export default AppTextButton;
