import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { TEntries } from "../types/types";
import __ from "../utils/utils";

export function useIsPreviousPageFromCurrentSite() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const internalNavigation = searchParams.get("internalNavigation");

  // If the internalNavigation flag is set, return true
  if (internalNavigation === "true") {
    return true;
  }

  const state = location.state;
  const previousLocation = state?.previousLocation;

  // If the previousLocation is available in state, compare origins
  if (previousLocation) {
    const currentOrigin = window.location.origin;
    const previousOrigin = new URL(
      location.state.previousLocation,
      currentOrigin
    ).origin;

    // Return true if the origins match, otherwise return false
    return currentOrigin === previousOrigin;
  }

  // Return false if no previousLocation is available
  return false;
}

/* https://github.com/tannerlinsley/react-query/issues/1657 */
export function useReactQueryBugQuickFix() {
  const [toggleState, setToggleState] = useState(false);
  useEffect(() => {
    setToggleState(!toggleState);
  }, []);
}

export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useLoading() {
  const [isLoading, setLoading] = useState(false);

  async function loadWhileAsync(callback: () => Promise<void>) {
    setLoading(true);
    await callback();
    setLoading(false);
  }

  async function loadWhilePromise<T>(promise: Promise<T>): Promise<T> {
    setLoading(true);
    try {
      const res = await promise;
      setLoading(false);
      return res;
    } catch (er) {
      setLoading(false);
      throw er;
    }
  }

  return { isLoading, loadWhileAsync, loadWhilePromise };
}

/* export function useOnRouteChange(callback: () => any) {
  const location = useLocation();
  const previous = usePrevious(location);

  useEffect(() => {
    if (location.pathname !== previous?.pathname) {
      callback();
    }
  });
} */

/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
export function useOnOutsideClick(
  ref: React.MutableRefObject<any>,
  callback: () => any,
  ...exclude: (React.MutableRefObject<any> | undefined)[]
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        const inExcludedArea = exclude.some(
          (excludeRef) =>
            excludeRef?.current && excludeRef.current.contains(event.target)
        );
        if (!inExcludedArea) {
          callback();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, exclude]);
}

export type TUseBundledStateReturn<T> = {
  value: T;
  set: React.Dispatch<React.SetStateAction<T>>;
};

export function useBundledState<T>(initial: T): TUseBundledStateReturn<T> {
  const [value, setValue] = useState(initial);
  return {
    value,
    set: setValue,
  };
}

export function bundledStateForContext<T>(
  initial: T
): TUseBundledStateReturn<T> {
  return {
    value: initial,
    set: () => {},
  };
}

export type TUseNumberReturn = TUseBundledStateReturn<number> & {
  increment(by?: number): void;
  decrement(by?: number): void;
  canGoHigher(): boolean;
  canGoLower(): boolean;
  setOptions(options: TUseNumberOptions): void;
  minValue?: number;
  maxValue?: number;
  incrementCurry(
    by?: number,
    preventDefault?: boolean
  ): (e: React.MouseEvent) => void;
  decrementCurry(
    by?: number,
    preventDefault?: boolean
  ): (e: React.MouseEvent) => void;
};

type TUseNumberOptions = {
  min?: number;
  max?: number;
};

export function useNumber(
  initial: number,
  optionsArg?: TUseNumberOptions
): TUseNumberReturn {
  const [options, setOptions] = useState(optionsArg);
  const bundled = useBundledState<number>(normaliseValue(initial));

  function normaliseValue(val: number) {
    if (options?.max && val > options.max) {
      return options.max;
    }
    if (options?.min && val < options.min) {
      return options.min;
    }
    return val;
  }

  const set: Dispatch<SetStateAction<number>> = (
    val: number | ((prevState: number) => number)
  ) => {
    const num = typeof val === "number" ? val : val(bundled.value);
    bundled.set(normaliseValue(num));
  };

  function increment(by: number = 1) {
    if (canGoHigher()) {
      set(bundled.value + by);
    }
  }

  function incrementCurry(by: number = 1, preventDefault: boolean = false) {
    return (e: React.MouseEvent) => {
      if (preventDefault) {
        e.preventDefault();
      }
      increment(by);
    };
  }

  function decrement(by: number = 1) {
    if (canGoLower()) {
      set(bundled.value - by);
    }
  }
  function decrementCurry(by: number = 1, preventDefault: boolean = false) {
    return (e: React.MouseEvent) => {
      if (preventDefault) {
        e.preventDefault();
      }
      decrement(by);
    };
  }

  function canGoHigher(): boolean {
    if (options?.max === undefined) {
      return true;
    }
    return bundled.value < options.max;
  }
  function canGoLower(): boolean {
    if (options?.min === undefined) {
      return true;
    }
    return bundled.value > options.min;
  }

  return {
    value: bundled.value,
    set,
    increment,
    decrement,
    canGoHigher,
    canGoLower,
    setOptions,
    minValue: options?.min,
    maxValue: options?.max,
    incrementCurry,
    decrementCurry,
  };
}

export function usePagination<T>(
  perPage: number,
  count: T[] | number | undefined,
  initalValue?: number
) {
  const countRef = useRef(count);
  const number = useNumber(initalValue || 0, getOptions());

  function getOptions() {
    return {
      min: 0,
      max: Math.max(Math.ceil(getCount() / perPage) - 1, 0),
    };
  }

  function getCount() {
    if (!countRef.current) {
      return 0;
    } else if (typeof countRef.current === "number") {
      return countRef.current;
    } else {
      return countRef.current.length;
    }
  }

  function update(count: any[] | number | undefined) {
    countRef.current = count;
    number.setOptions(getOptions());
  }

  function getCurrent(): T[] {
    if (Array.isArray(countRef.current)) {
      const offset = perPage * number.value;
      return countRef.current.slice(offset, perPage + offset);
    }

    return [];
  }

  return {
    ...number,
    pageNumber: number.value + 1,
    maxPage: (number.maxValue || 0) + 1,
    updateCount: update,
    current: getCurrent(),
    needsPagination: getCount() > perPage,
  };
}

export type TUseNumberReturnV2 = TUseBundledStateReturn<number> & {
  increment(by?: number): void;
  decrement(by?: number): void;
  canGoHigher(): boolean;
  canGoLower(): boolean;
  setOptions(options: TUseNumberOptions): void;
  minValue?: number;
  maxValue?: number;
  incrementCurry(
    by?: number,
    preventDefault?: boolean
  ): (e: React.MouseEvent) => void;
  decrementCurry(
    by?: number,
    preventDefault?: boolean
  ): (e: React.MouseEvent) => void;
};

type TUseNumberOptionsV2 = {
  min?: number;
  max?: number;
};

export function useNumberV2(
  initial: number,
  optionsArg?: TUseNumberOptionsV2
): TUseNumberReturnV2 {
  const [options, setOptions] = useState(optionsArg);
  const bundled = useBundledState<number>(normaliseValue(initial));

  function normaliseValue(val: number) {
    if (options?.max && val > options.max) {
      return options.max;
    }
    if (options?.min && val < options.min) {
      return options.min;
    }
    return val;
  }

  const set: Dispatch<SetStateAction<number>> = (
    val: number | ((prevState: number) => number)
  ) => {
    const num = typeof val === "number" ? val : val(bundled.value);
    bundled.set(normaliseValue(num));
  };

  function increment(by: number = 1) {
    if (canGoHigher()) {
      set(bundled.value + by);
    }
  }

  function incrementCurry(by: number = 1, preventDefault: boolean = false) {
    return (e: React.MouseEvent) => {
      if (preventDefault) {
        e.preventDefault();
      }
      increment(by);
    };
  }

  function decrement(by: number = 1) {
    if (canGoLower()) {
      set(bundled.value - by);
    }
  }
  function decrementCurry(by: number = 1, preventDefault: boolean = false) {
    return (e: React.MouseEvent) => {
      if (preventDefault) {
        e.preventDefault();
      }
      decrement(by);
    };
  }

  function canGoHigher(): boolean {
    if (options?.max === undefined) {
      return true;
    }
    return bundled.value < options.max;
  }
  function canGoLower(): boolean {
    if (options?.min === undefined) {
      return true;
    }
    return bundled.value > options.min;
  }

  return {
    value: bundled.value,
    set,
    increment,
    decrement,
    canGoHigher,
    canGoLower,
    setOptions,
    minValue: options?.min,
    maxValue: options?.max,
    incrementCurry,
    decrementCurry,
  };
}

type UsePaginationPropsV2<T> = {
  perPage: number;
  count: number | "countData";
  data: T[];
  initalValue?: number;
};

export function usePaginationV2<T>(props: UsePaginationPropsV2<T>) {
  const perPageRef = useRef(props.perPage);
  const dataRef = useRef(props.data);

  const countRef = useRef(getCount(props.count));
  const page = useNumberV2(props.initalValue || 0, getOptions());

  function getCount(count: UsePaginationPropsV2<T>["count"]): number {
    return count === "countData" ? dataRef.current.length : count;
  }

  function getOptions() {
    return {
      min: 0,
      max: Math.max(Math.ceil(countRef.current / perPageRef.current) - 1, 0),
    };
  }

  function update({
    data,
    count,
    perPage,
  }: Omit<Partial<UsePaginationPropsV2<T>>, "initialValue">) {
    if (data !== undefined) {
      dataRef.current = data;
      if (count === undefined && props.count === "countData") {
        countRef.current = getCount("countData");
      }
    }

    if (count !== undefined) {
      countRef.current = getCount(count);
    }

    if (perPage !== undefined) {
      perPageRef.current = perPage;
    }

    page.setOptions(getOptions());
  }

  function getCurrentData(): T[] {
    const offset = perPageRef.current * page.value;
    return dataRef.current.slice(offset, perPageRef.current + offset);
  }

  return {
    ...page,
    pageNumber: page.value + 1,
    maxPage: (page.maxValue || 0) + 1,
    update: update,
    currentData: getCurrentData(),
    needsPagination: countRef.current > perPageRef.current,
    perPage: perPageRef,
  };
}

export type TUseImmutableReturn<T extends object> = UseImmutableHelper<T>;

export class UseImmutableHelper<T extends object> {
  private _values?: T[keyof T][];
  private _entries?: TEntries<T>;
  private _keys?: string[];

  constructor(
    readonly dict: T,
    private onModify: (newObj: UseImmutableHelper<T>) => void
  ) {}

  public modify(callback: (obj: T) => void): T {
    const copy = { ...this.dict };
    callback(copy);
    this.onModify(new UseImmutableHelper(copy, this.onModify));
    return copy;
  }

  public override(obj: T) {
    this.onModify(new UseImmutableHelper(obj, this.onModify));
  }

  get values() {
    if (!this._values) {
      this._values = Object.values(this.dict);
    }

    return this._values;
  }

  get entries() {
    if (!this._entries) {
      this._entries = __.entries(this.dict);
    }

    return this._entries;
  }

  get keys() {
    if (!this._keys) {
      this._keys = Object.keys(this.dict);
    }

    return this._keys;
  }
}

export function useImmutable<T extends object>(obj: T): TUseImmutableReturn<T> {
  const [state, setState] = useState(
    new UseImmutableHelper(obj, (helper) => {
      setState(helper);
    })
  );
  return state;
}

export function ImmutableStateForContext<T extends object>(obj: T) {
  return new UseImmutableHelper(obj, () => {});
}

export function useSearchParamState(
  key: string,
  args: {
    defaultValue: string | null;
  }
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  //const [searchParams, setSearchParams] = useSearchParams();
  //const [searchParams] = useSearchParams();

  //@ts-ignore
  const url = new URL(window.location);

  const currentValue = url.searchParams.get(key);

  const initialValue = currentValue === null ? args.defaultValue : currentValue;
  const [value, setStateValue] = useState(initialValue);
  const previous = usePrevious(value);

  function setValue(
    value: (string | null) | ((prevValue: string | null) => string | null)
  ) {
    const result = value instanceof Function ? value(initialValue) : value;
    //@ts-ignore
    const url = new URL(window.location);
    if (result === "null" || result === null || result === undefined) {
      url.searchParams.delete(key);
      setStateValue(null);
      //searchParams.delete(key);
      //setSearchParams(Object.fromEntries(searchParams.entries()));
    } else {
      url.searchParams.set(key, result);
      setStateValue(result);
      //setSearchParams({
      //  ...Object.fromEntries(searchParams.entries()),
      //  [key]: result,
      //});
    }
    //window.location.href = url.toString();
    window.history.replaceState({}, "", url);
  }

  return [value, setValue];
}

export function useBundledSearchParamState(key: string, defaultValue: string) {
  const [value, set] = useSearchParamState(key, { defaultValue });
  return { value, set };
}

export function useCustomSearchParamState<T>(
  key: string,
  defaultValue: T | null,
  convert: {
    fromString: (str: string | null) => T;
    toString: (obj: T) => string | null;
  }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [stringValue, setStringValue] = useSearchParamState(key, {
    defaultValue: defaultValue === null ? null : convert.toString(defaultValue),
  });

  const currentValue = convert.fromString(stringValue);

  function setValue(value: T | ((prevValue: T) => T)) {
    const result = value instanceof Function ? value(currentValue) : value;
    setStringValue(convert.toString(result));
  }

  return [currentValue, setValue];
}

export function useBundledCustomSearchParamState<T>(
  key: string,
  defaultValue: T | null,
  convert: {
    fromString: (str: string | null) => T;
    toString: (obj: T) => string | null;
  }
): {
  value: T;
  set: React.Dispatch<React.SetStateAction<T>>;
} {
  const [value, set] = useCustomSearchParamState(key, defaultValue, convert);
  return { value, set };
}

export function useDetectKeyboardOpen(
  minKeyboardHeight: number = 300,
  defaultValue: boolean = false
): boolean {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);

  useEffect(() => {
    const listener = () => {
      const newState =
        window.screen.height - minKeyboardHeight >
        (window.visualViewport?.height || 0);
      setIsKeyboardOpen(newState);
    };
    window.visualViewport?.addEventListener("resize", listener);
    return () => {
      window.visualViewport?.removeEventListener("resize", listener);
    };
  }, [minKeyboardHeight]);

  return isKeyboardOpen;
}
