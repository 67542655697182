"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderLineItem = void 0;
var zod_1 = require("zod");
var WorkOrderLineItem;
(function (WorkOrderLineItem) {
    var BaseFields = zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
        unitPrice: zod_1.z.number().optional(),
        quantity: zod_1.z.number(),
        createdDate: zod_1.z.date(),
        addedByHandyman: zod_1.z.boolean(),
        unit: zod_1.z.string(),
    });
    WorkOrderLineItem.Schema = zod_1.z.discriminatedUnion("isCustom", [
        BaseFields.extend({
            isCustom: zod_1.z.literal(false),
            productId: zod_1.z.string(),
        }),
        BaseFields.extend({
            isCustom: zod_1.z.literal(true),
            productId: zod_1.z.literal(undefined).optional(),
        }),
    ]);
    WorkOrderLineItem.NewEntrySchema = zod_1.z.union([
        zod_1.z.object({
            productId: zod_1.z.string(),
            quantity: zod_1.z.number(),
            workOrderId: zod_1.z.string(),
            subject: zod_1.z.literal(undefined).optional(),
            unitPrice: zod_1.z.literal(undefined).optional(),
        }),
        zod_1.z.object({
            productId: zod_1.z.literal(undefined).optional(),
            subject: zod_1.z.string().min(1),
            unitPrice: zod_1.z.number(),
            quantity: zod_1.z.number(),
            workOrderId: zod_1.z.string(),
        }),
    ]);
})(WorkOrderLineItem || (WorkOrderLineItem = {}));
exports.WorkOrderLineItem = WorkOrderLineItem;
