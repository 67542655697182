import { isValid, parseJSON } from "date-fns";
import { useState } from "react";
import { TUseBundledStateReturn } from "./hooks";

type TFallback<T> = T | (() => T);
type UseLsOptions<T> = {
  decode?: (str: string) => T;
  encode?: (obj: T) => string;
};

/*
Localstorage
*/
export function useLsState<T>(
  key: string,
  fallback: TFallback<T>,
  options: UseLsOptions<T> = {}
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialValue());

  function initialValue(): T {
    const saved = localStorage.getItem(key);
    if (saved !== null) {
      if (options?.decode) {
        return options.decode(saved);
      } else {
        return saved as any;
      }
    }
    if (fallback instanceof Function) {
      return fallback();
    } else {
      return fallback;
    }
  }

  const save: React.Dispatch<React.SetStateAction<T>> = (
    value: T | ((prevValue: T) => T)
  ) => {
    setState(value);
    const val = value instanceof Function ? value(state) : value;
    if (options?.encode) {
      localStorage.setItem(key, options.encode(val));
    } else {
      localStorage.setItem(key, JSON.stringify(val));
    }
  };

  return [state, save];
}
export function useBundledLs<T>(
  key: string,
  fallback: T | (() => T),
  options: UseLsOptions<T> = {}
): TUseBundledStateReturn<T> {
  const [value, set] = useLsState(key, fallback, options);
  return {
    value,
    set,
  };
}

export function useBundledLsDate<T extends Date | null>(
  key: string,
  fallback: T | (() => T)
): TUseBundledStateReturn<T> {
  const bundle = useBundledLs<T>(key, fallback, {
    decode: (str) => {
      const date = parseJSON(str);
      if (isValid(date)) {
        return date as T;
      } else if (fallback instanceof Function) {
        return fallback();
      } else {
        return fallback;
      }
    },
  });

  return bundle;
}
