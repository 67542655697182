import { Handyman, HandymanWorkingHours } from "@eljouren/domain/build";
import IHandymanRepo from "../../../_model/repos/interfaces/IHandymanRepo";

export default class WorkingHandymanRepoMock implements IHandymanRepo {
  fetchHandyman(id: string): Promise<{
    id: string;
    phone: string;
    email: string;
    city: string;
    street: string;
    country: string;
    postalCode: string;
    companyId: string;
    firstName: string;
    lastName: string;
    userType: Handyman.UserType.worker;
    companyName: string;
    companyRole: "handyman" | "supervisor";
    profession: "electrician" | "plumber" | "plumber-spolbil";
    activeAreas: string[];
    qualifications: string[];
    permittedToViewPrices: boolean;
  }> {
    throw new Error("Method not implemented.");
  }
  fetchByProfession(
    profession: "electrician" | "plumber" | "plumber-spolbil"
  ): Promise<
    {
      id: string;
      phone: string;
      email: string;
      city: string;
      street: string;
      country: string;
      postalCode: string;
      companyId: string;
      firstName: string;
      lastName: string;
      userType: Handyman.UserType.worker;
      companyName: string;
      companyRole: "handyman" | "supervisor";
      profession: "electrician" | "plumber" | "plumber-spolbil";
      activeAreas: string[];
      qualifications: string[];
      permittedToViewPrices: boolean;
    }[]
  > {
    throw new Error("Method not implemented.");
  }
  updateProfileInfo(worker: {
    id: string;
    phone: string;
    email: string;
    city: string;
    street: string;
    country: string;
    postalCode: string;
    companyId: string;
    firstName: string;
    lastName: string;
    userType: Handyman.UserType.worker;
    companyName: string;
    companyRole: "handyman" | "supervisor";
    profession: "electrician" | "plumber" | "plumber-spolbil";
    activeAreas: string[];
    qualifications: string[];
    permittedToViewPrices: boolean;
  }): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  fetchProvincesWithCounties(): Promise<
    { name: string; counties: string[] }[]
  > {
    throw new Error("Method not implemented.");
  }
  fetchWorkingHours(args: {
    handymanId: string;
    interval: { start: Date; end: Date };
  }): Promise<HandymanWorkingHours.DictIndexedByStartOfDayType> {
    throw new Error("Method not implemented.");
  }
  saveWorkingHours(args: {
    handymanId: string;
    workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType;
  }): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
