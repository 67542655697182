import { motion } from "framer-motion";
import React from "react";
import { usePrevious } from "../../../hooks/hooks";
import __ from "../../../utils/utils";

interface Props {
  className?: string;
  atIndex: number;
  onActiveClick(index: number): void;
  steps: string[];
}

const FormBreadcrumb = (props: Props) => {
  return (
    <ol
      className={__.classNames("mx-auto flex rounded-lg", props.className)}
      data-test-id="form-breadcrumb"
    >
      {props.steps.map((step, index) => (
        <Item
          key={step}
          itemIndex={index}
          atIndex={props.atIndex}
          onActiveClick={props.onActiveClick}
        >
          {step}
        </Item>
      ))}
    </ol>
  );
};

const Item = (props: {
  children?: React.ReactNode;
  itemIndex: number;
  atIndex: number;
  onActiveClick(index: number): void;
}) => {
  const isHighlighted = props.itemIndex <= props.atIndex;
  const previousAtIndex = usePrevious(props.atIndex);
  const isActiveStep = props.atIndex === props.itemIndex;
  const isPreviousStep = isHighlighted && !isActiveStep;

  const duration = 0.2;
  const delay = previousAtIndex
    ? Math.max(0, previousAtIndex - props.itemIndex) * (duration * 0.5)
    : 0;

  return (
    <motion.li
      onClick={() => {
        if (isPreviousStep) {
          props.onActiveClick(props.itemIndex);
        }
      }}
      className={__.classNames(
        "border-2 border-r-0 border-main-bg-light p-1 px-2 text-sm first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg last:border-r-2 md:px-4",
        isPreviousStep && "cursor-pointer"
      )}
      style={{
        backgroundSize: "200% 100%",
        backgroundImage:
          "linear-gradient(to right, #ffffffaa 50%, var(--palette-green-light) 50%)",
      }}
      animate={{
        backgroundPosition: isHighlighted ? "-100% 0" : "0.001% 0",
        color: isHighlighted ? "#ffffff" : "#000000",
        transition: {
          type: "tween",
          //@ts-ignore
          color: {
            delay: 0.2,
            duration: 0.5,
          },
          duration,
          delay,
        },
      }}
    >
      {isActiveStep && <span className="sr-only">Nuvarande steg:</span>}
      {isPreviousStep && <span className="sr-only">Avklarat steg:</span>}
      <span>{props.children}</span>
    </motion.li>
  );
};

export default FormBreadcrumb;
