import React, { useContext } from "react";
import { GlobalContext } from "../../../top-level-contexts";
import { useNavigate } from "react-router-dom";

/*
	Placeholder
*/
const withAdminPermissions =
  (WrappedComponent: () => React.ReactElement) => () => {
    const { signInState } = useContext(GlobalContext);

    const isAdmin = signInState.isSignedIn && signInState.isAdmin;

    const navigate = useNavigate();

    /* useEffect(() => {
      if (!isAdmin) {
        navigate("/");
      }
    }, [isAdmin, navigate]); */

    if (!isAdmin) {
      return <></>;
    }

    return (
      <>
        <WrappedComponent />
      </>
    );
  };

export default withAdminPermissions;
