"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderConfirmation = void 0;
var zod_1 = require("zod");
var WorkOrder_1 = require("./WorkOrder");
var WorkOrderHousingType_1 = require("./WorkOrderHousingType");
var WorkOrderInvoice_1 = require("./WorkOrderInvoice");
/*
This is a mess and needs comments, but at the time of writing I don't fully understand it myself
*/
var WorkOrderConfirmation;
(function (WorkOrderConfirmation) {
    var PaymentInfoCompanySchema = zod_1.z.object({
        customerIsCompany: zod_1.z.literal(true),
        invoice: WorkOrderInvoice_1.WorkOrderInvoice.Schema,
        housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema.optional(),
    });
    var PaymentInfoPrivateSchema = zod_1.z.object({
        customerIsCompany: zod_1.z.literal(false),
        invoice: WorkOrderInvoice_1.WorkOrderInvoice.Schema,
        housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema,
    });
    WorkOrderConfirmation.PaymentInfoSchema = zod_1.z.discriminatedUnion("customerIsCompany", [
        PaymentInfoCompanySchema,
        PaymentInfoPrivateSchema,
    ]);
    WorkOrderConfirmation.BaseFieldsSchema = WorkOrder_1.WorkOrder.CustomerSchema.pick({
        description: true,
        additionalInformation: true,
        location: true,
        contact: true,
    });
    /*
    Schema for the type defined in react-images-uploading
    */
    //export const ImageSchema = z.object({
    //  dataURL: z.string(),
    //  // Reference error, File is not defined
    //  //file: z.instanceof(File),
    //  file: z.any(),
    //});
    //
    //export type ImageType = z.infer<typeof ImageSchema>;
    WorkOrderConfirmation.BaseFieldsWithGdpr = WorkOrderConfirmation.BaseFieldsSchema.merge(zod_1.z.object({
        /*  images: ImageSchema.partial()
          .array()
          .transform<ImageType[]>((values) =>
            values.filter(
              (el): el is ImageType => ImageSchema.safeParse(el).success
            )
          ), */
        gdprConfirmed: zod_1.z.literal(true),
    }));
    WorkOrderConfirmation.Schema = zod_1.z.union([
        WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoCompanySchema),
        WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoPrivateSchema),
    ]);
    /*
    The fields of an Account which are shown in the order confirmation form
  
    Is this used..?
    */
    /*   export const OrderConfirmationAccountFieldsSchema =
      WorkOrder.CustomerSchema.pick({
        contact: true,
        customerIsCompany: true,
      }); */
})(WorkOrderConfirmation || (WorkOrderConfirmation = {}));
exports.WorkOrderConfirmation = WorkOrderConfirmation;
