import { HandymanWorkingHours } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { parse } from "date-fns";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import { AppButton } from "../common/buttons/AppButton";
import ControlledTimePicker from "../common/time/ControlledTimePicker";
import HandymanContext from "../handyman-context/HandymanContext";

const FormSchema = z
  .object({
    start: z.date(),
    end: z.date(),
  })
  .refine(
    (val) => {
      return Number(val.end) > Number(val.start);
    },
    {
      message: "Sluttid måste vara efter starttid",
      path: ["end"],
    }
  );

type TFormSchema = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  dates: Record<string, true>;
  onFormSubmitted?(): void;
}

const WorkingHoursForm = (props: Props) => {
  const ctx = useContext(HandymanContext);
  const { handleSubmit, getValues, control, formState } = useForm<TFormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(),
  });

  function getDefaultValues() {
    const data = ctx.workingHoursRes.data;
    if (!data) {
      return undefined;
    }
    const dates = Object.keys(props.dates);
    let firstNonOffDutyWorkingHour: { start: Date; end: Date } | undefined;
    dates.some((key) => {
      const saved = data[key];
      if (saved && saved.status !== "offWork") {
        firstNonOffDutyWorkingHour = saved;
        return true;
      }
      return false;
    });

    return firstNonOffDutyWorkingHour;
  }

  async function onSubmit() {
    const values = getValues();
    const startHelper = new DateHelper(values.start);
    const endHelper = new DateHelper(values.end);
    const workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType =
      __.objectMap(props.dates, (value, key) => {
        const helper = new DateHelper(parse(key, "T", new Date()));

        const modifedStartHelper = helper
          .setHours(startHelper.hours)
          .setMinutes(startHelper.minutes);
        const end = helper
          .setHours(endHelper.hours)
          .setMinutes(endHelper.minutes).date;

        return {
          status: "reported",
          startOfDay: modifedStartHelper.startOfDay.date,
          start: modifedStartHelper.date,
          end,
        };
      });
    report(workingHours);
  }

  async function onOffDutyClick() {
    const workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType =
      __.objectMap(props.dates, (value, key) => {
        /*
        I'm temporarily adding a few hours here to potentially fix a timezone issue when reporting working hours
        */

        const start = new DateHelper(
          parse(key, "T", new Date())
        ).startOfDay.add("hours", 5);
        const end = start.endOfDay;
        return {
          status: "offWork",
          start: start.date,
          end: end.date,
        };
      });
    report(workingHours);
  }

  function report(
    workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType
  ) {
    ctx.saveWorkingHours.mutateAsync(workingHours).catch((er) => {
      window.modal.alert({
        title: "Det gick inte att rapportera arbetstider just nu",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    });

    props.onFormSubmitted && props.onFormSubmitted();
  }

  const disabled = !Object.values(props.dates).length || ctx.isLoading;
  const ordersPlanned = Object.keys(props.dates).some(
    (timestamp) => !!ctx.merger.get(timestamp).workOrders.length
  );

  return (
    <form
      className={__.classNames(
        "mx-auto flex w-full max-w-[300px] flex-col items-center gap-4 p-2",
        props.className
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="flex w-full max-w-[225px] flex-col gap-6">
        <label className="mx-auto text-xl font-semibold">Jag jobbar</label>
        <span className="flex flex-col border-2 border-transparent focus-within:border-orange-200 md:items-center lg:grid lg:grid-cols-2">
          <label className="text-lg" htmlFor="workingHoursStart">
            från
          </label>
          <ControlledTimePicker
            control={control}
            name="start"
            className="mr-auto p-2"
            htmlAttributes={{
              id: "workingHoursStart",
            }}
          />
        </span>
        <span className="flex flex-col border-2 border-transparent focus-within:border-orange-200 md:items-center lg:grid lg:grid-cols-2">
          <label className="text-lg" htmlFor="workingHoursEnd">
            till
          </label>
          <ControlledTimePicker
            control={control}
            name="end"
            className="mr-auto p-2"
            htmlAttributes={{
              id: "workingHoursEnd",
            }}
          />
        </span>
        {formState.errors.end && formState.errors.end.type === "custom" && (
          <span className="text-red-600">{formState.errors.end.message}</span>
        )}
      </p>

      <AppButton className="mt-8 w-full grow" disabled={disabled}>
        Rapportera tider
      </AppButton>
      <AppButton
        className="w-full"
        buttonMode="outline"
        onClick={onOffDutyClick}
        disabled={disabled || ordersPlanned}
      >
        Jag är otillgänglig
      </AppButton>
      {ordersPlanned && (
        <span className="text-sm">
          Minst ett av dina valda datum har en arbetsorder inplanerad och det är
          därför ej möjligt att rapportera in otillgänglighet.
        </span>
      )}
    </form>
  );
};

export default WorkingHoursForm;
