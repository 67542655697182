import { API } from "../api/API";
import SearchParams from "../api/SearchParams";
import { Observable } from "../observables/Observable";
import { WorkOrder } from "@eljouren/domain";
import { TWorkerSignInData } from "../schemas-and-types/repo-schemas";
import IAuthRepo, { TSignInState, TSignUpIdType } from "./interfaces/IAuthRepo";
import IHandymanRepo from "./interfaces/IHandymanRepo";
import FetchResponseFailureError from "../../utils/errors/FetchResponseFailureError";

export class AuthRepo implements IAuthRepo {
  private readonly _signedInState = new Observable<TSignInState>({
    isLoading: false,
    isSignedIn: false,
  });

  constructor(private workerRepo: IHandymanRepo) {}

  get signedInState() {
    return this._signedInState.privateObs;
  }

  async notifyCustomerOrderFetched(order: WorkOrder.Type) {
    const state = { ...this.signedInState.value };

    if (state.signedInAs === "customer" || state.signedInAs === "sales") {
      state.brand = order.brand;
      this._signedInState.set(state);
    }
  }

  async customerAuthentication(orderId: string): Promise<200 | 404> {
    const base = API.base();
    const url = `${base}/auth`;
    try {
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workorderId: orderId,
          guidOrigin: "customer",
        }),
      });

      const json = await res.json();
      if (res.status === 200) {
        API.onCustomerSignIn(json.jwtToken);

        /*
        Should probably return the brand from the server so we dont need to set the brand to ipis
        */
        this._signedInState.set({
          isSignedIn: true,
          signedInAs: "customer",
          isLoading: false,
          brand: "ipis",
        });

        return 200;
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });

        if (res.status === 404) {
          return 404;
        }
        throw new FetchResponseFailureError({
          response: res,
          json,
          context: "AuthRepo.signInWithCredentials",
        });
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw er;
    }
  }
  async signUpIdAuthentication(
    signUpId: string,
    type: TSignUpIdType
  ): Promise<200 | 404> {
    const params = new SearchParams({
      signupId: signUpId,
    });
    const url = API.endpoint("signUpIdValid", params);

    try {
      const res = await fetch(url, {
        credentials: "include",
      });

      const json = await res.json();
      if (res.status === 200) {
        if (json.type !== type) {
          // Misleading
          return 404;
        }

        return 200;
      } else {
        if (res.status === 404) {
          return 404;
        }

        throw new FetchResponseFailureError({
          response: res,
          json,
          context: "AuthRepo.signInWithCredentials",
        });
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw er;
    }
  }
  // Very similar to customerAuthentication, probably worth to merge
  async salesAuthentication(orderId: string): Promise<200 | 404> {
    const base = API.base();
    const url = `${base}/auth`;

    try {
      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workorderId: orderId,
          guidOrigin: "sales",
        }),
      });

      const json = await res.json();
      if (res.status === 200) {
        API.onCustomerSignIn(json.jwtToken);

        this._signedInState.set({
          isSignedIn: true,
          signedInAs: "sales",
          isLoading: false,
          brand: "ipis",
        });

        return 200;
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });

        if (res.status === 404) {
          return 404;
        }
        throw new FetchResponseFailureError({
          response: res,
          json,
          context: "AuthRepo.signInWithCredentials",
        });
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw er;
    }
  }

  async staffAuthentication(staffGuid: string): Promise<200 | 404> {
    const base = API.base();
    const url = `${base}/auth`;

    try {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: true,
      });

      const res = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          ...API.getOptionalAuthHeader(),
        },
        body: JSON.stringify({
          staffWebAppGuid: staffGuid,
        }),
      });

      const json = await res.json();
      if (res.status === 200) {
        API.onStaffSignIn({ staffGuid });

        const firstName = json.firstName;
        const lastName = json.lastName;
        const isAdmin = json.isAdmin;
        this._signedInState.set({
          isSignedIn: true,
          signedInAs: "staff",
          isLoading: false,
          brand: "ipis",
          firstName,
          lastName,
          isAdmin,
        });

        return 200;
      } else {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });

        if (res.status === 404) {
          return 404;
        }

        throw new FetchResponseFailureError({
          response: res,
          json,
          context: "AuthRepo.signInWithCredentials",
        });
      }
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw er;
    }
  }

  async signInWithToken(): Promise<unknown> {
    try {
      const creds = API.getCredentials();
      if (!creds || creds.authenticatedAs === "customer") {
        this._signedInState.set({
          isSignedIn: false,
          isLoading: false,
        });
        return null;
      }

      switch (creds.authenticatedAs) {
        case "handyman":
          return this.buildHandymanSignInInfo();
        case "staff":
          return this.staffAuthentication(creds.staffGuid);
      }
    } catch (ex) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });

      return null;
    }
  }

  async signInWithCredentials(
    usernameOrEmail: string,
    password: string
  ): Promise<TWorkerSignInData | null> {
    const base = API.base();
    const url = `${base}/auth`;

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        email: usernameOrEmail,
        password,
      }),
    });
    const json = await res.json();
    if (res.status === 200) {
      API.onHandymanSignIn(json.jwtToken);
      return this.buildHandymanSignInInfo();
    } else {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      if (res.status === 401) {
        return null;
      }
      throw new FetchResponseFailureError({
        response: res,
        json,
        context: "AuthRepo.signInWithCredentials",
      });
    }
  }

  private async buildHandymanSignInInfo(): Promise<TWorkerSignInData> {
    try {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: true,
      });

      const userId = API.getUserId();
      const handyman = await this.workerRepo.fetchHandyman(userId);

      this._signedInState.set({
        isSignedIn: true,
        signedInAs: "worker",
        handyman: handyman,
        isLoading: false,
      });

      return { handyman };
    } catch (er) {
      this._signedInState.set({
        isSignedIn: false,
        isLoading: false,
      });
      throw er;
    }
  }

  async signOut() {
    this._signedInState.set({
      isSignedIn: false,
      isLoading: false,
    });

    API.removeCredentials();

    const endpoint = API.endpoint("signOut");
    await fetch(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  }

  async choosePassword(args: {
    signUpId: string;
    password: string;
    type: TSignUpIdType;
  }): Promise<boolean> {
    const endpoint = API.endpoint("signup");

    const res = await fetch(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        signupId: args.signUpId,
        password: args.password,
        reset: args.type === "reset" ? "true" : "false",
      }),
    });

    if (res.status === 200) {
      return true;
    }
    const json = await res.json();
    throw new FetchResponseFailureError({
      response: res,
      json,
      context: "AuthRepo.signInWithCredentials",
    });
  }

  async invokeResetPasswordFlow(args: { email: string }): Promise<void> {
    const endpoint = API.endpoint("resetPassword");
    const res = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: args.email }),
    });

    if (res.status !== 200) {
      const json = await res.json();
      throw new FetchResponseFailureError({
        response: res,
        json,
        context: "AuthRepo.signInWithCredentials",
      });
    }
  }
}
