const { fetch: origFetch } = window;

namespace FetchInterceptor {
  let dateForLastHighLoadToast: Date | undefined;
  export async function func(
    ...args: Parameters<typeof origFetch>
  ): ReturnType<typeof origFetch> {
    const response = await origFetch(...args);

    if (response.status === 503) {
      if (dateForLastHighLoadToast) {
        const now = new Date();
        const duration = Number(now) - Number(dateForLastHighLoadToast);
        const fiveMinutesInMs = 1000 * 60 * 5;
        if (duration < fiveMinutesInMs) {
          // Return early
          return response;
        }
      }

      dateForLastHighLoadToast = new Date();
      window.modal.toast({
        id: "high-load-toast",
        title: "Hög belastning",
        prompt:
          "Det är ovanligt hög belastning just nu och vissa saker kan därför tillfälligt sluta fungera.",
        toastType: "error",
        timeVisibleInMs: 5000,
      });
    }

    return response;
  }
}

export default FetchInterceptor;
