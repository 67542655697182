import { motion } from "framer-motion";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import __ from "../../utils/utils";
import SignInForm from "../forms/SignInForm";
import AppPage from "../layout/AppPage";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../top-level-contexts";
import { useRepos } from "../../hooks/use-repos";

interface Props {
  className?: string;
}

const SignInSection = (props: Props) => {
  const isLg = useMediaQuery(TailwindBreakpoint.lg);

  const { signInState } = useContext(GlobalContext);
  const { authRepo } = useRepos();

  useEffect(() => {
    if (signInState.isSignedIn && signInState.signedInAs !== "worker") {
      authRepo.signOut();
    }
  }, [signInState, authRepo]);

  return (
    <AppPage className="flex h-full w-full flex-col">
      <section
        className={__.classNames(
          "flex h-full grid-rows-1 flex-col items-center justify-center bg-gradient-to-r from-main-bg-light via-main-bg-dark to-main-bg-dark lg:grid lg:grid-cols-2",
          props.className
        )}
      >
        {isLg && (
          <header className="flex h-full w-full flex-col items-center justify-center pb-[35%] text-white">
            <img
              alt="Bild på Aiis installations-plattform"
              src="/aii-devices/aii-devices.png"
            />
            <motion.h1
              className="text-2xl xl:text-3xl"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 1,
                delay: 0.2,
              }}
            >
              Din nya plattform för allt inom installation
            </motion.h1>
            {/* <motion.h2
            className="text-lg text-gray-400 lg:text-xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 1.2,
            }}
          >
            Lorem ipsum...
          </motion.h2> */}
          </header>
        )}
        <main className="flex h-full max-h-full flex-col items-center justify-center lg:py-12 lg:px-8 xl:p-16">
          <SignInForm />
        </main>
      </section>
    </AppPage>
  );
};

export default SignInSection;
