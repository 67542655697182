import { motion } from "framer-motion";
import React from "react";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import __ from "../../utils/utils";
import HistoryBackButton from "../common/navigation/HistoryBackButton";
import { useIsPreviousPageFromCurrentSite } from "../../hooks/hooks";

export type PageHeaderProps = {
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  noBackButton?: boolean;
  hideOnKeyboardOpen?: boolean;
  overrideLink?: string;
} & (
  | {
      heading: string;
      subheading?: string;
      RenderHeadings?: undefined;
    }
  | {
      heading?: undefined;
      subheading?: undefined;
      RenderHeadings: (props: {
        headingClassName: string;
        subheadingClassName: string;
      }) => React.ReactElement;
    }
);

const PageHeader = (props: PageHeaderProps) => {
  const isLg = useMediaQuery(TailwindBreakpoint.lg);
  const base = isLg ? "#ffffff" : "#103236";
  const from = isLg ? "#314a4e" : "#72cbd6";
  const to = base;

  const previousPageIsTheSame = useIsPreviousPageFromCurrentSite();
  const { RenderHeadings } = props;
  const loading = props.loading;

  let key: string;
  if (loading) {
    if (isLg) {
      key = "loadingPageHeaderLarge";
    } else {
      key = "loadingPageHeaderSmall";
    }
  } else {
    if (isLg) {
      key = "staticPageHeaderLarge";
    } else {
      key = "staticPageHeaderSmall";
    }
  }

  const headingClassName = "text-base leading-5 md:text-xl lg:text-2xl";
  const subheadingClassName = "text-base leading-4";

  const showBackButton = !props.noBackButton && previousPageIsTheSame;

  return (
    <motion.header
      key={key}
      className={__.classNames(
        "min-h-[65px] items-center gap-y-1 gap-x-2 bg-gradient-to-br from-main-bg-light to-main-bg-dark p-1 py-2 text-white lg:from-white lg:to-white lg:text-black",
        "grid grid-cols-[auto,minmax(0,1fr),70px] lg:grid-cols-[auto,minmax(0,1fr),auto]",
        !showBackButton && "pl-5",
        props.className
      )}
      initial={
        loading
          ? {
              background: `linear-gradient(to bottom, transparent 4px, ${base} 4px),linear-gradient(to right, ${from} 0%, ${to} 1%)`,
            }
          : undefined
      }
      animate={
        loading
          ? {
              background: `linear-gradient(to bottom, transparent 4px, ${base} 4px), linear-gradient(to right, ${from} 100%, ${to} 100%)`,
            }
          : undefined
      }
      transition={
        loading
          ? {
              repeat: Infinity,
              repeatDelay: 0.8,
              duration: 0.8,
            }
          : undefined
      }
    >
      {showBackButton && (
        <span className="flex w-12">
          <HistoryBackButton overrideLink={props.overrideLink} />
        </span>
      )}
      {RenderHeadings && (
        <RenderHeadings
          headingClassName={headingClassName}
          subheadingClassName={subheadingClassName}
        />
      )}
      {!RenderHeadings && (
        <span className="flex flex-col gap-1">
          <>
            <h1 className={headingClassName}>{props.heading}</h1>
            {props.subheading && (
              <h2 className={subheadingClassName}>{props.subheading}</h2>
            )}
          </>
        </span>
      )}
      {props.children}
    </motion.header>
  );
};

export default PageHeader;
