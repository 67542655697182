import { MotionConfig } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { z } from "zod";
import AppRoutesComponent from "./AppRoutesComponent";
import { RepoContext, TRepoContextValue } from "./top-level-contexts";
import { AuthRepo } from "./_model/repos/AuthRepo";
import CompanyRepo from "./_model/repos/CompanyRepo";
import ErrorLogRepo from "./_model/repos/ErrorLogRepo";
import HandymanRepo from "./_model/repos/HandymanRepo";
import { WorkOrderRepo } from "./_model/repos/WorkOrderRepo";
import SimulatedRepoContextValue from "./__mocks__/repos/simulated-repo-context-value";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === "string") {
      if (issue.minimum === 1) {
        return { message: `Minst ${issue.minimum} karaktär!` };
      } else {
        return { message: `Minst ${issue.minimum} karaktärer!` };
      }
    }
  }

  if (issue.code === z.ZodIssueCode.too_big) {
    if (issue.type === "string") {
      if (issue.maximum === 1) {
        return { message: `Max ${issue.maximum} karaktär!` };
      } else {
        return { message: `Max ${issue.maximum} karaktärer!` };
      }
    }
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

const useSimulation = false;
const dev = process.env.NODE_ENV === "development";
let repoContextValue: TRepoContextValue;
/* ToDo: Conditionally import the simulated repo context */
if (useSimulation && dev) {
  repoContextValue = SimulatedRepoContextValue;
  repoContextValue.authRepo.signInWithToken();
} else {
  const workerRepo = new HandymanRepo();
  const authRepo = new AuthRepo(workerRepo);
  repoContextValue = {
    authRepo,
    handymanRepo: workerRepo,
    companyRepo: new CompanyRepo(),
    workOrderRepo: new WorkOrderRepo(),
    errorLogRepo: new ErrorLogRepo(),
  };
  repoContextValue.authRepo.signInWithToken();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//const baseName = process.env.NODE_ENV === "development" ? "/" : "/eljourenapp";

root.render(
  <React.StrictMode>
    <MotionConfig reducedMotion="user">
      <RepoContext.Provider value={repoContextValue}>
        <BrowserRouter basename={"/"}>
          <AppRoutesComponent />
        </BrowserRouter>
      </RepoContext.Provider>
    </MotionConfig>
  </React.StrictMode>
);
