import { useRef } from "react";
import __ from "../../../utils/utils";
import { UUID } from "../../../utils/UUID";

interface Props {
  label?: string;
  errorMessage?: string;
  className?: string;
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
}
export function AppTextField(props: Props) {
  const id = useRef(UUID.generate().value).current;

  return (
    <p className={__.classNames("flex w-full flex-col gap-2", props.className)}>
      {props.label && (
        <label htmlFor={id} className="text-lg">
          {props.label}
        </label>
      )}
      <input
        className={__.classNames(
          "disabled:opacity-1 opacity-1 flex h-8 w-full rounded-sm border fill-black p-1 placeholder:text-sm placeholder:italic placeholder:text-black",
          props.htmlAttributes?.readOnly
            ? "border-form-border-disabled"
            : "border-form-border"
        )}
        id={id}
        {...props.htmlAttributes}
      ></input>
      {props.errorMessage && <span>{props.errorMessage}</span>}
    </p>
  );
}
