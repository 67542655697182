import { FiArrowLeft } from "react-icons/fi";
import AppTextButton from "../../../../components/common/buttons/AppTextButton";
import PageSection from "../../../../components/common/PageSection";
import WorkingHoursForm from "../../../../components/forms/WorkingHoursForm";
import { TUseImmutableReturn } from "../../../../hooks/hooks";

interface Props {
  selectedDates: TUseImmutableReturn<Record<string, true>>;
  goBack?(): void;
}

const HandymanWorkingHoursFormSection = (props: Props) => {
  function onFormSubmitted() {
    props.selectedDates.override({});
    props.goBack && props.goBack();
  }

  return (
    <PageSection as="section" className="flex grow flex-col">
      {!!props.goBack && (
        <header>
          <AppTextButton className="flex gap-2" onClick={props.goBack}>
            <FiArrowLeft size={25} />
            Tillbaka
          </AppTextButton>
        </header>
      )}
      <main className="flex h-full grow  flex-col">
        <WorkingHoursForm
          dates={props.selectedDates.dict}
          onFormSubmitted={() => onFormSubmitted()}
        />
      </main>
    </PageSection>
  );
};

export default HandymanWorkingHoursFormSection;
