import { AnimatePresence } from "framer-motion";
import __ from "../../../utils/utils";
import { TabContextProps } from "./TabContext";
import TabContextProvider from "./TabContextProvider";
import TabList from "./TabList";
import TabPanel from "./TabPanel";

function AppTabs<T extends string>(props: TabContextProps<T>) {
  return (
    <TabContextProvider {...props}>
      <AnimatePresence initial={false}>
        <div
          className={__.classNames(
            "grid h-full grow grid-rows-[minmax(0,1fr),auto] flex-col-reverse lg:grid-rows-[auto,minmax(0,1fr)]",
            props.className
          )}
        >
          <TabList />
          <AnimatePresence exitBeforeEnter initial={false}>
            <TabPanel key={props.selectedTab}>{props.children}</TabPanel>
          </AnimatePresence>
        </div>
      </AnimatePresence>
    </TabContextProvider>
  );
}

export default AppTabs;
