import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import withWorkerCredentials from "../../../components/auth/hocs/withWorkerCredentials";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";
import HandymanLandingPage, {
  HandymanLandingPageContext,
} from "./HandymanLandingPage";

interface Props extends TWorkerSignInData {}

const HandymanRoute = (props: Props) => {
  const [params] = useSearchParams();
  const workerId: string | null = params.get("id");

  const { state: unknownState } = useLocation();
  const state = (unknownState as any) || {};
  const { handymanRepo } = useRepos();

  const { data } = useQuery(
    ["handyman", workerId],
    async () => {
      if (workerId) {
        return handymanRepo.fetchHandyman(workerId);
      } else {
        return undefined;
      }
    },
    { initialData: state.worker }
  );

  const signedInWorker = props.handyman;
  const handyman = data ?? signedInWorker;

  return (
    <ErrorBoundary
      fallbackRender={(props) => <SomethingWentWrong error={props.error} />}
    >
      <HandymanLandingPageContext.Provider
        value={{
          signedInWorker,
          handyman,
          isViewingOwnProfile: handyman.id === signedInWorker.id,
        }}
      >
        <HandymanLandingPage />
      </HandymanLandingPageContext.Provider>
    </ErrorBoundary>
  );
};

export default withWorkerCredentials(HandymanRoute);
