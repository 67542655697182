import { HTMLMotionProps, motion } from "framer-motion";
import React, { useRef } from "react";
import { useOnEnterClick } from "../../../hooks/keyboard-event-hooks";
import __ from "../../../utils/utils";
import { AppLoader } from "../loaders/AppLoader";

interface Props extends HTMLMotionProps<"button"> {
  children?: React.ReactNode;
  loading?: boolean | number;
  buttonMode?: "fill" | "outline";
  useCustomColors?: boolean;
  colorSchema?: "default" | "brandLightBlue";
}

export function AppButton(props: Props) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useOnEnterClick({
    ref: buttonRef,
    callback: () => {
      buttonRef.current?.click();
    },
  });

  const {
    children,
    loading,
    className,
    buttonMode,
    useCustomColors,
    colorSchema,
    ...rest
  } = props;

  const mode = buttonMode ?? "fill";
  const schema = colorSchema ?? "default";
  const defaultSchema = !useCustomColors && schema === "default";
  const brandSchema = !useCustomColors && schema === "brandLightBlue";

  return (
    <motion.button
      className={__.classNames(
        "flex items-center justify-center gap-2 rounded border-2 py-2 px-4 font-semibold transition-colors",
        defaultSchema &&
          "border-button-color hover:border-button-color/80 hover:bg-button-color/80 hover:text-white focus:bg-button-color/50 focus:text-white",
        brandSchema &&
          "border-brand-light-blue bg-brand-light-blue text-black hover:border-brand-light-blue/30 hover:bg-brand-light-blue/80 focus:bg-brand-light-blue/50",
        mode === "fill" &&
          "bg-main-bg-dark text-white disabled:bg-button-color/90",
        mode === "outline" &&
          "text-button-color disabled:border-button-color/50",
        (loading || props.disabled) && "opacity-60",
        loading
          ? "cursor-wait"
          : props.disabled
          ? "cursor-auto"
          : "cursor-pointer",
        props.className
      )}
      {...rest}
      ref={buttonRef}
      whileTap={{
        scale: loading || props.disabled ? 1 : 0.95,
      }}
    >
      {children}
      {!!props.loading && <AppLoader className="mr-0 ml-2 h-4 w-4" />}
    </motion.button>
  );
}
