import { WorkOrder, WorkOrderLineItem } from "@eljouren/domain";
import { useContext } from "react";
import { FiTrash } from "react-icons/fi";
import HandymanQuickAddMaterialForm, {
  QuickAddSubmitValues,
} from "./HandymanQuickAddMaterialForm";
import HandymanWorkOrderRouteContext from "../../../../routes/worker/order/HandymanWorkOrderRouteContext";
import { useRepos } from "../../../../hooks/use-repos";
import __ from "../../../../utils/utils";
import { AppLoader } from "../../../common/loaders/AppLoader";
import WorkOrderReimbursementCalculator from "../../../../_model/helpers/WorkOrderReimbursementCalculator";
import { SignedInContext } from "../../../auth/hocs/withWorkerCredentials";
import AppTextButton from "../../../common/buttons/AppTextButton";

interface Props {
  className?: string;
  order: WorkOrder.Type;
}

const HandymanWorkOrderExtraHoursSection = (props: Props) => {
  const { extraHourRes, reportedHoursRes } = useContext(
    HandymanWorkOrderRouteContext
  );

  const { workOrderRepo } = useRepos();

  const extraHourLoading = extraHourRes.isLoading;
  const noExtraHour =
    !extraHourLoading && (!extraHourRes.data || extraHourRes.isError);

  const disabled =
    noExtraHour || reportedHoursRes.isLoading || props.order.isFinished;

  async function onSubmit(args: QuickAddSubmitValues) {
    if (!extraHourRes.data || !reportedHoursRes.query.data) {
      return;
    }

    const newData = [...reportedHoursRes.query.data];
    const prevHour = newData.find((el) => el.productId === args.material.id);
    if (prevHour) {
      prevHour.quantity += args.values.quantity;
    } else {
      newData.push(args.optimisticLineItem);
    }

    try {
      await reportedHoursRes.mutate(
        () =>
          workOrderRepo.reportExtraHour({
            workOrderId: props.order.orderId,
            quantity: args.values.quantity,
          }),
        { optimisticUpdate: newData }
      );
    } catch (ex) {
      window.modal.alert({
        title: "Det gick ej att lägga till extratimmar just nu",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: ex,
      });
    }
  }

  /*
      Should use removeHour when migration is done
      */
  async function onTrash(entry: WorkOrderLineItem.Type) {
    try {
      const data = reportedHoursRes.query.data || [];
      const optimisticUpdate = data.filter((el) => el.id !== entry.id);
      reportedHoursRes.mutate(
        () =>
          workOrderRepo.removeMaterial({
            entryId: entry.id,
            workOrderId: props.order.orderId,
          }),
        { optimisticUpdate }
      );
    } catch (er) {
      window.modal.alert({
        title: "Det gick ej att ta bort material just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  return (
    <section className={__.classNames("flex flex-col gap-2", props.className)}>
      <header>
        <h3 className="text-xl">Inrapporterad tid</h3>
      </header>
      <main className="flex flex-col gap-2 rounded-lg bg-bg-base-layer p-2 py-4 md:p-4">
        {!noExtraHour && !props.order.isFinished && !!extraHourRes.data && (
          <HandymanQuickAddMaterialForm
            disabled={disabled}
            onSubmit={onSubmit}
            type="hour"
            material={extraHourRes.data!}
            defaultQuantity={0.5}
            step={0.5}
            min={0.5}
            inputId="hourQuantityInput"
          />
        )}
        <section className="flex flex-col gap-2">
          <main className="flex flex-col">
            {reportedHoursRes.query.data &&
              !!reportedHoursRes.query.data.length && (
                <HoursTable
                  data={reportedHoursRes.query.data}
                  onTrash={onTrash}
                  isFinished={props.order.isFinished}
                />
              )}
            {!reportedHoursRes.query.isError &&
              !reportedHoursRes.isLoading &&
              (!reportedHoursRes.query.data ||
                !reportedHoursRes.query.data.length) && (
                <p>Inga extratimmar inrapporterade</p>
              )}
            {reportedHoursRes.isLoading && !reportedHoursRes.query.data && (
              <AppLoader />
            )}
            {reportedHoursRes.query.isError && !reportedHoursRes.isLoading && (
              <p>Det gick inte att hämta timmar just nu</p>
            )}
          </main>
        </section>
      </main>
    </section>
  );
};

const HoursTable = (props: {
  data: WorkOrderLineItem.Type[];
  onTrash(material: WorkOrderLineItem.Type): void;
  isFinished: boolean;
}) => {
  const calc = new WorkOrderReimbursementCalculator({
    hoursData: props.data,
  });

  const {
    reportedHoursRes: { isLoading },
  } = useContext(HandymanWorkOrderRouteContext);

  const { handyman } = useContext(SignedInContext);

  return (
    <>
      <ul className={__.classNames("w-full", isLoading && "cursor-wait")}>
        {props.data.map((hour) => {
          const { unitPrice, quantity } = hour;

          /*
          Mainly for testing purposes, not sure if this has any bad accessibility considerations
          */
          const ariaDescription = hour.addedByHandyman
            ? "Arbetstid tillagd av hantverkare"
            : "Arbetstid tillagd av kontor";

          return (
            <li
              className="grid grid-cols-[auto,minmax(40px,1fr)] items-center border-b p-1 last:border-b-0"
              key={hour.id}
              aria-description={ariaDescription}
            >
              <h3 className="text-base">{hour.name} </h3>
              {!!unitPrice && (
                <span className="row-start-2 text-sm">
                  {unitPrice * quantity}:- ({unitPrice}:- a {quantity}h)
                </span>
              )}

              {!unitPrice && (
                <span className="row-start-2 text-sm">{quantity}h</span>
              )}

              {hour.addedByHandyman && !props.isFinished && (
                <AppTextButton
                  className="col-start-3 row-span-2 ml-auto"
                  onClick={() => props.onTrash(hour)}
                  disabled={isLoading}
                >
                  <FiTrash size={25} />
                </AppTextButton>
              )}
            </li>
          );
        })}
      </ul>
      {handyman.permittedToViewPrices && (
        <span className="ml-auto p-2 text-right font-semibold">
          <span className="sr-only">Total ersättning för arbetstimmar</span>
          <span>{calc.forHours}:-</span>
        </span>
      )}
    </>
  );
};

export default HandymanWorkOrderExtraHoursSection;
