import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import withWorkerCredentials from "../../../components/auth/hocs/withWorkerCredentials";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import AppTabs from "../../../components/common/tabs/AppTabs";
import PageHeader from "../../../components/layout/PageHeader";
import { useBundledState } from "../../../hooks/hooks";
import AppRoutes from "../../../routes";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import HandymanCompanyEmployeeTab from "./HandymanCompanyEmployeeTab";
import WorkerCompanyInvoiceTab from "./WorkerCompanyInvoiceTab";
import WorkerCompanyRouteContext from "./WorkerCompanyRouteContext";

enum Tab {
  employees = "Anställda",
  invoiceBasis = "Fakturaunderlag",
}

interface Props extends TSignedInWorkerState {
  className?: string;
}

const WorkerCompanyRoute = (props: Props) => {
  const inCharge = props.handyman.companyRole === "supervisor";

  const navigate = useNavigate();

  useEffect(() => {
    if (!inCharge) {
      navigate(AppRoutes.partner.root());
    }
  }, [inCharge, navigate]);

  if (inCharge) {
    return (
      <WorkerCompanyRouteContext.Provider value={props}>
        <WorkerCompanyRouteInCharge {...props} />
      </WorkerCompanyRouteContext.Provider>
    );
  } else {
    return <AppLoader />;
  }
};

interface InChargeProps extends TSignedInWorkerState {
  //info: TUserCompanyInformation;
}

/*
ToDo:
* Split employee section and invoice section into two different components
* Clean up the date logic for the invoice section
*/
const WorkerCompanyRouteInCharge = (props: InChargeProps) => {
  const tab = useBundledState(Tab.employees);
  const { companyName } = props.handyman;

  return (
    <section className="mx-auto flex h-full w-full flex-col">
      <PageHeader
        className="border-b"
        heading={tab.value}
        subheading={companyName}
      ></PageHeader>
      <main className="flex grow flex-col gap-4">
        <AppTabs
          tabs={Object.values(Tab)}
          mainClassName="p-2 lg:p-6"
          selectedTab={tab.value}
          onSelect={(newTab) => tab.set(newTab)}
        >
          {tab.value === Tab.employees && <HandymanCompanyEmployeeTab />}
          {tab.value === Tab.invoiceBasis && <WorkerCompanyInvoiceTab />}
        </AppTabs>
      </main>
    </section>
  );
};

export default withWorkerCredentials(WorkerCompanyRoute);
