"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileServerGetFilesResponse = void 0;
var CollectionType_1 = require("../../shared-schemas/CollectionType");
var UploaderSource_1 = require("../../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var FileServerGetFilesResponse;
(function (FileServerGetFilesResponse) {
    FileServerGetFilesResponse.SingleObjectSchema = zod_1.z.object({
        src: zod_1.z.string(),
        recordId: zod_1.z.string(),
        uploadedBy: UploaderSource_1.UploaderSource.Schema,
        collectionType: CollectionType_1.CollectionType.Schema,
        guid: zod_1.z.string(),
        name: zod_1.z.string(),
        ext: zod_1.z.string(),
    });
    FileServerGetFilesResponse.AsObjectSchema = FileServerGetFilesResponse.SingleObjectSchema.array();
    FileServerGetFilesResponse.AsSrcSchema = zod_1.z.string().array();
    FileServerGetFilesResponse.Schema = zod_1.z.union([
        FileServerGetFilesResponse.SingleObjectSchema.array(),
        zod_1.z.string().array(),
    ]);
})(FileServerGetFilesResponse || (FileServerGetFilesResponse = {}));
exports.FileServerGetFilesResponse = FileServerGetFilesResponse;
