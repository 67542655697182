import { useContext } from "react";
import { useDetectKeyboardOpen } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import TabContext from "./TabContext";

interface Props {
  className?: string;
}

const TabList = (props: Props) => {
  const keyboardOpen = useDetectKeyboardOpen();

  const ctx = useContext(TabContext);
  if (ctx.withoutControls) {
    return <></>;
  }

  if (!ctx.showTabListOnKeyboardOpen && keyboardOpen) {
    return <></>;
  }

  return (
    <div
      role="tablist"
      className={__.classNames("grid bg-white lg:border-t-0", props.className)}
      style={{
        gridTemplateColumns: `repeat(${ctx.tabs.length},1fr)`,
      }}
    >
      {ctx.tabs.map((tab) => (
        <button
          className={__.classNames(
            "flex grow flex-col items-center border-t-2 p-1 py-2 font-bold transition-all last:border-r-0 lg:flex-row lg:justify-center lg:gap-2 lg:border-t-0 lg:border-b-2 lg:p-2",
            "hover:border-tab-border-selected hover:text-black",
            ctx.selectedTab === tab && "border-tab-border-selected text-black",
            ctx.selectedTab !== tab &&
              "border-tab-border-not-selected text-black/60"
          )}
          key={tab}
          onClick={() => ctx.onTabClick(tab)}
          aria-selected={ctx.selectedTab === tab}
          role="tab"
        >
          {ctx.icon && ctx.icon(tab)}
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabList;
