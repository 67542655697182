import { Handyman } from "@eljouren/domain/build";
import React, { useContext } from "react";
import { GlobalContext } from "../../../top-level-contexts";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import SignInSection from "../SignInSection";
import LoadingContent from "../../common/loaders/LoadingContent";

export const SignedInContext = React.createContext<{
  handyman: Handyman.Type;
}>({
  get handyman(): Handyman.Type {
    throw new Error("Not signed in");
  },
});

const withWorkerCredentials =
  (WrappedComponent: (props: TSignedInWorkerState) => React.ReactElement) =>
  () => {
    const { signInState } = useContext(GlobalContext);

    return (
      <LoadingContent loading={signInState.isLoading} renderContentSeparately>
        {(!signInState.isSignedIn || signInState.signedInAs === "customer") && (
          <SignInSection />
        )}
        {signInState.isSignedIn && signInState.signedInAs === "worker" && (
          <SignedInContext.Provider
            value={{
              handyman: signInState.handyman,
            }}
          >
            <WrappedComponent {...signInState} />
          </SignedInContext.Provider>
        )}
      </LoadingContent>
    );
  };

export default withWorkerCredentials;
