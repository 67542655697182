import { useEffect } from "react";
import withSignUpId from "../../components/auth/hocs/withSignUpId";
import SignUpForm from "../../components/forms/SignUpForm";
import PageHeader from "../../components/layout/PageHeader";
import { useRepos } from "../../hooks/use-repos";
import __ from "../../utils/utils";

const ResetPasswordRoute = () => {
  const { authRepo } = useRepos();
  useEffect(() => {
    authRepo.signOut();
  }, [authRepo]);

  return (
    <section className={__.classNames("w-full")}>
      <PageHeader heading="Återställ lösenord" />
      <main>
        <SignUpForm type="reset" />
      </main>
    </section>
  );
};

export default withSignUpId("reset", ResetPasswordRoute);
