import { TRPCClientError } from "@trpc/client";
import NetworkRequestError from "./NetworkRequestError";

export default class MaybeTrpcError extends NetworkRequestError {
  private _isHandled: boolean;
  readonly _publicId: string | undefined;

  constructor(error: unknown) {
    if (error instanceof TRPCClientError) {
      super(error.message);
      this._publicId = error.data.publicId;
    } else {
      super("Unknown error type");
    }
    this._isHandled = false;
  }

  getPublicId(): string | undefined {
    return this._publicId;
  }
  isHandled(): boolean {
    return this._isHandled;
  }
}
