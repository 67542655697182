"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRepoUploadBase64Response = void 0;
var zod_1 = require("zod");
var FileErrorReason_1 = require("./FileErrorReason");
var FileRepoUploadBase64Response;
(function (FileRepoUploadBase64Response) {
    var Success = zod_1.z.object({
        successState: zod_1.z.literal("complete-success"),
    });
    var SuccessWithoutMeta = zod_1.z.object({
        successState: zod_1.z.literal("success-without-meta"),
        error: zod_1.z.unknown().optional(),
    });
    var Failure = zod_1.z.object({
        successState: zod_1.z.literal("failure"),
        reason: FileErrorReason_1.FileErrorReason.Schema,
        fileServiceJson: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
        error: zod_1.z.unknown().optional(),
    });
    FileRepoUploadBase64Response.Schema = zod_1.z.discriminatedUnion("successState", [
        Success,
        SuccessWithoutMeta,
        Failure,
    ]);
})(FileRepoUploadBase64Response || (FileRepoUploadBase64Response = {}));
exports.FileRepoUploadBase64Response = FileRepoUploadBase64Response;
