import { Handyman } from "@eljouren/domain/build";
import { useContext } from "react";
import HandymanWorkOrderRouteContext from "../../../../routes/worker/order/HandymanWorkOrderRouteContext";
import __ from "../../../../utils/utils";
import PageSection from "../../../common/PageSection";
import HandymanWorkOrderRelatedFilesList from "./HandymanWorkOrderRelatedFilesList";
import HandymanWorkOrderExtraHoursSection from "./HandymanWorkOrderExtraHoursSection";
import CustomerOrderExtraMaterialSection from "./HandymanWorkOrderExtraMaterialSection";
import WorkOrderHandymanControlsFooter from "./WorkOrderHandymanControlsFooter";
import WorkOrderHandymanControlsTextContentSection from "./WorkOrderHandymanControlsTextContentSection";

export type CustomerOrderWorkerControlsProps = {
  className?: string;
  //order: WorkOrder.HandymanType;
  worker?: Handyman.Type;
  checkIn?(): void;
  checkOut?(): void;
};

const HandymanWorkOrderControls = (props: CustomerOrderWorkerControlsProps) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);

  return (
    <section className={__.classNames("flex flex-col gap-2", props.className)}>
      <main className="flex flex-col xl:gap-6 2xl:grid 2xl:grid-cols-2 ">
        <WorkOrderHandymanControlsTextContentSection className="rborder-b pb-2" />

        <PageSection as="section">
          <main className="flex flex-col gap-8">
            <HandymanWorkOrderExtraHoursSection order={order} />
            <CustomerOrderExtraMaterialSection />
          </main>
          <WorkOrderHandymanControlsFooter className="p-2" />
        </PageSection>
        <PageSection as="section" className="flex flex-col gap-2">
          <header>
            <h3 className="text-lg">Relaterade filer</h3>
          </header>
          <main>
            <HandymanWorkOrderRelatedFilesList />
          </main>
        </PageSection>
      </main>
    </section>
  );
};

export default HandymanWorkOrderControls;
