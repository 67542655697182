import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import AppLayout from "./components/layout/AppLayout";
import Modal from "./components/notifications/Modal";
import QueueModal from "./components/notifications/QueueModal";
import QueueContextProvider from "./components/queue/QueueContextProvider";
import { useBind } from "./hooks/observable-hooks";
import { useRepos } from "./hooks/use-repos";
import { GlobalContext } from "./top-level-contexts";
import FetchInterceptor from "./utils/FetchInterceptor";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});
function fixAppHeightForMobileDevices() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", fixAppHeightForMobileDevices);
fixAppHeightForMobileDevices();
window.fetch = FetchInterceptor.func;

interface Props {
  children?: React.ReactNode;
}

function App(props: Props) {
  const { authRepo, workOrderRepo: customerOrderRepo } = useRepos();
  const signInState = useBind(authRepo.signedInState);

  /*
    To show the right logo based on brand.
    Not really a fan of this.
  */
  useEffect(() => {
    const unsub = customerOrderRepo.onWorkOrderFetched((order) =>
      authRepo.notifyCustomerOrderFetched(order)
    );
    return unsub;
  });

  /*
    Clear all data when the user signs out 
  */
  useEffect(() => {
    if (!signInState.isSignedIn) {
      queryClient.clear();
    }
  }, [signInState.isSignedIn]);

  /* 
    So that the profile route can use the cached data
  */
  useEffect(() => {
    queryClient.setQueryData("signInStateProfile", signInState.handyman);
  }, [signInState.isSignedIn, signInState.handyman]);

  const location = useLocation();

  return (
    <GlobalContext.Provider
      value={{
        signInState,
        location,
      }}
    >
      <QueueContextProvider>
        <QueryClientProvider client={queryClient}>
          <AppLayout>
            <Outlet />
            {props.children}
          </AppLayout>

          {/* 
            ToDo: Better fallback render
          */}
          <ErrorBoundary
            fallbackRender={() => {
              console.log("Modal failed to render");

              return <></>;
            }}
          >
            <Modal />
            <QueueModal />
          </ErrorBoundary>
        </QueryClientProvider>
      </QueueContextProvider>
    </GlobalContext.Provider>
  );
}

export default App;
