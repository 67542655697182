"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRepoUploadMultipleResponse = void 0;
var zod_1 = require("zod");
var FileErrorReason_1 = require("./FileErrorReason");
var FileServerUploadSingleFileResponse_1 = require("../file-server-routes/output/FileServerUploadSingleFileResponse");
var OperationResults_1 = require("../shared-schemas/OperationResults");
var FileMetaInput_1 = require("../shared-schemas/FileMetaInput");
var FileRepoUploadMultipleResponse;
(function (FileRepoUploadMultipleResponse) {
    FileRepoUploadMultipleResponse.ResultsArraySchema = zod_1.z
        .union([
        FileServerUploadSingleFileResponse_1.FileServerUploadSingleFileResponse.Schema.extend({
            operations: OperationResults_1.OperationResults.UploadSchema,
            meta: zod_1.z.union([FileMetaInput_1.FileMetaInput.Schema, zod_1.z.literal(false)]),
            metaErrorMessage: zod_1.z.string().nullable(),
        }),
        zod_1.z.literal(false),
    ])
        .array();
    var Success = zod_1.z.object({
        successState: zod_1.z.literal("complete-success"),
        results: FileRepoUploadMultipleResponse.ResultsArraySchema,
    });
    var SuccessWithoutMeta = zod_1.z.object({
        successState: zod_1.z.literal("partial-success"),
        error: zod_1.z.unknown().optional(),
        results: FileRepoUploadMultipleResponse.ResultsArraySchema.nullable(),
    });
    var Failure = zod_1.z.object({
        successState: zod_1.z.literal("failure"),
        reason: FileErrorReason_1.FileErrorReason.Schema,
        fileServiceJson: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
        error: zod_1.z.unknown().optional(),
    });
    FileRepoUploadMultipleResponse.Schema = zod_1.z.discriminatedUnion("successState", [
        Success,
        SuccessWithoutMeta,
        Failure,
    ]);
})(FileRepoUploadMultipleResponse || (FileRepoUploadMultipleResponse = {}));
exports.FileRepoUploadMultipleResponse = FileRepoUploadMultipleResponse;
