import { TWorkerAssociation } from "./_model/repos/interfaces/IHandymanRepo";

const AppRoutes = {
  root: () => "/",
  signUp: {
    root: (signUpId?: string) => `/anslut/${signUpId ? signUpId : ":signUpId"}`,
  },
  resetPassword: {
    root: (signUpId?: string) =>
      `/aterstall/${signUpId ? signUpId : ":signUpId"}`,
  },
  customer: {
    root: (workOrderGuid?: string) =>
      `/kund/${workOrderGuid ? workOrderGuid : ":workOrderGuid"}`,
  },
  partner: {
    root: (args: { userId?: string } = {}) =>
      args.userId ? `/partner/?id=${args.userId}` : "/partner",
    profile: () => "/partner/profil",
    customerOrder: (workerGuid?: string) =>
      `/partner/arbetsorder/${workerGuid ? workerGuid : ":workerGuid"}`,
    company: () => "/partner/foretag",
  },
  salesTeam: {
    customerOrder: (workerGuid?: string) =>
      `/salj/arbetsorder/${workerGuid ? workerGuid : ":salesGuid"}`,
    partnerList: () => `/salj/partners`,
    partnerListWithAssociation: (type?: TWorkerAssociation) =>
      `/salj/partners/${type || ":workerAssociation"}`,
    singlePartner: (id?: string) => `/salj/partner/${id || ":workerId"}`,
  },
  staff: {
    root: (guid?: string) => `/personal/${guid ? guid : ":staffGuid"}`,
    test: (guid?: string) => `/personal/${guid ? guid : ":staffGuid"}/test`,
  },
  admin: {
    errorLogs: () => `/admin/errors`,
  },
};

export default AppRoutes;
