import { useContext } from "react";
import withStaffGuid from "../../components/auth/hocs/withStaffGuid";
import AnimatedCheckmark from "../../components/common/AnimatedCheckMark";
import PageHeader from "../../components/layout/PageHeader";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";

const StaffRoot = () => {
  const { signInState } = useContext(GlobalContext);
  if (signInState.signedInAs !== "staff") {
    return <></>;
  }

  const name = `${signInState.firstName} ${signInState.lastName}`;

  return (
    <section
      className={__.classNames("grid h-full grid-rows-[auto,minmax(0,1fr)]")}
    >
      <PageHeader heading="Personalinlogg" subheading={name} />
      <main className="h-full-w-full flex items-center justify-center">
        <AnimatedCheckmark text={`Du är inloggad som ${name}!`} />
      </main>
    </section>
  );
};

export default withStaffGuid(StaffRoot);
