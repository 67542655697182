"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChecklistAnswers = void 0;
var zod_1 = require("zod");
var ChecklistAnswers;
(function (ChecklistAnswers) {
    /*
      Single answers
    */
    ChecklistAnswers.SingleAnswerSchema = zod_1.z.object({
        answer: zod_1.z.string(),
        comment: zod_1.z.string().nullable(),
        checklistItemId: zod_1.z.string(),
    });
    /*
      Complete answers
    */
    // Domain
    ChecklistAnswers.Schema = ChecklistAnswers.SingleAnswerSchema.array();
})(ChecklistAnswers || (ChecklistAnswers = {}));
exports.ChecklistAnswers = ChecklistAnswers;
