import React from "react";
import { Location } from "react-router-dom";
import IAuthRepo, { TSignInState } from "./_model/repos/interfaces/IAuthRepo";
import ICompanyRepo from "./_model/repos/interfaces/ICompanyRepo";
import IErrorLogRepo from "./_model/repos/interfaces/IErrorLogRepo";
import IHandymanRepo from "./_model/repos/interfaces/IHandymanRepo";
import IWorkOrderRepo from "./_model/repos/interfaces/IWorkOrderRepo";

/*
	This might be worth to remove, once tests are in place.
*/
export const GlobalContext = React.createContext<{
  signInState: TSignInState;
  location: Location;
}>({
  signInState: {
    isSignedIn: false,
    isLoading: false,
  },
  location: {
    hash: "",
    key: "",
    pathname: "",
    state: "",
    search: "",
  },
});

export type TRepoContextValue = {
  authRepo: IAuthRepo;
  companyRepo: ICompanyRepo;
  workOrderRepo: IWorkOrderRepo;
  handymanRepo: IHandymanRepo;
  errorLogRepo: IErrorLogRepo;
};

export const RepoContext = React.createContext<TRepoContextValue>({
  get authRepo(): IAuthRepo {
    throw new Error("Repo context not initialised");
  },
  get companyRepo(): ICompanyRepo {
    throw new Error("Repo context not initialised");
  },
  get workOrderRepo(): IWorkOrderRepo {
    throw new Error("Repo context not initialised");
  },
  get handymanRepo(): IHandymanRepo {
    throw new Error("Repo context not initialised");
  },
  get errorLogRepo(): IErrorLogRepo {
    throw new Error("Repo context not initialised");
  },
});
