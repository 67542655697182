import { UploaderSource } from "@eljouren/file-schemas/build";
import { AiTwotoneTool } from "react-icons/ai";
import { IoMdCart } from "react-icons/io";
import { BsHeadset } from "react-icons/bs";

interface Props {
  size: number;
  uploaderSource: UploaderSource.Type;
}

const IpisUploadedByIcon = (props: Props) => {
  switch (props.uploaderSource) {
    case "customer":
      return <IoMdCart size={props.size} />;
    case "staff":
      return <BsHeadset size={props.size} />;
    case "worker":
      return <AiTwotoneTool size={props.size} />;
  }

  return <></>;
};

export default IpisUploadedByIcon;
