import { useRef } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import __ from "../../../utils/utils";
import { UUID } from "../../../utils/UUID";

type TSelectOption = {
  value: string;
  label: string;
};

// ToDo: Make options typesafe
interface Props<T extends FieldValues, P extends Path<T>> {
  name: P;
  label?: string;
  errorMessage?: string;
  errors?: FieldErrors<T>;
  className?: string;
  options: TSelectOption[];

  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions;
  htmlAttributes?: Omit<JSX.IntrinsicElements["select"], "ref">;
}
export function AppFormSelect<T extends FieldValues, P extends Path<T>>(
  props: Props<T, P>
) {
  const id = useRef(UUID.generate().value).current;

  const registerOptions = props.registerOptions || {};

  const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError();

  return (
    <p className={__.classNames("flex w-full flex-col gap-1", props.className)}>
      {props.label && (
        <label htmlFor={id} className="text-lg">
          {props.label}
        </label>
      )}
      <select
        className={__.classNames(
          "flex w-full cursor-pointer border border-form-border p-2"
        )}
        id={id}
        {...props.register(props.name, registerOptions)}
        {...props.htmlAttributes}
      >
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span>{error}</span>}
    </p>
  );
}
