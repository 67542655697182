import React, { useContext } from "react";
import { FiEye, FiMail, FiPhoneCall, FiUser } from "react-icons/fi";
import { IoMdBusiness } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { useBrand } from "../../hooks/brand-hooks";
import AppRoutes from "../../routes";
import { GlobalContext } from "../../top-level-contexts";
import __ from "../../utils/utils";
import MyLink, { MyLinkProps } from "../routing/MyLink";
import { BiError } from "react-icons/bi";

interface Props {
  className?: string;
  omitProfile?: boolean;
}

const AppNavLinks = (props: Props) => {
  const { signInState, location } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();

  const brand = useBrand();

  let phone: string;
  let formattedPhone: string;
  let mail: string | undefined;

  if (brand === "eljouren") {
    phone = "0102881240";
    formattedPhone = "010-288 12 40";
    mail = "kontakt@svenskaeljouren.se";
  } else if (brand === "rorjouren") {
    phone = "0108885240";
    formattedPhone = "010-888 52 40";
    mail = "kontakt@rorjour247.se";
  } else {
    phone = "0108989046";
    formattedPhone = "010-898 90 46";
  }

  const iconSize = 25;
  const iconClass = "rounded-full";
  const textClass = "truncate";

  function onPage(pageWithoutEndingSlash: string) {
    const { pathname } = location;
    return (
      pathname === pageWithoutEndingSlash ||
      pathname === pageWithoutEndingSlash + "/"
    );
  }

  const onHomePage = onPage("/partner") && !searchParams.has("id");
  const onProfilePage = onPage("/partner/profil");
  const onCompanyPage = onPage("/partner/foretag");
  const onErrorLogPage = onPage("/admin/errors");
  //const onNewsPage = onPage("/partner/nyheter");
  return (
    <ul
      className={__.classNames(
        "mx-auto flex h-full w-full flex-col gap-2 text-lg text-white lg:gap-8 lg:text-xl",
        props.className
      )}
    >
      <li className="mr-auto flex w-full rounded-md">
        <ul className="flex w-full flex-col">
          {signInState.handyman && (
            <MenuItem>
              <StyledLink
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                to={AppRoutes.partner.root()}
                onPage={onHomePage}
              >
                <FiEye size={iconSize} className={iconClass} />
                <span className={textClass}>Översikt</span>
              </StyledLink>
            </MenuItem>
          )}

          {signInState.handyman && !props.omitProfile && (
            <MenuItem>
              <StyledLink
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                to={AppRoutes.partner.profile()}
                onPage={onProfilePage}
              >
                <FiUser size={iconSize} className={iconClass} />
                <span className={textClass}>Profil</span>
              </StyledLink>
            </MenuItem>
          )}
          {signInState.handyman &&
            signInState.handyman.companyRole === "supervisor" && (
              <MenuItem>
                <StyledLink
                  onPage={onCompanyPage}
                  className="text-bold flex items-center gap-3 drop-shadow-sm"
                  to={AppRoutes.partner.company()}
                >
                  <IoMdBusiness size={iconSize} className={iconClass} />
                  <span className={textClass}>Företag</span>
                </StyledLink>
              </MenuItem>
            )}
          {signInState.isSignedIn && signInState.isAdmin && (
            <MenuItem>
              <StyledLink
                onPage={onErrorLogPage}
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                to={AppRoutes.admin.errorLogs()}
              >
                <BiError size={iconSize} className={iconClass} />
                <span className={textClass}>Errorlog</span>
              </StyledLink>
            </MenuItem>
          )}
          {/*  {worker && (
            <MenuItem>
              <StyledLink
                onPage={onNewsPage}
                className="text-bold flex items-center gap-3 drop-shadow-sm"
                to="/partner/nyheter"
              >
                <BiNews size={iconSize} className={iconClass} />
                <span className={textClass}>Nyheter</span>
              </StyledLink>
            </MenuItem>
          )} */}
        </ul>
      </li>

      <li
        className="mr-auto mt-auto w-full p-2"
        //variants={contactSectionVariants}
      >
        <ul className="flex flex-col gap-4 rounded-md p-3 text-sm">
          <MenuItem>
            <a
              className="text-bold flex items-center gap-3 drop-shadow-sm"
              href={`tel:${phone}`}
            >
              <FiPhoneCall size={iconSize} className={iconClass} />
              <span className={textClass}>{formattedPhone}</span>
            </a>
          </MenuItem>
          {mail && (
            <MenuItem>
              <a
                className="text-semibold flex items-center gap-3"
                href={`mailto:${mail}`}
              >
                <FiMail size={iconSize} className={iconClass} />
                <span className={textClass}>{mail}</span>
              </a>
            </MenuItem>
          )}
        </ul>
      </li>
    </ul>
  );
};

const StyledLink = (
  props: {
    onPage: boolean;
  } & MyLinkProps &
    React.RefAttributes<HTMLAnchorElement>
) => {
  const { onPage, ...rest } = props;

  return (
    <MyLink
      {...rest}
      className={__.classNames(
        "text-bold flex items-center gap-3 rounded p-3 text-lg drop-shadow-sm transition-all",
        onPage && "scale-10 pointer-events-none bg-main-bg-dark",
        !onPage && "hover:bg-main-bg-dark",
        props.className
      )}
    >
      {props.children}
    </MyLink>
  );
};

const MenuItem = (props: { children?: React.ReactNode }) => {
  return <li className="w-full">{props.children}</li>;
};

export default AppNavLinks;
