import { Handyman, WorkOrder } from "@eljouren/domain/build";
import {
  Observable,
  PrivateObservable,
} from "../../../_model/observables/Observable";
import IAuthRepo, {
  TSignInState,
  TSignUpIdType,
} from "../../../_model/repos/interfaces/IAuthRepo";

/* 
	The functionality of this will be added as we go along, when we need it
*/
export default class WorkingAuthRepoMock implements IAuthRepo {
  private mockHandyMan: Handyman.Type = {
    id: "12345",
    firstName: "Anders",
    lastName: "Andersson",
    email: "anders@andersson.se",
    phone: "+4612345675",
    street: "Andersgatan 7",
    city: "Uppsala",
    postalCode: "12345",
    country: "Sweden",
    companyRole: "supervisor",
    permittedToViewPrices: true,
    companyId: "c12345",
    companyName: "Anders Bygg AB",
    activeAreas: ["Uppsala", "Stockholm"],
    userType: Handyman.UserType.worker,
    profession: "electrician",
    qualifications: ["Jonglera", "Balett"],
  };

  private _signedInState = new Observable<TSignInState>({
    isSignedIn: false,
    isLoading: false,
  });

  get signedInState(): PrivateObservable<TSignInState> {
    return this._signedInState.privateObs;
  }
  async notifyCustomerOrderFetched(order: WorkOrder.Type): Promise<void> {
    const state = { ...this.signedInState.value };

    if (state.signedInAs === "customer" || state.signedInAs === "sales") {
      state.brand = order.brand;
      this._signedInState.set(state);
    }
  }

  async customerAuthentication(orderId: string): Promise<200 | 404> {
    return 200;
  }
  async signUpIdAuthentication(
    signUpId: string,
    type: TSignUpIdType
  ): Promise<200 | 404> {
    return 200;
  }
  async salesAuthentication(orderId: string): Promise<200 | 404> {
    return 200;
  }
  async staffAuthentication(orderId: string): Promise<200 | 404> {
    return 200;
  }
  async signInWithToken(): Promise<{ handyman: Handyman.Type } | null> {
    this._signedInState.set({
      isSignedIn: true,
      signedInAs: "worker",
      handyman: this.mockHandyMan,
      isLoading: false,
    });
    return { handyman: this.mockHandyMan };
  }
  async signInWithCredentials(
    usernameOrEmail: string,
    password: string
  ): Promise<{
    handyman: Handyman.Type;
  } | null> {
    this._signedInState.set({
      isSignedIn: true,
      signedInAs: "worker",
      handyman: this.mockHandyMan,
      isLoading: false,
    });
    return { handyman: this.mockHandyMan };
  }
  async signOut(): Promise<void> {
    this._signedInState.set({ isSignedIn: false, isLoading: false });
  }
  async choosePassword(args: {
    signUpId: string;
    password: string;
    type: TSignUpIdType;
  }): Promise<boolean> {
    return true;
  }
  invokeResetPasswordFlow(args: { email: string }): Promise<void> {
    throw new Error("Not implemented");
  }
}
