import Process from "./Process";
import ProcessTask from "./ProcessTask";

export type ProcessQueueState = {
  currentProcess: Process | null;
  currentTask: ProcessTask | null;
  previousProcess: Process | null;
  previousTask: ProcessTask | null;
};

type ProcessQueueArgs = {
  processes: Process[];
  currentProcessIndex?: number;
  subscribers?: ((state: ProcessQueueState) => void)[];
};

// ProcessQueue class to manage and run a queue of processes
export default class ProcessQueue {
  private processes: Process[];
  private currentProcessIndex: number;
  private subscribers: ((state: ProcessQueueState) => void)[];
  private finishedSubscribers: (() => void)[];
  private _isCanceled: boolean;

  constructor(args: ProcessQueueArgs) {
    this.processes = args.processes;
    this.currentProcessIndex = args.currentProcessIndex || 0;
    this.subscribers = args.subscribers || [];
    this.finishedSubscribers = [];
    this._isCanceled = false;
  }

  // Returns if the queue is canceled
  isCanceled(): boolean {
    return this._isCanceled;
  }

  getProcessCount(): number {
    return this.processes.length;
  }

  getCurrentProcessIndex(): number {
    return this.currentProcessIndex;
  }

  // Returns the current state of the process queue
  getState(): ProcessQueueState {
    return {
      currentProcess: this.getCurrentProcess(),
      currentTask: this.getCurrentTask(),
      previousProcess: this.getPreviousProcess(),
      previousTask: this.getPreviousTask(),
    };
  }

  // Returns the current process in the queue
  getCurrentProcess(): Process | null {
    return this.processes[this.currentProcessIndex] || null;
  }

  // Returns the previous process in the queue
  getPreviousProcess(): Process | null {
    return this.currentProcessIndex > 0
      ? this.processes[this.currentProcessIndex - 1]
      : null;
  }

  // Returns the current task of the current process
  getCurrentTask(): ProcessTask | null {
    const currentProcess = this.getCurrentProcess();
    return currentProcess
      ? currentProcess.getTasks()[currentProcess.getCurrentTaskIndex()]
      : null;
  }

  // Returns the previous task in the current or previous process
  getPreviousTask(): ProcessTask | null {
    const currentProcess = this.getCurrentProcess();
    const previousProcess = this.getPreviousProcess();
    if (previousProcess) {
      const taskIndex = previousProcess.getCurrentTaskIndex() - 1;
      if (taskIndex >= 0) {
        return previousProcess.getTasks()[taskIndex];
      }
    } else if (currentProcess) {
      const taskIndex = currentProcess.getCurrentTaskIndex() - 1;
      if (taskIndex >= 0) {
        return currentProcess.getTasks()[taskIndex];
      }
    }

    return null;
  }

  // Notifies all subscribers with the current state
  private notifySubscribers(): void {
    const state = {
      currentProcess: this.getCurrentProcess(),
      currentTask: this.getCurrentTask(),
      previousProcess: this.getPreviousProcess(),
      previousTask: this.getPreviousTask(),
    };

    this.subscribers.forEach((subscriber) => subscriber(state));
  }

  // Subscribes to the state updates of the process queue
  subscribe(callback: (state: ProcessQueueState) => void): () => void {
    this.subscribers.push(callback);
    callback({
      currentProcess: this.getCurrentProcess(),
      currentTask: this.getCurrentTask(),
      previousProcess: this.getPreviousProcess(),
      previousTask: this.getPreviousTask(),
    });

    return () => {
      this.subscribers = this.subscribers.filter(
        (subscriber) => subscriber !== callback
      );
    };
  }

  // Runs the process queue, executing each process until finished or canceled
  async run(): Promise<void> {
    while (!this.isFinished() && !this._isCanceled) {
      const process = this.processes[this.currentProcessIndex];

      // Subscribe to the state of the Process
      const unsubscribeProcessState = process.subscribeToState(() => {
        this.notifySubscribers();
      });

      await process.runUntilFinished();
      unsubscribeProcessState(); // Unsubscribe from the Process when it is finished or a new task is started

      this.currentProcessIndex++;
      this.notifySubscribers();

      if (this.isFinished()) {
        this.notifyFinishedSubscribers();
      }
    }
  }

  // Removes all subscribers from the process queue
  private removeAllSubscribers(): void {
    this.subscribers = [];
  }

  // Returns true if the process queue is finished
  isFinished(): boolean {
    return this.currentProcessIndex >= this.processes.length;
  }

  // Adds processes to the existing process queue, creating a new queue with the added processes
  addProcesses(processes: Process[]): ProcessQueue {
    if (this.isFinished()) {
      throw new Error("Cannot add processes to a finished queue.");
    }
    const newQueue = new ProcessQueue({
      processes: [...this.processes, ...processes],
      currentProcessIndex: this.currentProcessIndex,
      subscribers: this.subscribers.slice(),
    });

    this._isCanceled = true;
    this.removeAllSubscribers();

    return newQueue;
  }

  // Finds a process in the process queue by its ID
  findProcessById(id: string): Process | null {
    return this.processes.find((process) => process.id === id) || null;
  }

  // Cancels the process queue and removes all subscribers
  cancel(): void {
    this._isCanceled = true;
    this.removeAllSubscribers();
  }

  // Subscribes to the finished event of the process queue
  onFinished(callback: () => void): () => void {
    this.finishedSubscribers.push(callback);

    if (this.isFinished()) {
      callback();
    }

    return () => {
      this.finishedSubscribers = this.finishedSubscribers.filter(
        (subscriber) => subscriber !== callback
      );
    };
  }

  // Notifies all finished subscribers that the process queue is finished
  private notifyFinishedSubscribers(): void {
    this.finishedSubscribers.forEach((subscriber) => subscriber());
  }

  //Get copy of the processes
  getProcesses(): Process[] {
    return this.processes.slice();
  }

  getFailedTasks(): { task: ProcessTask; process: Process }[] {
    return this.processes.reduce((acc, process) => {
      const failedTasks = process.getFailedTasks();
      const withProcess = failedTasks.map((task) => ({ task, process }));
      return acc.concat(withProcess);
    }, [] as { task: ProcessTask; process: Process }[]);
  }
}
