import { WorkOrder } from "@eljouren/domain";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import __ from "../../utils/utils";
import DateHelper from "../../_model/helpers/DateHelper";
import PageSection from "../common/PageSection";
import HandymanContext from "../handyman-context/HandymanContext";
import HandymanWorkOrderListItem from "../work-order/handyman/HandymanWorkOrderListItem";
import Calendar, { TCalendarView } from "./Calendar";

interface Props {
  className?: string;
  defaultView?: TCalendarView;
}

/* 
  At the start, the Calendar was written to be generic, which is why the orders are converted to events and whatnot.

  This is no longer the case, but some things may seem weird because of this migration
*/
const HandymanCalendar = (props: Props) => {
  const nav = useNavigate();
  const ctx = useContext(HandymanContext);

  function onActiveDateChanged(index: number) {
    ctx.setPage(index);
  }

  return (
    <PageSection
      as="section"
      className={__.classNames("h-full w-full", props.className)}
    >
      <Calendar
        className="mx-auto max-w-screen-lg"
        pageIndex={ctx.page}
        loading={ctx.workOrderRes.isLoading}
        onIndexChanged={onActiveDateChanged}
        defaultView={props.defaultView}
        events={ctx.workOrderRes.data?.map((order) => ({
          id: order.orderId,
          start: order.startDate,
          end: order.endDate,
          name: order.description,
          description: order.additionalInformation,
          attachedData: order,
          onClick: () => {
            nav(`/partner/arbetsorder/${order.workerGuid}`);
          },
        }))}
        weeksInPeriod={ctx.weeksInPeriod}
        listViewFilter={[
          {
            id: "showFinishedOrders",
            label: "Göm klarmarkerade arbetsordrar",
            filter: (event) => !event.props.attachedData?.isFinished,
            type: "checkbox",
          },
          {
            id: "filterOrdersByText",
            label: "Filtrera",
            filter: (event, text) => {
              const order = event.props
                .attachedData as WorkOrder.HandymanWithPermissionsType;
              const filterBy = [
                order.orderId,
                `#${order.serialNumber}`,
                order.description,
                order.additionalInformation,
                order.typeOfService,
                order.contact.email,
                order.contact.fullName,
                order.contact.phone,
                order.contact.phone2,
                order.location.city,
                order.location.country,
                order.location.street,
                order.location.postalCode,
                order.internalDescription,
                order.categories.join(""),
                new DateHelper(order.startDate).dateTimeInputFormat,
                new DateHelper(order.endDate).dateTimeInputFormat,
              ];

              return text.split(" ").every((str) => {
                return filterBy.some((filter) => {
                  if (!filter) {
                    return false;
                  }
                  return filter.toLowerCase().includes(str.toLowerCase());
                });
              });
            },
            type: "text",
          },
        ]}
        RenderListViewItem={(props) => {
          const order = props.event.props
            .attachedData as WorkOrder.HandymanWithPermissionsType;
          return <HandymanWorkOrderListItem order={order} />;
        }}
        RenderDayViewItem={(props) => {
          const order = props.event.props
            .attachedData as WorkOrder.HandymanWithPermissionsType;
          return (
            <HandymanWorkOrderListItem
              order={order}
              className="bg-bg-base-layer"
            />
          );
        }}
      />
    </PageSection>
  );
};

export default HandymanCalendar;
