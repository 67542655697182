import { WorkOrder } from "@eljouren/domain/build";
import React from "react";
import { UseMutatableQueryResult } from "../../hooks/use-mutatable-query";

const CustomerWorkOrderContext = React.createContext<{
  workOrder: WorkOrder.CustomerType;
  //form: UseFormReturn<WorkOrder.Type>;
  workOrderRes: UseMutatableQueryResult<WorkOrder.CustomerType>;
}>({} as never);

export default CustomerWorkOrderContext;
