import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BiCheck, BiCheckCircle } from "react-icons/bi";
import { FaCheck, FaHandshake } from "react-icons/fa";
import PageSection from "../../../components/common/PageSection";
import AppTabs from "../../../components/common/tabs/AppTabs";
import TabPanel from "../../../components/common/tabs/TabPanel";
import HandymanWorkOrderControls from "../../../components/work-order/handyman/handle-tab/HandymanWorkOrderControls";
import WorkOrderFiles from "../../../components/work-order/files/WorkOrderFiles";
import AppPage from "../../../components/layout/AppPage";
import PageHeader from "../../../components/layout/PageHeader";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import WorkOrderFileContextProvider from "../../../components/work-order/files/WorkOrderFileContextProvider";
import PageBanner from "../../../components/layout/PageBanner";
import { useBundledState, useDetectKeyboardOpen } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import HandymanWorkOrderOverviewSection from "./HandymanWorkOrderOverviewSection";
import HandymanWorkOrderRouteContext from "./HandymanWorkOrderRouteContext";

enum Tabs {
  overview = "Översikt",
  handle = "Hantera",
  files = "Filer",
}

const HandymanWorkOrderPage = () => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const keyboardOpen = useDetectKeyboardOpen();

  const selectedTabBundle = useBundledState(Tabs.overview);

  const { orderRes } = useContext(HandymanWorkOrderRouteContext);

  const handlingSomeoneElsesOrder =
    !order.isAssignedThisOrder && order.allowedToHandleOrder;

  /* async function onReset() {
    try {
      await orderRes.mutate(() => {
        return workOrderRepo.reset({ orderId: order.orderId });
      });
    } catch (er: any) {
      window.modal.alert({
        title: "Oops",
        prompt: "Ojdå",
        typeOfAlert: "error",
      });
    }
  }
 */
  return (
    <WorkOrderFileContextProvider orderId={order.orderId}>
      <ErrorBoundary
        fallbackRender={(props) => (
          <SomethingWentWrong
            error={props.error}
            onTryAgain={() => orderRes.query.refetch()}
            tryAgainLabel="Hämta på nytt"
            description="Det gick inte att hämta detta uppdrag just nu."
          />
        )}
      >
        <AppPage
          tabProps={{
            tabs: order.allowedToHandleOrder
              ? Object.values(Tabs)
              : [Tabs.overview, Tabs.files],

            selectedTab: selectedTabBundle.value,
            onSelect: (tab) => selectedTabBundle.set(tab as Tabs),
            mainClassName: "p-1 md:p-2 lg:p-8 2xl:p-12",
          }}
          headerProps={{
            /* 
             {(order.isFinished || !!order.serviceContract) && (
              <section className="col-span-3 my-auto flex flex-col px-4  lg:col-span-1">
                {!!order.serviceContract && (
                  <span className="flex gap-2 text-base">
                    <FaHandshake
                      size={25}
                      className="text-orange-400"
                      aria-hidden="true"
                    />
                    Avtalskund
                  </span>
                )}
              </section>
            )}
          */
            hideOnKeyboardOpen: true,
            loading: orderRes.query.isFetching,
            RenderHeadings: (props) => {
              return (
                <span className="grid grid-cols-[auto,min-content,minmax(0,1fr)] items-center gap-x-2 gap-y-1 lg:gap-x-4">
                  <h1
                    className={__.classNames(
                      props.headingClassName,
                      "col-start-1"
                    )}
                  >
                    {order.description}
                  </h1>
                  <h2
                    className={__.classNames(
                      props.subheadingClassName,
                      "col-start-1"
                    )}
                  >
                    #{order.serialNumber}
                  </h2>
                  {order.isFinished && (
                    <span
                      className={__.classNames(
                        "col-start-2 row-span-2 row-start-1 my-auto rounded-full border p-2 lg:bg-main-bg-light"
                      )}
                    >
                      <span className="sr-only">Ordern är klarmarkerad</span>
                      <FaCheck
                        size={25}
                        className="text-green-600"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                  {order.serviceContract && (
                    <span
                      className={__.classNames(
                        "row-span-2 row-start-1 my-auto mr-auto rounded-full border p-2 lg:bg-main-bg-light",
                        order.isFinished && "col-start-3",
                        !order.isFinished && "col-start-2"
                      )}
                    >
                      <span className="sr-only">Avtalskund</span>
                      <FaHandshake
                        size={25}
                        className="text-orange-600"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </span>
              );
            },
          }}
          className={__.classNames(
            "grid grow md:gap-4",
            /*
            When not displaying banner that you're handling someone else's order
          */
            !handlingSomeoneElsesOrder && "grid-rows-1",
            /*
            When displaying banner that you're handling someone else's order
          */
            handlingSomeoneElsesOrder &&
              "grid-rows-[minmax(0,1fr),auto] md:grid-rows-[auto,minmax(0,1fr)]"
          )}
        >
          <AnimatePresence exitBeforeEnter>
            <TabPanel key={selectedTabBundle.value}>
              {selectedTabBundle.value === Tabs.overview && (
                <HandymanWorkOrderOverviewSection />
              )}
              {selectedTabBundle.value === Tabs.handle && (
                <HandymanWorkOrderControls />
              )}
              {selectedTabBundle.value === Tabs.files && (
                /* ToDo: Use more accessible element */
                <PageSection
                  as="div"
                  className="mx-auto h-full w-full max-w-screen-xl"
                >
                  <WorkOrderFiles
                    uploadLabel="Ladda upp"
                    orderId={order.orderId}
                    allowDeleting={
                      order.allowedToHandleOrder && !order.isFinished
                    }
                    allowUploading={
                      order.allowedToHandleOrder && !order.isFinished
                    }
                  />
                </PageSection>
              )}
            </TabPanel>
          </AnimatePresence>

          {!order.isAssignedThisOrder &&
            order.allowedToHandleOrder &&
            order.assignedTo?.handymanName && (
              <PageBanner
                className="row-start-2 mt-auto  md:row-start-1 md:border-2"
                isLoading={false}
              >
                Du hanterar uppdraget åt {order.assignedTo.handymanName}
              </PageBanner>
            )}
        </AppPage>
      </ErrorBoundary>
    </WorkOrderFileContextProvider>
  );
};

export default HandymanWorkOrderPage;
