import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import __ from "../../../utils/utils";

const SalesTeamFilterSelect = (props: {
  className?: string;
  id: string;
  label: string;
  defaultValue: string;
  currentValue?: string | null;
  values: string[];
  onChange: (val: string) => void;
}) => {
  const value = props.currentValue || props.defaultValue;
  const defaultSelected = value === "default";
  const form = useForm<{ filter: string }>({
    defaultValues: {
      filter: "",
    },
  });

  const options = props.values.filter((val) => {
    return form
      .watch("filter")
      .split(" ")
      .every((str) => val.toLowerCase().includes(str.toLowerCase()));
  });

  useEffect(() => {
    if (
      options.length !== props.values.length &&
      options.length === 1 &&
      options[0] !== value
    ) {
      props.onChange(options[0]);
    }
  }, [props.values, options, value]);

  return (
    <div
      className={__.classNames(
        "grid h-80 grid-rows-[auto,auto,minmax(0,1fr)] gap-1 p-4",
        props.className
      )}
    >
      <label htmlFor={props.id} className="text-base font-bold">
        {props.label}
      </label>
      <AppFormTextField
        register={form.register}
        name="filter"
        htmlAttributes={{ placeholder: "Filtrera" }}
      />
      <ul
        id={props.id}
        className="flex h-full flex-col gap-1 overflow-auto rounded-lg border p-3"
        role="listbox"
      >
        <ListItem
          selected={defaultSelected}
          onClick={() => {
            form.reset({ filter: "" });
            props.onChange("default");
          }}
          label="Ingen filtrering"
        />
        {options.map((city) => {
          const selected = value === city;
          return (
            <ListItem
              key={city}
              selected={selected}
              onClick={() => props.onChange(city)}
              label={city}
            />
          );
        })}
      </ul>
    </div>
  );
};

const ListItem = (props: {
  onClick: () => void;
  selected: boolean;
  label: string;
}) => {
  return (
    <li
      className={__.classNames(
        "cursor-pointer rounded-lg py-1 px-2 text-lg transition-all hover:bg-main-bg-light hover:text-white",
        props.selected && "bg-main-bg-light text-white"
      )}
      onClick={props.onClick}
      role={"option"}
      aria-selected={props.selected}
    >
      {props.label}
    </li>
  );
};

export default SalesTeamFilterSelect;
