import { WorkOrderConfirmation } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { AppButton } from "../../../components/common/buttons/AppButton";
import AppTextButton from "../../../components/common/buttons/AppTextButton";
import { AppFormSelect } from "../../../components/common/select/AppFormSelect";
import { AppFormTextArea } from "../../../components/common/text-areas/AppFormTextArea";
import { AppFormTextField } from "../../../components/common/text-fields/AppFormTextField";
import { AppTextField } from "../../../components/common/text-fields/AppTextField";
import { useBundledState } from "../../../hooks/hooks";
import __ from "../../../utils/utils";
import CustomerWorkOrderContext from "../CustomerWorkOrderContext";

interface Props {
  className?: string;
  defaultValues?: WorkOrderConfirmation.PaymentInfoType;
  onSubmit(values: WorkOrderConfirmation.PaymentInfoType): void;
  onGoBack(): void;
  defaultInvoiceMail?: string;
}

const CustomerWorkOrderPaymentAndHousingFormStep = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const form = useForm<WorkOrderConfirmation.PaymentInfoType>({
    defaultValues: props.defaultValues || {
      invoice: {
        email:
          props.defaultInvoiceMail ??
          ctx.workOrder.invoiceInformation?.email ??
          undefined,
        invoiceMethod: "email",
      },
      housing: {
        housingType: "house",
      },
      customerIsCompany: ctx.workOrder.customerIsCompany,
    },
    resolver: zodResolver(WorkOrderConfirmation.PaymentInfoSchema),
  });

  const showErrors = useBundledState<
    false | FieldErrors<WorkOrderConfirmation.PaymentInfoType>
  >(false);

  useEffect(() => {
    form.watch();
  }, [form]);

  function onSubmit(values: WorkOrderConfirmation.PaymentInfoType) {
    props.onSubmit(values);
  }

  function onError(errors: FieldErrors<WorkOrderConfirmation.PaymentInfoType>) {
    showErrors.set(errors);
    window.modal.toast({
      id: "invoiceInformationErrorToast",
      title: "Information saknas",
      prompt:
        "Vänligen fyll i både faktureringsinformation och information för rotavdrag",
      toastType: "error",
    });
  }

  const housingType = form.getValues().housing?.housingType;
  const invoiceMethod = form.getValues().invoice?.invoiceMethod;

  return (
    <section
      className={__.classNames(
        "flex flex-col gap-2 py-3 sm:p-4",
        props.className
      )}
    >
      <form
        className="flex flex-col gap-2"
        onSubmit={form.handleSubmit(onSubmit, onError)}
      >
        <section
          className={__.classNames(
            "flex flex-col gap-2 border-2 border-transparent p-2",
            showErrors.value &&
              showErrors.value.housing &&
              "focus-within:border-orange-300"
          )}
        >
          <header>
            <h2 className="text-xl">Faktureringsinformation</h2>
          </header>
          <main className="flex flex-col gap-4 pb-2">
            <AppFormSelect
              register={form.register}
              name="invoice.invoiceMethod"
              label="Välj faktureringsmetod"
              options={[
                {
                  value: "email",
                  label: "E-post",
                },
                {
                  value: "postal",
                  label: "Pappersfaktura (avgift 50:-)",
                },
              ]}
            />

            <AnimatePresence exitBeforeEnter>
              <motion.fieldset
                key={invoiceMethod}
                className="flex flex-col gap-2 rounded"
                initial={{
                  opacity: 0,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {invoiceMethod === "email" && (
                  <>
                    <AppFormTextField
                      register={form.register}
                      name="invoice.email"
                      label="E-post för faktura"
                      htmlAttributes={{
                        type: "email",
                      }}
                    ></AppFormTextField>
                  </>
                )}
                {invoiceMethod === "postal" && (
                  <>
                    <AppTextField
                      htmlAttributes={{
                        disabled: true,
                        readOnly: true,
                        value:
                          ctx.workOrder.invoiceInformation?.street ?? undefined,
                        name: "invoice.street",
                      }}
                      label="Gata"
                      className="col-start-2 row-start-1"
                    />
                    <AppTextField
                      htmlAttributes={{
                        disabled: true,
                        readOnly: true,
                        name: "invoice.postalCode",
                        value:
                          ctx.workOrder.invoiceInformation?.postalCode ??
                          undefined,
                      }}
                      className="col-start-2"
                      label="Postnummer"
                    />
                    <AppTextField
                      className="col-start-2"
                      htmlAttributes={{
                        disabled: true,
                        readOnly: true,
                        name: "invoice.city",
                        value:
                          ctx.workOrder.invoiceInformation?.city ?? undefined,
                      }}
                      label="Stad"
                    />
                  </>
                )}
              </motion.fieldset>
            </AnimatePresence>
            <AppFormTextArea
              register={form.register}
              name="invoice.additionalInformation"
              label="Ytterligare faktureringsinformation"
              htmlAttributes={{
                placeholder: "Finns det något mer vi behöver veta?",
                rows: 5,
              }}
            />
          </main>
        </section>
        {!ctx.workOrder.customerIsCompany && (
          <section
            className={__.classNames(
              "flex flex-col gap-2 border-2 border-transparent p-2",
              showErrors.value &&
                showErrors.value.housing &&
                "focus-within:border-orange-300"
            )}
          >
            <header className="grid grid-cols-3">
              <h2 className="col-span-3 text-xl">Information för rotavdrag</h2>
              <p className="col-span-3 text-sm">
                Den information du inte har tillgänglig lämnar du blank
              </p>

              {/* <AppTextButton
                className="mt-auto ml-auto"
                onClick={() => form.resetField("housing.housingType")}
                disabled={housingType === undefined}
              >
                Rensa val
              </AppTextButton> */}
            </header>

            <main className="flex min-h-[270px] flex-col gap-2">
              <AppFormSelect
                register={form.register}
                name="housing.housingType"
                label="Välj bostadstyp"
                options={[
                  {
                    value: "house",
                    label: "Villa",
                  },
                  {
                    value: "condomonium",
                    label: "Bostadsrätt",
                  },
                  {
                    value: "rentalApartment",
                    label: "Hyreslägenhet",
                  },
                  {
                    value: "insuranceMatter",
                    label: "Försäkringsärende (ej ROT)",
                  },
                ]}
              />

              <AnimatePresence exitBeforeEnter>
                {(housingType === "house" || housingType === "condomonium") && (
                  <motion.fieldset
                    key={housingType}
                    className="flex flex-col gap-2 rounded"
                    initial={{
                      opacity: 0,
                    }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {housingType === "house" && (
                      <AppFormTextField
                        register={form.register}
                        className="col-start-2"
                        label="Fastighetsbeteckning"
                        name="housing.propertyDesignation"
                      />
                    )}
                    {housingType === "condomonium" && (
                      <>
                        <AppFormTextField
                          register={form.register}
                          className="col-start-2"
                          label="Organisationsnummer"
                          name="housing.housingCooperativeOrgNo"
                        />
                        <AppFormTextField
                          register={form.register}
                          className="col-start-2"
                          label="Lägenhetsnummer"
                          name="housing.apartmentNumber"
                        />
                      </>
                    )}
                  </motion.fieldset>
                )}
              </AnimatePresence>
            </main>
          </section>
        )}
        <footer className="flex flex-col gap-2 lg:col-start-2">
          <AppButton className="lg:ml-auto">Spara information</AppButton>
          <AppTextButton
            className="flex items-center gap-2 p-2 lg:ml-auto"
            onClick={props.onGoBack}
          >
            <FiArrowLeft size={30} />
            Gå tillbaka
          </AppTextButton>
        </footer>
      </form>
    </section>
  );
};

export default CustomerWorkOrderPaymentAndHousingFormStep;
