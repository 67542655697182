import { AnimatePresence, Variants, motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiCaretUp, BiError } from "react-icons/bi";
import { FiCheck, FiX } from "react-icons/fi";
import Process from "../../utils/process/Process";
import { ProcessQueueState } from "../../utils/process/ProcessQueue";
import ProcessTask from "../../utils/process/ProcessTask";
import __ from "../../utils/utils";
import { AppLoader } from "../common/loaders/AppLoader";
import QueueContext from "../queue/QueueContext";

const FadeInOutVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

interface TaskResultProps {
  className?: string;
  task: ProcessTask;
  process: Process;
}

const TaskResult = (props: TaskResultProps) => {
  const success = props.task.getResult()?.success;

  return (
    <motion.span className={__.classNames("flex gap-2", props.className)}>
      <span
        className={__.classNames(
          "flex flex-col rounded",
          props.process.name.length < 20 &&
            "sm:flex-row sm:items-center sm:gap-2"
        )}
      >
        <span className="font-bold">{props.process.name}</span>
        <span className="max-w-[175px] truncate">{props.task.name}</span>
      </span>

      <span className="my-auto" role="status" aria-live="polite">
        {success ? (
          <>
            <span className="sr-only">Lyckad</span>
            <FiCheck size={25} className="text-green-600" />
          </>
        ) : (
          <>
            <span className="sr-only">Något gick fel</span>
            <BiError size={25} className="text-red-600" />
          </>
        )}
      </span>
      {!success && props.task.getErrorMessage() && (
        <span className="text-xs text-red-600">
          {props.task.getErrorMessage()}
        </span>
      )}
    </motion.span>
  );
};

const QueueModal: React.FC = () => {
  const { queue, cleanUp } = useContext(QueueContext);
  const [minimized, setMinimized] = useState(false);
  const [showAllFailedTasks, setShowAllFailedTasks] = useState(false);
  const failedTasks = queue?.getFailedTasks() ?? [];

  const [processState, setProcessState] = useState<ProcessQueueState | null>(
    queue?.getState() ?? null
  );

  const loaderWrapperRef = useRef<HTMLDivElement | null>(null);
  const minimizedAndFinished = queue && queue.isFinished() && minimized;

  useEffect(() => {
    if (queue) {
      const unsubscribe = queue.subscribe(setProcessState);
      return () => {
        unsubscribe();
      };
    }
  }, [queue]);

  useEffect(() => {
    if (!queue && minimized) {
      setMinimized(false);
    }
  }, [minimized, queue]);

  useEffect(() => {
    let subscription: () => void;
    let timeout: NodeJS.Timeout;

    if (queue && !showAllFailedTasks) {
      subscription = queue.onFinished(() => {
        timeout = setTimeout(
          () => {
            cleanUp();
          },
          failedTasks.length ? 8000 : 2500
        );
      });
    }

    return () => {
      if (subscription) {
        subscription();
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  useEffect(() => {
    if (queue && minimizedAndFinished) {
      cleanUp();
      setMinimized(false);
    }
  }, [
    minimizedAndFinished,
    cleanUp,
    queue,
    failedTasks.length,
    showAllFailedTasks,
  ]);

  function toggleMinimized() {
    setMinimized(!minimized);
  }
  const toggleShowAllFailedTasks = () => {
    setShowAllFailedTasks(!showAllFailedTasks);
  };

  const currentProcess = processState?.currentProcess;
  const currentTask = processState?.currentTask;
  const previousProcess = processState?.previousProcess ?? currentProcess;
  const previousTask = processState?.previousTask;
  const showPreviousTask =
    !showAllFailedTasks &&
    !!previousTask &&
    !!previousProcess &&
    previousTask.getResult() &&
    !previousTask.getResult()?.success;

  const toastProcessNameId = `toastTitle${currentProcess?.id}`;
  const toastTaskNameId = `toastDescription${currentTask?.name}`;

  const fadeInOutProps = {
    variants: FadeInOutVariants,
    initial: "initial",
    animate: "animate",
    exit: "exit",
  };

  if (minimizedAndFinished) {
    return <></>;
  }

  if (queue && !queue.isFinished() && minimized) {
    return (
      <div
        className="pointer-events-none fixed top-[calc(min(5vw,25px)/2)] left-[calc(min(5vw,25px)/2)] h-[calc(100%-min(5vw,25px))] w-[calc(100%-min(5vw,25px))]"
        ref={loaderWrapperRef}
      >
        <motion.button
          drag="y"
          //dragElastic={0.1}
          dragMomentum={false}
          dragConstraints={loaderWrapperRef}
          onClick={toggleMinimized}
          className="pointer-events-auto absolute left-[min(5vw,25px)] bottom-[min(5vw,25px)] z-50 translate-x-0 rounded-full border bg-white p-4 shadow-lg"
        >
          <AppLoader />
        </motion.button>
      </div>
    );
  }

  //process count
  const processCount = queue?.getProcessCount() ?? 0;
  const currentProcessIndex = queue?.getCurrentProcessIndex() ?? 0;
  const showProcessCount = processCount > 1 && !queue?.isFinished();

  const currentProcessTaskCount = currentProcess?.getTasks().length ?? 0;
  /*   const previousProcessName = previousProcess?.name ?? currentProcess?.name;
  const currentTaskIndex = currentProcess?.getCurrentTaskIndex() ?? 0;
  const showTaskCount = currentProcessTaskCount > 1; */

  return (
    <AnimatePresence exitBeforeEnter>
      {queue && (
        <motion.div
          className="fixed z-50 flex w-full max-w-[min(90vw,500px)] flex-col gap-2 justify-self-center overflow-hidden rounded bg-white p-6 text-black shadow-lg"
          role="dialog"
          aria-labelledby={toastProcessNameId}
          aria-describedby={toastTaskNameId}
          initial={{
            y: `calc(100vh + 100px)`,
          }}
          animate={{
            y: `calc(95vh - 100%)`,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <motion.span className="flex items-center justify-between gap-4">
            <AnimatePresence exitBeforeEnter>
              <motion.h2
                key={toastProcessNameId}
                id={toastProcessNameId}
                className="text-lg"
              >
                {currentProcess?.name ?? "Alla processer klara"}
              </motion.h2>
              {showProcessCount && (
                <motion.span>
                  {currentProcessIndex + 1}/{processCount}
                </motion.span>
              )}
            </AnimatePresence>

            <motion.button
              {...fadeInOutProps}
              onClick={() => toggleMinimized()}
              className="z-40 col-start-1 row-start-1 ml-auto mb-auto"
            >
              <span className="sr-only">Minimera</span>
              <FiX size={20} />
            </motion.button>
          </motion.span>
          {/* <AnimatePresence> */}
          {currentTask && (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex items-center gap-4"
            >
              <AnimatePresence exitBeforeEnter>
                <motion.span
                  {...fadeInOutProps}
                  key={toastTaskNameId}
                  id={toastTaskNameId}
                  className="text-lg"
                >
                  {currentTask?.name}
                </motion.span>
              </AnimatePresence>

              <AppLoader className="mx-0 h-6 w-6" />
            </motion.span>
          )}
          {showPreviousTask && (
            <TaskResult
              task={previousTask}
              process={previousProcess}
              className="ml-auto"
            />
          )}

          {/* New section for displaying all failed tasks */}
          {showAllFailedTasks && (
            <ul className="ml-auto flex flex-col gap-1">
              {failedTasks.map((el, i) => (
                <li key={el.task.name + i}>
                  <TaskResult task={el.task} process={el.process} />
                </li>
              ))}
            </ul>
          )}
          {failedTasks.length > 0 && (
            <button
              onClick={toggleShowAllFailedTasks}
              className="ml-auto flex items-center gap-2"
            >
              <span>
                {showAllFailedTasks
                  ? "Göm misslyckade processer"
                  : "Visa misslyckade processer"}
              </span>
              <BiCaretUp
                className={__.classNames(showAllFailedTasks && "rotate-180")}
              />
            </button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default QueueModal;
