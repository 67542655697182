import WorkOrderFileUpload, {
  FullImageType,
} from "../../../components/work-order/files/WorkOrderFileUpload";
import __ from "../../../utils/utils";

interface Props {
  className?: string;
  onSubmit(images: FullImageType[]): void;
  onGoBack(): void;
  defaultValues?: FullImageType[];
}

const CustomerWorkOrderImagesFormStep = (props: Props) => {
  return (
    <section className={__.classNames("", props.className)}>
      <main>
        <WorkOrderFileUpload
          uploadLabel="Gå vidare"
          defaultValues={props.defaultValues}
          onSubmit={props.onSubmit}
          onGoBack={props.onGoBack}
        />
      </main>
    </section>
  );
};

export default CustomerWorkOrderImagesFormStep;
