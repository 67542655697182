import __ from "../../../utils/utils";
interface Props {
  className?: string;
  onlyIcon?: boolean;
  withColor?: boolean;
}

const EljourenLogo = (props: Props) => {
  return (
    <img
      className={__.classNames(
        "rounded",
        //!props.className && "h-40 max-h-full rounded",
        props.className
      )}
      src={
        props.withColor
          ? "/logos/eljouren-logo-color.png"
          : "/logos/eljouren-logo.png"
      }
      data-testid="eljouren-logo"
      alt="Logga för Svenska Eljouren"
    />
  );
};

export default EljourenLogo;
