"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckInOut = void 0;
var zod_1 = require("zod");
/*
    Could use a better name
*/
var CheckInOut;
(function (CheckInOut) {
    /*
    Domain
    */
    CheckInOut.Schema = zod_1.z.object({
        date: zod_1.z.date(),
    });
})(CheckInOut || (CheckInOut = {}));
exports.CheckInOut = CheckInOut;
